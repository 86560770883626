// NoteForm.js
import React, { useState } from 'react';
import { X } from 'lucide-react';
import './NoteForm.scss';

const NoteForm = ({ note, onSave, onClose }) => {
    const [formData, setFormData] = useState(note || {
        title: '',
        content: '',
        folder: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <div className="note-form-modal">
            <div className="modal-content">
                <button className="close-btn" onClick={onClose}><X size={24} /></button>
                <h2>{note ? 'Editer une note' : 'Ajouter une note'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="title">Titre</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Contenu</label>
                        <textarea
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="folder">Dossier (optionel)</label>
                        <input
                            type="text"
                            id="folder"
                            name="folder"
                            value={formData.folder}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="save-btn">Ajouter</button>
                </form>
            </div>
        </div>
    );
};

export default NoteForm;