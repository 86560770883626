// ContactSection.js
import React, { useState } from 'react';
import { Plus, Edit, Trash2 } from 'lucide-react';
import ContactForm from './ContactForm';
import './ContactSection.scss';

const ContactSection = () => {
    const [contacts, setContacts] = useState([]);
    const [currentContact, setCurrentContact] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const handleAddContact = () => {
        setCurrentContact(null);
        setShowForm(true);
    };

    const handleEditContact = (contact) => {
        setCurrentContact(contact);
        setShowForm(true);
    };

    const handleDeleteContact = (contactId) => {
        setContacts(contacts.filter(contact => contact.id !== contactId));
    };

    const handleSaveContact = (contactData) => {
        if (currentContact) {
            setContacts(contacts.map(contact => contact.id === currentContact.id ? { ...contact, ...contactData } : contact));
        } else {
            setContacts([...contacts, { ...contactData, id: Date.now() }]);
        }
        setShowForm(false);
    };

    return (
        <div className="contact-section">
            <h2>Contacts</h2>
            <button className="add-btn" onClick={handleAddContact}><Plus size={16} /> Add Contact</button>
            <ul className="contact-list">
                {contacts.map(contact => (
                    <li key={contact.id} className="contact-item">
                        <span>{contact.name}</span>
                        <div className="actions">
                            <button onClick={() => handleEditContact(contact)}><Edit size={16} /></button>
                            <button onClick={() => handleDeleteContact(contact.id)}><Trash2 size={16} /></button>
                        </div>
                    </li>
                ))}
            </ul>
            {showForm && (
                <ContactForm
                    contact={currentContact}
                    onSave={handleSaveContact}
                    onClose={() => setShowForm(false)}
                />
            )}
        </div>
    );
};

export default ContactSection;
