// Error.js
import React from 'react';
import { AlertCircle, RefreshCw } from 'lucide-react';
import logo from '../img/Banner_transparent.png';
import '../styles/Error.scss';

const Error = ({ message, onRetry }) => {
    return (
        <div className="error-container">
            <div className="error-content">
                <AlertCircle className="error-icon" />
                <h2 className="error-title">Oups ! Une erreur s'est produite</h2>
                <p className="error-message">{message || "Nous n'avons pas pu traiter votre demande. Veuillez réessayer."}</p>
                <button onClick={onRetry} className="error-retry-button">
                    <RefreshCw className="error-retry-icon" />
                    Réessayer
                </button>
                <hr style={{ width: '100%', margin: '20px 0' }} />


                <img src={logo} alt="Logo" className="error-logo" style={{ width: '200px' }} />

                
            </div>
        </div>
    );
};

export default Error;