import React, { useState, useEffect, useCallback } from 'react';
import '../styles/Desktop.scss';

import explorer from '../img/Explorer.png';
import userImg from '../img/User.png';
import keyboard from '../img/Keyboard.png';
import audio from '../img/Audio.png';
import video from '../img/Video_file.png';
import project from '../img/Tasks.png';
import game from '../img/Game.png';
import internet from '../img/internet.png';
import blog from '../img/Blog.png';
import textfile from '../img/Notes.png';
import fileshare from '../img/Blog.png';
import key from '../img/Key.png';
import run from '../img/Run.png';


import Window from './Window';
import Solitaire from './Solitaire';
import TextEditor from './TextEditor';
import Contact from './Contact';
import ServiceOrder from './ServiceOrder';
import ProjectManager from './ProjectManager';
import FPSGame from './FPSGame';
import Blog from './Blog';
import Social from './Social';
import FileUploader from './FileUploader';
import VerifyUser from './VerifyUser';
import RoleManager from './AdminDashboard/AdminDashboard';
import PasswordManager from './PasswordManager/PasswordManager';
import CyberSecurity from './CyberSecurity';

import background_vid from '../img/background/background.mp4';
import { useUser } from '../contexts/UserContext';

const Desktop = ({ isCreditsVisible }) => {
    const { user, isStaff, isVerified } = useUser();
    const [windows, setWindows] = useState([]);
    const [hasAlreadyOpened, setHasAlreadyOpened] = useState(false);
    const [welcomeWindow, setWelcomeWindow] = useState(false);
    const [rightClickMenu, setRightClickMenu] = useState(false);
    const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });
    const [skillPopup, setSkillPopup] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState('');
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showMusicPlayer, setShowMusicPlayer] = useState(false);
    const [showVideoStreaming, setShowVideoStreaming] = useState(false);
    const [showProjectManager, setShowProjectManager] = useState(false);
    const [showFpsGame, setShowFpsGame] = useState(false);
    const [showServiceOrder, setShowServiceOrder] = useState(false);
    const [showBlog, setShowBlog] = useState(false);
    const [showSocial, setShowSocial] = useState(false);
    const [showFileUploader, setShowFileUploader] = useState(false);
    const [showVerifyUser, setShowVerifyUser] = useState(false);
    const [showRoleManager, setShowRoleManager] = useState(false);
    const [showPasswordManager, setShowPasswordManager] = useState(false);
    const [showCyberSecurity, setShowCyberSecurity] = useState(false);

    const [skills, setSkills] = useState({
        'React': 'React est une bibliothèque JavaScript pour construire des interfaces utilisateur. Elle est maintenue par Facebook et une communauté de développeurs.',
        'React Native': 'React Native permet de créer des applications mobiles utilisant seulement JavaScript. Il utilise la même conception que React, ce qui facilite la création de composants interactifs et robustes.',
        'HTML': 'HTML (HyperText Markup Language) est le code utilisé pour structurer et afficher une page web et son contenu.',
        'CSS': 'CSS (Cascading Style Sheets) est un langage de feuille de style utilisé pour décrire la présentation d’un document écrit en HTML ou XML.',
        'Sass': 'Sass est un préprocesseur CSS qui ajoute des fonctionnalités telles que les variables, les mixins, et les fonctions pour rendre le CSS plus maintenable et flexible.',
        'Tailwind': 'Tailwind CSS est un framework de design utilitaire permettant de créer rapidement des interfaces utilisateur modernes sans quitter le fichier HTML.',
        'Electron': 'Electron est un framework permettant de créer des applications de bureau multiplateformes avec des technologies web comme JavaScript, HTML, et CSS.',
        'Tauri': 'Tauri est un framework moderne et léger pour créer des applications de bureau avec des technologies web, offrant une alternative plus sécurisée et rapide qu’Electron.',
        'JavaScript': 'JavaScript est un langage de programmation dynamique qui est principalement utilisé pour ajouter de l’interactivité aux pages web.',
        'PHP': 'PHP est un langage de script côté serveur principalement utilisé pour le développement web. Il est puissant pour créer des sites dynamiques et interactifs.',
        'Express': 'Express est un framework web minimaliste pour Node.js, utilisé pour construire des applications web et des API robustes.',
        'MySQL': 'MySQL est un système de gestion de base de données relationnelle open source, populaire pour les applications web.',
        'MongoDB': 'MongoDB est une base de données NoSQL orientée document, conçue pour stocker des données non structurées de manière flexible.',
        'Redis': 'Redis est une base de données open-source, en mémoire, qui peut être utilisée comme magasin de données clé-valeur, cache, et file d’attente.',
        'KeyDB': 'KeyDB est une base de données clé-valeur haute performance compatible avec Redis, offrant un support multi-thread pour des performances améliorées.',
        'Cassandra': 'Apache Cassandra est une base de données NoSQL distribuée conçue pour gérer de grandes quantités de données à travers de nombreux serveurs sans point de défaillance unique.',
        'Python': 'Python est un langage de programmation polyvalent qui est connu pour sa simplicité et son efficacité pour la programmation scientifique, l’analyse de données, et le développement web.',
        'Java': 'Java est un langage de programmation polyvalent, orienté objet, utilisé pour créer des applications web, des logiciels, et des systèmes distribués.',
        'Factorisation de matrices': 'La factorisation de matrices est une technique mathématique utilisée pour décomposer une matrice en plusieurs matrices plus petites. Elle est particulièrement utile dans les systèmes de recommandation pour réduire la dimensionnalité des données.',
        'Protocole de Maillage Fractal Adaptatif': 'Le PMFA est un protocole innovant pour des communications sécurisées, utilisant des motifs fractals pour créer un réseau dynamique et auto-adaptatif, offrant une sécurité et un anonymat renforcés.',
        'WebSockets': 'WebSockets est une technologie qui permet la communication bidirectionnelle en temps réel entre le client et le serveur sur une seule connexion TCP.',
        'Protocole SMTP': 'SMTP (Simple Mail Transfer Protocol) est un protocole utilisé pour l’envoi de courriers électroniques sur le réseau Internet.',
        'Peer-to-Peer': 'Le réseau Peer-to-Peer (P2P) est une architecture de réseau dans laquelle chaque partie a les mêmes capacités et peut initier une communication.',
        'Réseau Client-Serveur': 'Le modèle Client-Serveur est une architecture réseau dans laquelle le serveur central fournit des ressources ou services aux clients.',
        'Kubernetes': 'Kubernetes est une plateforme open-source pour automatiser le déploiement, la mise à l’échelle et la gestion des applications conteneurisées.',
        'Docker': 'Docker est un outil conçu pour faciliter la création, le déploiement et l’exécution d’applications en utilisant des conteneurs.',
        'Git': 'Git est un système de contrôle de version distribué utilisé pour suivre les modifications dans le code source pendant le développement logiciel.'
    });
    const [savedContent, setSavedContent] = useState(null);

    const handleSave = (content) => {
        setSavedContent(content);
        console.log('Saved content:', content);
    };



    const openWindow = (id, title, content) => {
        setWindows([...windows, { id, title, content }]);
    };


    const closeWindow = (id) => {
        setWindows(windows.filter(window => window.id !== id));
    };

    const contactMe = () => {
        window.location.href = "mailto:bonjour@gmail.com";
    }

    const openTextEditor = () => {
        setShowTextEditor(true);
    };

    const openContactWindow = () => {
        setShowContact(true);
    };

    const openVerifyUser = () => {
        setShowVerifyUser(true);
    };

    const openRoleManager = () => {
        setShowRoleManager(true);
    };

    const openBlog = () => {
        setShowBlog(true);
    };

    const openPasswordManager = () => {
        setShowPasswordManager(true);
    };

    const openSocial = () => {
        setShowSocial(true);
    };

    const openFpsGame = () => {
        setShowFpsGame(true);
    };

    const openServiceOrder = () => {
        setShowServiceOrder(true);
    };

    const projectManagerWindow = () => {
        setShowProjectManager(true);
    };

    const openFileUploader = () => {
        setShowFileUploader(true);
    };

    const openCyberSecurity = () => {
        setShowCyberSecurity(true);
    };


    // useEffect pour afficher le verify user window au bout de 30 secondes sur la page
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!showVerifyUser) {
                if (isVerified) return;
                openVerifyUser();
            }

        }, 5000);

        return () => clearTimeout(timer);
    }, []);



    useEffect(() => {
        const handleClickOutside = (e) => {
            // Cacher le menu contextuel si le clic n'est pas sur le menu
            if (rightClickMenu) {
                setRightClickMenu(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [rightClickMenu]);

    const closeWelcomeWindow = () => {
        setWelcomeWindow(false);
        setHasAlreadyOpened(true);
        localStorage.setItem('hasAlreadyOpened', true);
    };

    const onRightClick = (e) => {
        e.preventDefault();
        setRightClickPosition({ x: e.clientX, y: e.clientY });
        setRightClickMenu(true);
    };

    const closeSkillPopup = () => {
        setSkillPopup(false);
    };

    const showSkillDetails = (skill) => {
        setSelectedSkill(skill);
        setSkillPopup(true);
    };

    const skillsInfo = useCallback((skill) => {
        return skills[skill];
    }, [skills]);


    const renderSkillButtons = (skillsArray) => {
        return skillsArray.map((skill) => (
            <button className='skillButton' key={skill} onClick={() => showSkillDetails(skill)}>{skill}</button>
        ));
    };


    const closeFPS = () => {

        // safe unmount FPSGame component to reset the game

        // refresh the page to reset the game
        window.location.reload();

        setShowFpsGame(false);
    }

    return (
        <div className="desktop" onContextMenu={onRightClick}>
            <video autoPlay muted loop id="background-video">
                <source src={background_vid} type="video/mp4" />
            </video>

            <div className='desktop-icons'>

                <div className="icon" onClick={() => openWindow('1', 'Mes projets', '1')}>
                    <img src={explorer} alt="My Computer" />
                    <span>Mes projets</span>
                </div>

                <div className="icon" onClick={openBlog}>
                    <img src={blog} alt="My Computer" />
                    <span>Blog</span>
                </div>

                <div className="icon" onClick={openSocial}>
                    <img src="https://cdn3d.iconscout.com/3d/free/thumb/free-discord-3d-icon-download-in-png-blend-fbx-gltf-file-formats--twitter-logo-social-media-pack-logos-icons-7516828.png?f=webp" alt="My Computer" />
                    <span>Réseau social</span>
                </div>

                <div className="icon" onClick={openServiceOrder}>
                    <img src={internet} alt="Service Order" />
                    <span>Boutique</span>
                </div>

                {isStaff && (
                    <div className="icon" onClick={openRoleManager}>
                        <img src={run}
                            alt="Role Manager" />
                        <span>Zone Admin</span>
                    </div>
                )}

                <div className="icon" onClick={openCyberSecurity}>
                    <img src={keyboard}
                        alt="Cyber Security" />
                    <span>Cyber Sécurité</span>
                </div>

                {/* ici icon pour le texteditor */}
                <div className="icon" onClick={openFileUploader}>
                    <img src={fileshare}
                        alt="File Sharing" />
                    <span>Partage de fichiers</span>
                </div>

                {/* ici icon pour le texteditor */}
                <div className="icon" onClick={openTextEditor}>
                    <img src={textfile}
                        alt="Text Editor" />
                    <span>Editeur de texte</span>
                </div>

                <div className="icon" onClick={openFpsGame}>
                    <img src={game} alt="FPS Game" />
                    <span>Jeu de tir</span>
                </div>

                <div className="icon" onClick={projectManagerWindow}>
                    <img src={project} alt="Contact Me" />
                    <span>Gestionnaire de projet</span>
                </div>

                <div className="icon" onClick={openPasswordManager}>
                    <img src={key} alt
                        ="Password Manager" />
                    <span>Gestionnaire de mots de passe</span>
                </div>

                <div className="icon" onClick={openContactWindow}>
                    <img src={userImg} alt="Contact Me" />
                    <span>Contactez-moi</span>
                </div>

            </div>


            {windows.map(window => (
                <Window key={window.id} id={window.id} title={window.title} content={window.content} onClose={closeWindow} />
            ))}


            <div className="module" style={{ display: isCreditsVisible ? 'flex' : 'none' }}>
                <div className="about-me">
                    <h2>
                        <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8 10.2H13.806M10.2004 10.1999H10.2064M9.59961 13.1997C9.59961 13.1997 10.4996 14.3997 11.9996 14.3997C13.4996 14.3997 14.3996 13.1997 14.3996 13.1997M17 3H19C20.1046 3 21 3.89543 21 5V7M21 17V19C21 20.1046 20.1046 21 19 21H17M7 21H5C3.89543 21 3 20.1046 3 19V17M3 7V5C3 3.89543 3.89543 3 5 3H7M14.1 10.2C14.1 10.3657 13.9657 10.5 13.8 10.5C13.6343 10.5 13.5 10.3657 13.5 10.2C13.5 10.0343 13.6343 9.9 13.8 9.9C13.9657 9.9 14.1 10.0343 14.1 10.2ZM10.5004 10.1999C10.5004 10.3656 10.3661 10.4999 10.2004 10.4999C10.0347 10.4999 9.90039 10.3656 9.90039 10.1999C9.90039 10.0342 10.0347 9.8999 10.2004 9.8999C10.3661 9.8999 10.5004 10.0342 10.5004 10.1999ZM18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        À propos de moi</h2>
                    <hr />
                    <p>Je suis un développeur passionné avec un parcours riche et diversifié depuis 2016.<br /><br />Je me spécialise dans la conception de solutions innovantes et performantes, tant en frontend qu'en backend. Vivant en France, j'ai acquis des compétences solides dans plusieurs technologies et frameworks de pointe.</p>
                </div>

                <div className="experience">
                    <h2>
                        <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 10L12 4.5L22 10L17.9457 12.2298M2 10L6.05427 12.2298M2 10V16M6 17.5V12.5C6 12.4084 6.01848 12.3182 6.05427 12.2298M6 17.5C6 18.6046 8.68629 19.5 12 19.5C15.3137 19.5 18 18.6046 18 17.5M6 17.5C6 16.3954 8.68629 15.5 12 15.5C15.3137 15.5 18 16.3954 18 17.5M18 17.5V12.5C18 12.4084 17.9815 12.3182 17.9457 12.2298M17.9457 12.2298C17.9334 12.1993 17.9189 12.1691 17.9025 12.139C17.3927 11.2067 14.9439 10.5 12 10.5C9.05606 10.5 6.60733 11.2067 6.09749 12.139C6.08105 12.1691 6.06663 12.1993 6.05427 12.2298" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Mon expérience</h2>
                    <hr />
                    <p>J'ai débuté ma carrière en développant des plugins Minecraft en Java et ai rapidement élargi mon expertise à JavaScript, Python, et Go.<br /> <br />Mon travail couvre une large gamme de technologies, allant des applications web interactives aux architectures backend évolutives.</p>
                </div>

                <div className="skills">
                    <h2>
                        <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 12H4.6C4.03995 12 3.75992 12 3.54601 12.109C3.35785 12.2049 3.20487 12.3578 3.10899 12.546C3 12.7599 3 13.0399 3 13.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H6M18 12H19.4C19.9601 12 20.2401 12 20.454 12.109C20.6422 12.2049 20.7951 12.3578 20.891 12.546C21 12.7599 21 13.0399 21 13.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H18M15 6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6M10 12H14M10.8 21H13.2C14.8802 21 15.7202 21 16.362 20.673C16.9265 20.3854 17.3854 19.9265 17.673 19.362C18 18.7202 18 17.8802 18 16.2V10.8C18 9.11984 18 8.27976 17.673 7.63803C17.3854 7.07354 16.9265 6.6146 16.362 6.32698C15.7202 6 14.8802 6 13.2 6H10.8C9.11984 6 8.27976 6 7.63803 6.32698C7.07354 6.6146 6.6146 7.07354 6.32698 7.63803C6 8.27976 6 9.11984 6 10.8V16.2C6 17.8802 6 18.7202 6.32698 19.362C6.6146 19.9265 7.07354 20.3854 7.63803 20.673C8.27976 21 9.11984 21 10.8 21Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Compétences Techniques</h2>
                    <hr />
                    <strong>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 21H16M12 17V21M14.5 3H17.7997C18.9198 3 19.4798 3 19.9076 3.21799C20.284 3.40973 20.5899 3.71569 20.7817 4.09202C20.9997 4.51984 20.9997 5.07989 20.9997 6.2V13.8C20.9997 14.9201 20.9997 15.4802 20.7817 15.908C20.5899 16.2843 20.284 16.5903 19.9076 16.782C19.4798 17 18.9198 17 17.7997 17H6C5.06812 17 4.60218 17 4.23463 16.8478C3.74458 16.6448 3.35523 16.2554 3.15224 15.7654C3 15.3978 3 14.9319 3 14M7 2V2.41667M7 11.5835V12.0002M11.9997 7H11.583M2.41667 7H2M10.5349 3.46453L10.2402 3.75916M3.75947 10.2408L3.46484 10.5354M3.46514 3.46477L3.75977 3.7594M10.2415 10.241L10.5361 10.5356M9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Frontend:</strong>
                    <div className="skills-list">
                        {renderSkillButtons(['React', 'React Native', 'HTML', 'CSS', 'Sass', 'Tailwind', 'Electron', 'Tauri'])}
                    </div>
                    <strong>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 9L11 12L8 15M13 15H16M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Backend:</strong>
                    <div className="skills-list">
                        {renderSkillButtons(['JavaScript', 'PHP', 'Express', 'MySQL', 'MongoDB', 'Redis', 'KeyDB', 'Cassandra', 'Python', 'Java'])}
                    </div>
                    <strong>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 10.2308L3.08495 7.02346M12 10.2308L20.9178 7.03406M12 10.2308V20.8791M5.13498 18.5771L10.935 20.6242C11.3297 20.7635 11.527 20.8331 11.7294 20.8608C11.909 20.8853 12.091 20.8853 12.2706 20.8608C12.473 20.8331 12.6703 20.7635 13.065 20.6242L18.865 18.5771C19.6337 18.3058 20.018 18.1702 20.3018 17.9269C20.5523 17.7121 20.7459 17.4386 20.8651 17.1308C21 16.7823 21 16.3747 21 15.5595V8.44058C21 7.62542 21 7.21785 20.8651 6.86935C20.7459 6.56155 20.5523 6.28804 20.3018 6.0732C20.018 5.82996 19.6337 5.69431 18.865 5.42301L13.065 3.37595C12.6703 3.23665 12.473 3.167 12.2706 3.13936C12.091 3.11484 11.909 3.11484 11.7294 3.13936C11.527 3.167 11.3297 3.23665 10.935 3.37595L5.13498 5.42301C4.36629 5.69431 3.98195 5.82996 3.69824 6.0732C3.44766 6.28804 3.25414 6.56155 3.13495 6.86935C3 7.21785 3 7.62542 3 8.44058V15.5595C3 16.3747 3 16.7823 3.13495 17.1308C3.25414 17.4386 3.44766 17.7121 3.69824 17.9269C3.98195 18.1702 4.36629 18.3058 5.13498 18.5771Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Avancées:</strong>
                    <div className="skills-list">
                        {renderSkillButtons(['Factorisation de matrices', 'Protocole de Maillage Fractal Adaptatif'])}
                    </div>
                    <strong>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12H12.01M12 16H12.01M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Autres:</strong>
                    <div className="skills-list">
                        {renderSkillButtons(['WebSockets', 'Web-Scrapping', 'Protocole SMTP', 'Peer-to-Peer', 'Réseau Client-Serveur', 'Kubernetes', 'Docker', 'Git'])}
                    </div>
                </div>

            </div>

            {skillPopup && (
                <div className="skill-popup">
                    <div className="skill-popup-content">
                        <h3>{selectedSkill}</h3>
                        <p>{skillsInfo(selectedSkill)}</p>
                        <button className="close-skill-popup" onClick={closeSkillPopup}>Fermer</button>
                    </div>
                </div>
            )}

            {rightClickMenu && (
                <div className="right-click-menu" style={{ top: rightClickPosition.y, left: rightClickPosition.x }}>
                    <ul>
                        <li onClick={contactMe}>Me contacter</li>
                        <li onClick={() => openWindow('1', 'Mes projets', '1')}>Mes projets</li>
                        <li onClick={() => openWindow('2', 'Qui suis-je ?', '2')}>Qui suis-je ?</li>
                        <li onClick={() => setRightClickMenu(false)}>Actualiser</li>
                    </ul>
                </div>
            )}


            {showTextEditor && <TextEditor
                initialContent={savedContent?.content}
                onSave={handleSave}
                onClose={() => setShowTextEditor(false)} />}

            {showContact && <Contact onClose={() => setShowContact(false)} />}

            {showProjectManager && <ProjectManager onClose={() => setShowProjectManager(false)} />}

            {showFpsGame && <FPSGame onClose={() => closeFPS(false)} />}

            {showServiceOrder && <ServiceOrder onClose={() => setShowServiceOrder(false)} />}

            {showBlog && <Blog onClose={() => setShowBlog(false)} />}

            {showSocial && <Social onClose={() => setShowSocial(false)} />}

            {showFileUploader && <FileUploader onClose={() => setShowFileUploader(false)} />}

            {showVerifyUser && <VerifyUser onClose={() => setShowVerifyUser(false)} />}

            {showRoleManager && <RoleManager onClose={() => setShowRoleManager(false)} />}

            {showPasswordManager && <PasswordManager onClose={() => {
                setShowPasswordManager(false)
                // clear "sessionToken" from localStorage
                localStorage.removeItem('sessionToken');
                }} />}

            {showCyberSecurity && <CyberSecurity onClose={() => setShowCyberSecurity(false)} />}
        </div>
    );
};

export default Desktop;
