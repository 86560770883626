import React, { createContext, useContext, useReducer, useEffect } from 'react';

const NotificationContext = createContext();

const initialState = {
    notifications: [],
    history: []
};

function notificationReducer(state, action) {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
                history: [action.payload, ...state.history]
            };
        case 'REMOVE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.filter(notif => notif.id !== action.payload)
            };
        case 'CLEAR_NOTIFICATIONS':
            return {
                ...state,
                notifications: []
            };
        default:
            return state;
    }
}

export function NotificationProvider({ children }) {
    const [state, dispatch] = useReducer(notificationReducer, initialState);

    const addNotification = (notification) => {
        const id = Date.now();
        dispatch({ type: 'ADD_NOTIFICATION', payload: { ...notification, id } });

        if (notification.duration) {
            setTimeout(() => {
                dispatch({ type: 'REMOVE_NOTIFICATION', payload: id });
            }, notification.duration);
        }
    };

    const removeNotification = (id) => {
        dispatch({ type: 'REMOVE_NOTIFICATION', payload: id });
    };

    const clearNotifications = () => {
        dispatch({ type: 'CLEAR_NOTIFICATIONS' });
    };

    return (
        <NotificationContext.Provider value={{
            notifications: state.notifications,
            history: state.history,
            addNotification,
            removeNotification,
            clearNotifications
        }}>
            {children}
        </NotificationContext.Provider>
    );
}

export const useNotification = () => useContext(NotificationContext);


