// NoteSection.js
import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, Search } from 'lucide-react';
import NoteForm from './NoteForm';
import './NoteSection.scss';

const NoteSection = () => {
    const [notes, setNotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [currentNote, setCurrentNote] = useState(null);

    useEffect(() => {
        // Fetch notes from API
        // setNotes(fetchedNotes);
    }, []);

    const handleAddNote = () => {
        setCurrentNote(null);
        setShowForm(true);
    };

    const handleEditNote = (note) => {
        setCurrentNote(note);
        setShowForm(true);
    };

    const handleDeleteNote = (noteId) => {
        // Delete note and update state
        setNotes(notes.filter(note => note.id !== noteId));
    };

    const handleSaveNote = (noteData) => {
        if (currentNote) {
            // Update existing note
            setNotes(notes.map(note => note.id === currentNote.id ? { ...note, ...noteData } : note));
        } else {
            // Add new note
            setNotes([...notes, { ...noteData, id: Date.now() }]);
        }
        setShowForm(false);
    };

    const filteredNotes = notes.filter(note =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="note-section">
            <h2>Notes</h2>
            <div className="note-actions">
                <button className="add-btn" onClick={handleAddNote}><Plus size={16} /> Ajouter une note</button>
                <div className="search-bar">
                    <Search size={16} />
                    <input
                        type="text"
                        placeholder="Chercher une note..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <ul className="note-list">
                {filteredNotes.map(note => (
                    <li key={note.id} className="note-item">
                        <div className="note-content">
                            <h3>{note.title}</h3>
                            <p>{note.content.substring(0, 100)}...</p>
                        </div>
                        <div className="actions">
                            <button onClick={() => handleEditNote(note)}><Edit size={16} /></button>
                            <button onClick={() => handleDeleteNote(note.id)}><Trash2 size={16} /></button>
                        </div>
                    </li>
                ))}
            </ul>
            {showForm && (
                <NoteForm
                    note={currentNote}
                    onSave={handleSaveNote}
                    onClose={() => setShowForm(false)}
                />
            )}
        </div>
    );
};

export default NoteSection;