import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, Search } from 'lucide-react';
import PasswordForm from './PasswordForm';
import './PasswordSection.scss';

const PasswordSection = () => {
    const [passwords, setPasswords] = useState([]);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch passwords from API
        // setPasswords(fetchedPasswords);
    }, []);

    const handleAddPassword = () => {
        setCurrentPassword(null);
        setShowForm(true);
    };

    const handleEditPassword = (password) => {
        setCurrentPassword(password);
        // Open modal or form to edit password
    };

    const handleDeletePassword = (passwordId) => {
        // Delete password and update state
    };

    const handleSavePassword = (passwordData) => {
        if (currentPassword) {
            // Update existing password
        } else {
            // Add new password
        }
        setShowForm(false);
    }

    const filteredPasswords = passwords.filter(password =>
        password.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        password.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="password-section">
            <h2>Mots de passe</h2>
            <div className='password-actions'>
                <button className="add-btn" onClick={handleAddPassword}><Plus size={16} /> Ajouter un mot de passe</button>
                <div className="search-bar">
                    <Search size={16} />
                    <input
                        type="text"
                        placeholder="Chercher un titre..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <ul className="password-list">
                {filteredPasswords.map(password => (
                    <li key={password.id} className="password-item">
                        <span>{password.name}</span>
                        <div className="actions">
                            <button onClick={() => handleEditPassword(password)}><Edit size={16} /></button>
                            <button onClick={() => handleDeletePassword(password.id)}><Trash2 size={16} /></button>
                        </div>
                    </li>
                ))}
            </ul>


            {showForm && (
                <PasswordForm password={currentPassword} onSave={handleSavePassword} onClose={() => setShowForm(false)} />
            )}
        </div>
    );
};

export default PasswordSection;