import React, { useState, useEffect } from 'react';
import { X, Plus, ChevronDown, ChevronUp, Edit2, Trash2, CheckSquare, Square, Calendar, Clock, Users, BarChart2 } from 'lucide-react';
import logo from '../img/Tasks.png';
import '../styles/ProjectManager.scss';

const initialProjects = [
    {
        id: 1,
        name: "Refonte du site web",
        description: "Modernisation complète du site web de l'entreprise",
        startDate: "2024-09-15",
        endDate: "2025-03-15",
        progress: 25,
        tasks: [
            { id: 1, name: "Analyse des besoins", status: "completed", assignee: "Alice", dueDate: "2024-10-01" },
            { id: 2, name: "Design UI/UX", status: "in-progress", assignee: "Bob", dueDate: "2024-11-15" },
            { id: 3, name: "Développement front-end", status: "not-started", assignee: "Charlie", dueDate: "2025-01-31" },
            { id: 4, name: "Développement back-end", status: "not-started", assignee: "David", dueDate: "2025-02-28" },
        ]
    },
    // Add more initial projects as needed
];

const ProjectManager = ({ onClose }) => {
    const [projects, setProjects] = useState(initialProjects);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
    const [newProject, setNewProject] = useState({ name: '', description: '', startDate: '', endDate: '' });
    const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
    const [newTask, setNewTask] = useState({ name: '', status: 'not-started', assignee: '', dueDate: '' });

    const handleProjectSelect = (project) => {
        setSelectedProject(project);
    };

    const handleCreateProject = () => {
        const project = {
            ...newProject,
            id: Date.now(),
            progress: 0,
            tasks: []
        };
        setProjects([...projects, project]);
        setNewProject({ name: '', description: '', startDate: '', endDate: '' });
        setIsNewProjectModalOpen(false);
    };

    const handleCreateTask = () => {
        const task = {
            ...newTask,
            id: Date.now()
        };
        const updatedProject = {
            ...selectedProject,
            tasks: [...selectedProject.tasks, task]
        };
        setProjects(projects.map(p => p.id === selectedProject.id ? updatedProject : p));
        setSelectedProject(updatedProject);
        setNewTask({ name: '', status: 'not-started', assignee: '', dueDate: '' });
        setIsNewTaskModalOpen(false);
    };

    const handleTaskStatusChange = (taskId, newStatus) => {
        const updatedTasks = selectedProject.tasks.map(task =>
            task.id === taskId ? { ...task, status: newStatus } : task
        );
        const updatedProject = { ...selectedProject, tasks: updatedTasks };
        setProjects(projects.map(p => p.id === selectedProject.id ? updatedProject : p));
        setSelectedProject(updatedProject);
    };

    const calculateProjectProgress = (project) => {
        const completedTasks = project.tasks.filter(task => task.status === 'completed').length;
        return Math.round((completedTasks / project.tasks.length) * 100) || 0;
    };

    useEffect(() => {
        if (selectedProject) {
            const updatedProgress = calculateProjectProgress(selectedProject);
            if (updatedProgress !== selectedProject.progress) {
                const updatedProject = { ...selectedProject, progress: updatedProgress };
                setProjects(projects.map(p => p.id === selectedProject.id ? updatedProject : p));
                setSelectedProject(updatedProject);
            }
        }
    }, [selectedProject]);

    return (
        <div className="project-manager-popup">
            <div className="project-manager-header">
                <h2>
                    <img src={logo} alt="Gestionnaire de Projets" className='mini-logo' />
                    Gestionnaire de Projets</h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>
            <div className="project-manager-content">
                <div className="project-list">
                    <h3>Projets</h3>
                    <button className="create-project-btn" onClick={() => setIsNewProjectModalOpen(true)}>
                        <Plus size={16} /> Nouveau Projet
                    </button>
                    {projects.map(project => (
                        <div
                            key={project.id}
                            className={`project-item ${selectedProject?.id === project.id ? 'selected' : ''}`}
                            onClick={() => handleProjectSelect(project)}
                        >
                            <span>{project.name}</span>
                            <div className="project-progress">
                                <div className="progress-bar" style={{ width: `${project.progress}%` }}></div>
                                <span>{project.progress}%</span>
                            </div>
                        </div>
                    ))}
                </div>
                {selectedProject && (
                    <div className="project-details">
                        <h3>{selectedProject.name}</h3>
                        <p>{selectedProject.description}</p>
                        <div className="project-info">
                            <div>
                                <Calendar size={16} />
                                <span>Du {selectedProject.startDate} au {selectedProject.endDate}</span>
                            </div>
                            <div>
                                <BarChart2 size={16} />
                                <span>Progression : {selectedProject.progress}%</span>
                            </div>
                        </div>
                        <div className="task-list">
                            <h4>Tâches</h4>
                            <button className="create-task-btn" onClick={() => setIsNewTaskModalOpen(true)}>
                                <Plus size={16} /> Nouvelle Tâche
                            </button>
                            {selectedProject.tasks.map(task => (
                                <div key={task.id} className="task-item">
                                    <div className="task-status">
                                        {task.status === 'completed' ? (
                                            <CheckSquare size={16} onClick={() => handleTaskStatusChange(task.id, 'not-started')} />
                                        ) : (
                                            <Square size={16} onClick={() => handleTaskStatusChange(task.id, 'completed')} />
                                        )}
                                    </div>
                                    <div className="task-info">
                                        <span className="task-name">{task.name}</span>
                                        <div className="task-details">
                                            <span><Users size={12} /> {task.assignee}</span>
                                            <span><Calendar size={12} /> {task.dueDate}</span>
                                        </div>
                                    </div>
                                    <div className="task-actions">
                                        <Edit2 size={16} />
                                        <Trash2 size={16} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {isNewProjectModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Nouveau Projet</h3>
                        <input
                            type="text"
                            placeholder="Nom du projet"
                            value={newProject.name}
                            onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
                        />
                        <textarea
                            placeholder="Description"
                            value={newProject.description}
                            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                        ></textarea>
                        <input
                            type="date"
                            placeholder="Date de début"
                            value={newProject.startDate}
                            onChange={(e) => setNewProject({ ...newProject, startDate: e.target.value })}
                        />
                        <input
                            type="date"
                            placeholder="Date de fin"
                            value={newProject.endDate}
                            onChange={(e) => setNewProject({ ...newProject, endDate: e.target.value })}
                        />
                        <div className="modal-actions">
                            <button onClick={() => setIsNewProjectModalOpen(false)}>Annuler</button>
                            <button onClick={handleCreateProject}>Créer</button>
                        </div>
                    </div>
                </div>
            )}
            {isNewTaskModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Nouvelle Tâche</h3>
                        <input
                            type="text"
                            placeholder="Nom de la tâche"
                            value={newTask.name}
                            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
                        />
                        <select
                            value={newTask.status}
                            onChange={(e) => setNewTask({ ...newTask, status: e.target.value })}
                        >
                            <option value="not-started">Non commencée</option>
                            <option value="in-progress">En cours</option>
                            <option value="completed">Terminée</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Assigné à"
                            value={newTask.assignee}
                            onChange={(e) => setNewTask({ ...newTask, assignee: e.target.value })}
                        />
                        <input
                            type="date"
                            placeholder="Date d'échéance"
                            value={newTask.dueDate}
                            onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                        />
                        <div className="modal-actions">
                            <button onClick={() => setIsNewTaskModalOpen(false)}>Annuler</button>
                            <button onClick={handleCreateTask}>Créer</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectManager;