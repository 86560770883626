// Loading.js
import React from 'react';
import { Loader2 } from 'lucide-react';
import logo from '../img/Banner_transparent.png';
import '../styles/Loading.scss';

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <img src={logo} alt="logo" className="loading-logo" />
                <h2 className="loading-title">Chargement en cours</h2>
                <p className="loading-text">Veuillez patienter pendant que nous préparons votre fichier...</p>

               <br />
               <br />
               <br />
                <Loader2 className="loading-spinner" />
            </div>
        </div>
    );
};

export default Loading;