// ContactForm.js
import React, { useState } from 'react';
import { X } from 'lucide-react';
import './ContactForm.scss';

const ContactForm = ({ contact, onSave, onClose }) => {
    const [formData, setFormData] = useState(contact || {
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <div className="contact-form-modal">
            <div className="modal-content">
                <button className="close-btn" onClick={onClose}><X size={24} /></button>
                <h2>{contact ? 'Edit Contact' : 'Add New Contact'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="save-btn">Save</button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
