import React from "react";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import '../styles/AddBlogPost.scss';
import { getLink } from "../utils/pointer";
import { Cross, CrossIcon, DoorClosed, PanelTopClose, PanelTopCloseIcon, SidebarClose, X } from "lucide-react";

const AddBlogPost = ( { closeCreation }) => {
    const { user } = useUser();

    // form data
    const [title, setTitle] = React.useState("");
    const [content, setContent] = React.useState("");
    const [image, setImage] = React.useState("");
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState([]);
    

    const [message, setMessage] = React.useState("");

    const tags = [
        "Blog",
        "Portfolio",
        "Tech",
        "WebDev",
        "Issues",
        "Solutions",
        "ObjectiveDone",
    ]

    const categories = [
        "functionnality",
        "design",
        "performance",
        "security",
        "other",
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check each entry
        if (!title || !content) {
            setMessage("Veuillez remplir tous les champs");
            return;
        }

        if (!selectedTags.length) {
            setMessage("Veuillez sélectionner au moins un tag");
            return;
        }

        if (!selectedCategory.length) {
            setMessage("Veuillez sélectionner au moins une catégorie");
            return;
        }




        try {
            const response = await axios.post(
                getLink() + "/blog",
                { title, content, image, tags: selectedTags, category: selectedCategory },
                {
                    headers: { "x-access-token": localStorage.getItem("db_token") },
                }
            );
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message);
        }
    };

    const handleAddTag = (tag) => {
        setSelectedTags([...selectedTags, tag]);
    }

    const handleRemoveTag = (tag) => {
        setSelectedTags(selectedTags.filter(t => t !== tag));
    }

    const handleSelectCategory = (category) => {
        setSelectedCategory(...selectedCategory, category);
    }

    const handleRemoveCategory = (category) => {
        setSelectedCategory(categories.filter(c => c !== category));
    }

    const AddOrRemoveTag = (tag) => {
        if (selectedTags.includes(tag)) {
           return
        } else {
            handleAddTag(tag);
        }
    }

    const AddOrRemoveCategory = (category) => {
        if (selectedCategory.includes(category)) {
            return
        } else {
            handleSelectCategory(category);
        }
    }



    return (
        <div className="add-blog-post">
            <div className="close-button" onClick={closeCreation}>
                <X size="32" />
            </div>
            <div className="blog-post-container">
                <div className="blog-post-header">
                    <h2>Ajout d'un nouvel article</h2>
                    <p>Compte: {user.name} ({user.role})</p>
                </div>
                <form onSubmit={handleSubmit} className="blog-post-form">

                    <div className="blog-top">
                        {/* title */}
                        <div className="blog-post-selector">
                            <div className="blog-post-selector-header">
                                <h3>Titre</h3>
                                <p>Choisissez un titre pour votre article</p>
                            </div>
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required className="blog-post-title" />
                        </div>


                        {/* image ? */}
                        <div className="blog-post-selector">
                            <div className="blog-post-selector-header">
                                <h3>Image</h3>
                                <p>Choisissez une image pour votre article</p>
                            </div>
                            <input type="text" value={image} onChange={(e) => setImage(e.target.value)} className="blog-post-image" />
                        </div>
                    </div>




                    {/* tags */}
                    <div className="blog-post-selector">
                        <div className="blog-post-selector-header">
                            <h3>Tags</h3>
                            <p>Choisissez les tags qui correspondent à votre article</p>
                        </div>

                        <div className="blog-post-selector-list">
                            {tags.map((tag, index) => (
                                <label key={index} className="blog-post-tag" onClick={() => AddOrRemoveTag(tag)}>
                                    <input type="checkbox" />
                                    {tag}
                                </label>
                            ))}
                        </div>
                    </div>


                    {/* category */}
                    <div className="blog-post-selector">
                        <div className="blog-post-selector-header">
                            <h3>Catégorie</h3>
                            <p>Choisissez la catégorie qui correspond à votre article</p>
                        </div>

                        <div className="blog-post-selector-list">
                            {categories.map((category, index) => (
                                <label key={index} className="blog-post-category" onClick={() => AddOrRemoveCategory(category)}>
                                    <input type="checkbox" />
                                    {category}
                                </label>
                            ))}
                        </div>
                    </div>


                    <div className="blog-post-selector">
                        <div className="blog-post-selector-header">
                            <h3>Contenu</h3>
                            <p>Écrivez le contenu de votre article</p>
                        </div>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                        />

                    </div>
                    <button type="submit" className="blog-post-button">
                        Ajouter l'article
                    </button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default AddBlogPost;