import React, { useState } from 'react';
import '../styles/NotificationCenter.scss';
import { useUser } from '../contexts/UserContext';
import folder from "../img/folder.png";
import { useNotification } from '../contexts/NotificationContext';
import Notification from '../components/Notifications/Notification';

const NotificationCenter = ({ isVisible, handleClose }) => {
    const { user } = useUser();
    const { history } = useNotification();

    const [notifications, setNotifications] = useState(history);

    const clearNotifications = () => {
        // close the notification center
        handleClose();
    }




    return isVisible ? (
        <div className="notification-bar">
            <div className="notification-header">
                <h2>Notifications</h2>
                <button onClick={clearNotifications}>&times;</button>
            </div>
            <div className="notification-container">
                {notifications.map(notification => (
                    <Notification key={notification.id} notification={notification} />
                ))}
            </div>
            <div className="notification-history">
                <h3>Historique</h3>
                {history.slice(0, 5).map(notification => (
                    <div key={notification.id} className="history-item">
                        <h4>{notification.title}</h4>
                        <p>{notification.description}</p>
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

export default NotificationCenter;
