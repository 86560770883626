import React, { useState } from 'react';
import { ChevronRight, Clock, File, Lock, Mail, PlayCircle, Save, Verified, Video, X } from 'lucide-react';
import logo from '../img/Search.png';
import { getLink } from '../utils/pointer';
import { useUser } from '../contexts/UserContext';
import '../styles/VerifyUser.scss';

const VerifyUser = ({ onClose }) => {
    const { user } = useUser();
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        email: '',
        setupMfa: false,
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        // check if the password and confirm password match
        if (name === 'confirmPassword' && formData.password !== value) {
            setError('Les mots de passe ne correspondent pas');
        } else {
            setError('');
        }
    };

    const handleSubmit = () => {
        const { password, confirmPassword, email, setupMfa } = formData;

        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas');
            return;
        }

        if (password.length < 8 || password.length > 64) {
            setError('Le mot de passe doit contenir entre 8 et 64 caractères');
            return;
        }

        if (password.includes(email) || password.includes(user.name)) {
            setError('Le mot de passe ne doit pas contenir votre email ou votre nom');
            return;
        }

        const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
        const numbers = /[0-9]/g;
        const uppercase = /[A-Z]/g;
        const lowercase = /[a-z]/g;

        if (!specialChars.test(password) || !numbers.test(password)) {
            setError('Le mot de passe doit contenir des caractères spéciaux et des chiffres');
            return;
        }

        if (!uppercase.test(password) || !lowercase.test(password)) {
            setError('Le mot de passe doit contenir des lettres majuscules et minuscules');
            return;
        }

        // ici envoyer les données au serveur
        const data = {
            password,
            email,
            setupMfa,
        };


        fetch(getLink() + '/auth/ask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('db_token'),
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setError(data.error);
                } else {

                    // clear local storage
                    localStorage.removeItem('db_token');
                    localStorage.removeItem('user');

                    onClose();
                }
            })
            .catch((err) => setError(err.message));

        // onClose();

    };

    return (
        <div className="verify-popup">
            <div className="verify-header">
                <h2>
                    <img src={logo} alt="Music Player" className="logo" />
                    Vérification de votre compte
                </h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>

            <div className="verify-content">
                <p className="verify-subtitle">
                    <Verified size={18} />
                    Fonctionnalités des comptes vérifiés
                </p>

                <p className="verify-description">
                    Les comptes vérifiés ont accès à des fonctionnalités supplémentaires pour une meilleure expérience utilisateur.
                </p>


                <ul className="requirements">
                    <li><File size={18} color='#3a85ff' />Partage de fichiers</li>
                    <li><Save size={18} color='#3a85ff' />Sauvegarde des données</li>
                    <li><PlayCircle size={18} color='#3a85ff' />Création de playlists</li>
                    <li><Video size={18} color='#3a85ff' />Streaming jusqu'en 4K</li>


                </ul>


                <div className="form-group">
                    <div className="vu-form-info">
                        <label htmlFor="password">
                            <Lock size={18} />
                            Mot de passe</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="vu-form-info">
                        <label htmlFor="confirmPassword">
                            <Lock size={18} />
                            Confirmez le mot de passe</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="vu-form-info">
                        <label htmlFor="email">
                            <Mail size={18} />
                            Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="vu-form-info">
                        <div className='mfa-checkbox'>
                            <input
                                type="checkbox"
                                name="setupMfa"
                                id="setupMfa"
                                checked={formData.setupMfa}
                                onChange={handleChange}
                            />
                            <label htmlFor="setupMfa">Configurer l'authentification à deux facteurs</label>
                        </div>
                    </div>
                </div>

                {error && <p className="error-message">{error}</p>}

                <button className="submit-button" onClick={handleSubmit}>
                    Continuer
                    <ChevronRight size={24} />
                </button>

                <button className="later-button" onClick={onClose}>
                    Plus tard
                    <Clock size={24} />
                </button>
            </div>
        </div>
    );
};

export default VerifyUser;
