import React, { useState, useEffect } from 'react';
import { getLink } from '../../utils/pointer';
import './UserManagement.scss';
import { Plus, X, Edit, Trash2, Eye, User, Mail, Phone, Briefcase, PillBottle, Cookie, Calendar, CalendarArrowDown, Coins, Diamond, BaggageClaim, MonitorCheck, Star, PanelTopIcon, Lock, Unlock } from 'lucide-react';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isViewing, setIsViewing] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(getLink() + '/users', {
                    method: 'GET',
                    headers: {
                        'x-access-token': localStorage.getItem('db_token')
                    }
                });
                const data = await response.json();
                console.log("Users fetched from API", data);
                setUsers(data);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    const handleCreateUser = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const userData = Object.fromEntries(formData.entries());

        try {
            const response = await fetch(getLink() + '/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(userData)
            });
            const newUser = await response.json();
            setUsers([...users, newUser]);
            setIsCreating(false);
        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

    const handleEditUser = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const userData = Object.fromEntries(formData.entries());

        try {
            const response = await fetch(getLink() + `/user/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(userData)
            });
            const updatedUser = await response.json();
            setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
            setIsEditing(false);
            setSelectedUser(null);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
            try {
                await fetch(getLink() + `/user/${userId}`, {
                    method: 'DELETE',
                    headers: {
                        'x-access-token': localStorage.getItem('db_token')
                    }
                });
                setUsers(users.filter(user => user._id !== userId));
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    };

    const renderUserForm = (user = {}) => (
        <form onSubmit={user._id ? handleEditUser : handleCreateUser}>
            <div className="form-group">
                <label htmlFor="name"><User size={18} /> Nom</label>
                <input type="text" id="name" name="name" defaultValue={user.name || ''} required />
            </div>
            <div className="form-group">
                <label htmlFor="email"><Mail size={18} /> Email</label>
                <input type="email" id="email" name="email" defaultValue={user.email || ''} required />
            </div>
            <div className="form-group">
                <label htmlFor="phone"><Phone size={18} /> Téléphone</label>
                <input type="tel" id="phone" name="phone" defaultValue={user.phone || ''} />
            </div>
            <div className="form-group">
                <label htmlFor="role"><Briefcase size={18} /> Rôle</label>
                <select id="role" name="role" defaultValue={user.role || ''} required>
                    <option value="">Sélectionnez un rôle</option>
                    <option value="admin">Administrateur</option>
                    <option value="manager">Manager</option>
                    <option value="user">Utilisateur</option>
                </select>
            </div>
            <button type="submit">{user._id ? 'Modifier' : 'Créer'} l'utilisateur</button>
        </form>
    );

    const renderUsersTable = () => (
        <div className="users-table-container">
        <table className="users-table">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Est vérifié ?</th>
                    <th>Rôle</th>
                    <th>Inscription</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr key={user._id}>
                        <td>{user.user.name}</td>
                        <td>{user.securityCard ? 'Oui' : 'Non'}</td>
                        <td>{user.user.role}</td>
                        <td>{new Date(user.user.createdAt).toLocaleString()}</td>
                        <td>
                            <button onClick={() => { setSelectedUser(user); setIsEditing(true); }} title="Modifier">
                                <Edit size={18} />
                            </button>
                            <button onClick={() => handleDeleteUser(user.user._id)} title="Supprimer">
                                <Trash2 size={18} />
                            </button>
                            <button onClick={() => { setSelectedUser(user); handleViewUser(); }} title="Voir">
                                <Eye size={18} />
                            </button>
                            
                            {/* bannir, pardon */}
                            <button onClick={() => { setSelectedUser(user); }} title="Bannir">
                                <Lock size={18} />
                            </button>

                            <button onClick={() => { setSelectedUser(user); }} title="Pardonner">
                                <Unlock size={18} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );


    const handleViewUser = () => {
        setIsViewing(true);
    };


    return (
        <div className="user-management">
            <h1>Gestion des Utilisateurs</h1>
            <button onClick={() => setIsCreating(true)} className="create-user-btn">
                <Plus size={18} /> Ajouter un utilisateur
            </button>
            {renderUsersTable()}

            {isCreating && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Créer un nouvel utilisateur</h2>
                        <button className="close-btn" onClick={() => setIsCreating(false)}><X size={18} /></button>
                        {renderUserForm()}
                    </div>
                </div>
            )}

            {isEditing && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Modifier l'utilisateur</h2>
                        <button className="close-btn" onClick={() => setIsEditing(false)}><X size={18} /></button>
                        {renderUserForm(selectedUser)}
                    </div>
                </div>
            )}

            {isViewing && (
                <div className="modal">
                    <div className="modal-content">

                        <div className='scard-left'>
                            <button className="close-btn" onClick={() => setIsViewing(false)}><X size={18} /></button>
                            <h2>Profil de {selectedUser.user.name}</h2>
                            <p><img src={selectedUser.user.avatar} alt={selectedUser.user.name} style={{ width: '24px', height: '24px', borderRadius: '50%', paddingRight: '1rem' }} />
                                {selectedUser.user.name} ({selectedUser.user.level}/{selectedUser.user.experience})</p>
                            <p></p>
                            <p><Cookie size={18}color='#228fe2' />{selectedUser.user.cookiesAccepted ? 'Cookies acceptés' : 'Cookies non acceptés'}</p>
                            <p><Calendar size={18} color='#228fe2'/>{new Date(selectedUser.user.createdAt).toLocaleString()}</p>
                            <p><CalendarArrowDown size={18} color='#228fe2'/>{new Date(selectedUser.user.updatedAt).toLocaleString()}</p>
                            <hr />
                            <br />
                            {/* Coins, gems, inventory, cart, purchased, achievements, quests */}
                            <h2>Statistiques</h2>
                            <p><Coins size={18} color='#228fe2'/>{selectedUser.user.coins === 0 ? 'Pas de coins' : selectedUser.user.coins + ' coins'}</p>
                            <p><Diamond size={18} color='#228fe2'/>{selectedUser.user.gems === 0 ? 'Pas de gems' : selectedUser.user.gems + ' gems'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.inventory.length === 0 ? 'Inventaire vide' : selectedUser.user.inventory.length + ' items'}</p>
                            <p><MonitorCheck size={18} color='#228fe2'/>{selectedUser.user.cart.length === 0 ? 'Panier vide' : selectedUser.user.cart.length + ' items'}</p>
                            <p><PillBottle size={18} color='#228fe2'/>{selectedUser.user.purchased.length === 0 ? 'Pas d\'achats' : selectedUser.user.purchased.length + ' achats'}</p>
                            <p><Star size={18} color='#228fe2'/>{selectedUser.user.achievements.length === 0 ? 'Pas de succès' : selectedUser.user.achievements.length + ' succès'}</p>
                            <p><PanelTopIcon size={18} color='#228fe2'/>{selectedUser.user.quests.length === 0 ? 'Pas de quêtes' : selectedUser.user.quests.length + ' quêtes'}</p>


                            <hr />
                            <br />
                            {/* friends, friendRequests, sentRequests, unreadNotifications, notifications, stats, questsCompleted, questsActive */}
                            <h2>Social</h2>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.friends.length === 0 ? 'Pas d\'amis' : selectedUser.user.friends.length + ' amis'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.friendRequests.length === 0 ? 'Pas de demandes d\'amis' : selectedUser.user.friendRequests.length + ' demandes d\'amis'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.sentRequests.length === 0 ? 'Pas de demandes d\'amis envoyées' : selectedUser.user.sentRequests.length + ' demandes d\'amis envoyées'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.unreadNotifications.length === 0 ? 'Pas de notifications non lues' : selectedUser.user.unreadNotifications.length + ' notifications non lues'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.notifications.length === 0 ? 'Pas de notifications' : selectedUser.user.notifications.length + ' notifications'}</p>
                            <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.user.questsCompleted.length === 0 ? 'Pas de quêtes complétées' : selectedUser.user.questsCompleted.length + ' quêtes complétées'}</p>

                        </div>

                        <div className='scard-right'>
                            {selectedUser.securityCard ? (
                                <div>
                                    <h2>SCard de {selectedUser.user.name}</h2>
                                    
                                    {/* accountStatus, lastPasswordChange, hasTwoFactorAuth, lastIP, lastIPChange, ipHistory, currentDevice, lastDeviceChange, deviceHistory, isLocked, loginAttempts, passwordFailures, passwordResetRequested, createdAt, updatedAt */}
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.accountStatus}</p>
                                    <p><Calendar size={18} color='#228fe2'/>{new Date(selectedUser.securityCard.lastPasswordChange).toLocaleString()} — <Lock size={18} color='#fff'/></p>
                                    <p><CalendarArrowDown size={18} color='#228fe2'/>{new Date(selectedUser.securityCard.lastIPChange).toLocaleString()} — <Lock size={18} color='#fff'/></p>
                                    <br />
                                    <p><Calendar size={18} color='#228fe2'/>{new Date(selectedUser.securityCard.createdAt).toLocaleString()}</p>
                                    <p><CalendarArrowDown size={18} color='#228fe2'/>{new Date(selectedUser.securityCard.updatedAt).toLocaleString()}</p>
                                    <hr />
                                    <br />
                                    <h2>Historique</h2>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.ipHistory.length === 0 ? 'Pas d\'historique d\'IP' : selectedUser.securityCard.ipHistory.length + ' adresses IP :'}</p>
                                    {/* si il y a plusieurs ips alors les listers */}
                                    {selectedUser.securityCard.ipHistory.length > 0 && (
                                        <ul div className='history-list'>
                                            {selectedUser.securityCard.ipHistory.map((ip, index) => (
                                                <li key={index}>{ip}</li>
                                            ))}
                                        </ul>
                                    )}

                                    <p><MonitorCheck size={18} color='#228fe2' />{selectedUser.securityCard.deviceHistory.length === 0 ? 'Pas d\'historique de device' : selectedUser.securityCard.deviceHistory.length + ' devices :'}</p>

                                    {selectedUser.securityCard.deviceHistory.length > 0 && (
                                        <ul div className='history-list'>
                                            {selectedUser.securityCard.deviceHistory.map((device, index) => (
                                                <li key={index}>{device}</li>
                                            ))}
                                        </ul>
                                    )}

                                    <p><CalendarArrowDown size={18} color='#228fe2'/>{selectedUser.securityCard.tokenHistory.length === 0 ? 'Pas d\'historique de token' : selectedUser.securityCard.tokenHistory.length + ' tokens'}</p>
                                    <hr />
                                    <br />
                                    <h2>Punitions</h2>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.punishments.length === 0 ? 'Pas de punitions' : selectedUser.securityCard.punishments.length + ' punitions'}</p>
                                    <hr />
                                    <br />
                                    <h2>Verrouillages</h2>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.isLocked ? 'Verrouillé' : 'Non verrouillé'}</p>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.isTempLocked ? 'Verrouillé temporairement' : 'Non verrouillé temporairement'}</p>
                                    <p><Calendar size={18} color='#228fe2'/>{selectedUser.securityCard.tempLockEndTime ? new Date(selectedUser.securityCard.tempLockEndTime).toLocaleString() : 'Pas de verrouillage temporaire'}</p>
                                    <hr />
                                    <br />
                                    <h2>Autres</h2>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.loginAttempts === 0 ? 'Pas de tentatives de connexion' : selectedUser.securityCard.loginAttempts + ' tentatives de connexion'}</p>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.passwordFailures === 0 ? 'Pas d\'échecs de mot de passe' : selectedUser.securityCard.passwordFailures + ' échecs de mot de passe'}</p>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.passwordResetRequested ? 'Réinitialisation du mot de passe demandée' : 'Pas de demande de réinitialisation du mot de passe'}</p>
                                    <p><BaggageClaim size={18} color='#228fe2'/>{selectedUser.securityCard.passwordResetToken ? 'Token de réinitialisation de mot de passe' : 'Pas de token de réinitialisation de mot de passe'}</p>
                                    <p><Calendar size={18} color='#228fe2'/>{selectedUser.securityCard.passwordResetExpires ? new Date(selectedUser.securityCard.passwordResetExpires).toLocaleString() : 'Pas de date d\'expiration du token'}</p>
                                    

                                </div>
                            ) : (
                                <p>Aucune carte de sécurité n'a été définie pour cet utilisateur.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserManagement;