import React, { useState } from 'react';
import '../styles/StartMenu.scss';
import settings from "../img/wsettings.png";
import { useUser } from '../contexts/UserContext';
import { User, ListRestart } from 'lucide-react';
import folder from "../img/folder.png";
import userlogo from "../img/User.png";

const StartMenu = ({ isVisible }) => {
    const { user } = useUser();

    const handleShutDown = () => {
        // clear the local storage
        localStorage.clear();
        // reload the page
        window.location.reload();
    }


    return isVisible ? (
        <div className="start-menu">

            {/* search bar */}
            <div className="search-bar">
                <input type="text" placeholder="Rechercher des projets, des paramètres ou des documents..." />
            </div>

            <div className="menu-item">
                <img src={folder} alt="folder" className="icon" />
                Programmes</div>
            <div className="menu-item">
                <img src={folder} alt="folder" className="icon" />
                Documents</div>
            <div className="menu-item">
                <img src={settings} alt="settings" className="icon" />
                Paramètres
            </div>
            <div className="menu-item" onClick={handleShutDown}>
                <img src={folder} alt="folder" className="icon" />
                Eteindre
            </div>


            {/* ici nous allons faire le footer avec les infos de l'utilisateur à droite et un bouton shutdown à gauche */}
            <div className="footer">
                <div className="user-info">
                    <img src={user.avatar ? user.avatar : userlogo} alt="user" className="avatar" />
                    <p>{user.name} {user.role === 'admin' ? '— ⛏️' : ''}</p>
                </div>
                <div className="user-info" onClick={handleShutDown}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 3V12M18.3611 5.64001C19.6195 6.8988 20.4764 8.50246 20.8234 10.2482C21.1704 11.994 20.992 13.8034 20.3107 15.4478C19.6295 17.0921 18.4759 18.4976 16.9959 19.4864C15.5159 20.4752 13.776 21.0029 11.9961 21.0029C10.2162 21.0029 8.47625 20.4752 6.99627 19.4864C5.51629 18.4976 4.36274 17.0921 3.68146 15.4478C3.00019 13.8034 2.82179 11.994 3.16882 10.2482C3.51584 8.50246 4.37272 6.8988 5.6311 5.64001" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    ) : null;
};

export default StartMenu;
