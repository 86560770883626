import React, { createContext, useContext, useState, useEffect } from 'react';
import { getLink } from '../utils/pointer';
import axios from 'axios';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('db_token'));
    const [isAuth, setIsAuth] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if (token) {
            fetchUser();
        }
    }, [token]);

    const fetchUser = async () => {
        try {
            const response = await axios.get(getLink() + '/auth/me', {
                headers: { 'x-access-token': token }
            });
            console.log('fetchUser', response.data);
            if (response.data.error) {
                console.error('Error fetching user data:', response.data.error);
                logout();
                return;
            }
            setUser(response.data);
            setIsAuth(true);
            setIsStaff(response.data.role === 'admin');
            setIsVerified(response.data.isVerified);

            // add user to localStorage
            localStorage.setItem('user', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching user data:', error);
            logout();
        }
    };

    const login = async (name, password) => {
        try {
            const response = await axios.post(getLink() + '/auth/login', { name, password })
                .then(async (response) => {
                    console.log('Login response:', response.data);
                    setToken(response.data.token);
                    localStorage.setItem('db_token', response.data.token);
                    await fetchUser();
                    return true;
                })
                .catch((error) => {
                    console.error('Login error:', error);
                    return false;
                });
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const register = async (name) => {
        try {
            const res = await axios.post(getLink() + '/auth/register', { name })
                .then((response) => {
                    setToken(response.data.token);
                    localStorage.setItem('db_token', JSON.stringify(response.data.token));
                    localStorage.setItem('user', JSON.stringify(response.data.user));

                    console.info("Token in register", response.data.token, "Token in localStorage", localStorage.getItem('db_token'));
                    setUser(response.data.user);
                    setIsAuth(true);
                    setIsStaff(response.data.user.role === 'admin');
                    return true;
                })
                .catch((error) => {
                    console.error('Register error:', error);
                    return false;
                });
        } catch (error) {
            // console.error('Register error:', error);
            return false;
        }
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        // localStorage.removeItem('token');
    };

    const updateName = async (name) => {
        try {
            await axios.put(getLink() + '/auth/updateName', { name }, {
                headers: { 'x-access-token': token }
            });
            setUser(prevUser => ({ ...prevUser, name }));
        } catch (error) {
            console.error('Error updating name:', error);
            throw error;
        }
    };

    const markAsVisited = async () => {
        try {
            await axios.put(getLink() + 'auth/markAsVisited', {}, {
                headers: { 'x-access-token': token }
            });
            setUser(prevUser => ({ ...prevUser, hasVisited: true }));
        } catch (error) {
            console.error('Error marking as visited:', error);
            throw error;
        }
    };



    /*

    [ WEB SOCKET ]

    */

    return (
        <UserContext.Provider value={{ user, login, register, logout, updateName, markAsVisited, isAuth, isStaff, isVerified }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);

export const useUserValue = (key) => {
    const { user } = useUser();
    return user ? user[key] : null;
};

export const useUserVisited = () => {
    const { user } = useUser();
    return user ? user.hasVisited : false;
};

export const useUserSetVisited = () => {
    const { markAsVisited } = useUser();
    return markAsVisited;
};