// AccountVerificationRequired.js
import React from 'react';
import './AccountVerificationRequired.scss';
import { CheckCircle, Mail } from 'lucide-react';

const AccountVerificationRequired = ({ onVerifyAccount }) => {
    return (
        <div className="verification-required">
            <div className="verification-container">

                <h1><CheckCircle size={32} />Accès restreint</h1>
                <p>Seuls les utilisateurs vérifiés peuvent accéder à nos outils de gestion.</p>
                <p>Veuillez vérifier votre compte pour continuer.</p>
                <div className="call-to-action">
                    <Mail className="icon-mail" size={32} />
                    <p>Un email de vérification a été envoyé à votre adresse email.</p>
                    <button className="verify-btn" onClick={onVerifyAccount}>
                        Vérifier mon compte
                    </button>
                </div>
                <p className="footer-text">Vous n'avez pas reçu l'email ? <span className="resend-link">Renvoyer</span></p>
            </div>
        </div>
    );
};

export default AccountVerificationRequired;
