import React, { useState, useEffect } from 'react';
import { X, Heart, MessageCircle, ArrowLeft, Share2, Bookmark, Image as ImageIcon, Link as LinkIcon, Hash } from 'lucide-react';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { getLink } from '../utils/pointer';
import '../styles/Blog.scss';
import AddBlogPost from './AddBlogPost';

const Blog = ({ onClose }) => {
    const [updates, setUpdates] = useState([]);
    const [selectedUpdate, setSelectedUpdate] = useState(null);
    const [filter, setFilter] = useState('all');
    const { user, isStaff } = useUser();
    const [showAddBlogpost, setShowAddBlogpost] = useState(false);

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const response = await axios.get(getLink() + '/blog', {
                    headers: { 'x-access-token': localStorage.getItem('db_token') }
                });
                console.log('resp blog', response.data);
                setUpdates(response.data);


            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUpdates();

        console.info('User is staff:', isStaff);


    }, []);

    const handleLike = (updateId) => {
        setUpdates(updates.map(update =>
            update.id === updateId ? { ...update, likes: update.likes + 1 } : update
        ));
    };

    const handleComment = (updateId, comment) => {
        setUpdates(updates.map(update =>
            update.id === updateId ? { ...update, comments: [...update.comments, comment] } : update
        ));
    };

    const handleShare = (updateId) => {
        setUpdates(updates.map(update =>
            update.id === updateId ? { ...update, shares: update.shares + 1 } : update
        ));
        // Ici, vous pourriez ajouter une logique pour partager réellement la mise à jour
    };

    const handleBookmark = (updateId) => {
        setUpdates(updates.map(update =>
            update.id === updateId ? { ...update, bookmarks: update.bookmarks + 1 } : update
        ));
        // Ici, vous pourriez ajouter une logique pour sauvegarder réellement le signet
    };

    const handleUpdateClick = (update) => {
        setSelectedUpdate(update);
    };

    const handleBackToList = () => {
        setSelectedUpdate(null);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const filteredUpdates = filter === 'all' ? updates : updates.filter(update => update.category === filter);


    const openAddBlogpost = () => {
        setShowAddBlogpost(true);
    }

    const closeAddBlogpost = () => {
        setShowAddBlogpost(false);
    }

    const addBlogpost = () => {
        // Add new blogpost to database
        // Then fetch all blogposts again
        closeAddBlogpost();
    }

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(getLink() + `/blog/${id}`, {
                headers: { 'x-access-token': localStorage.getItem('db_token') }
            });
            console.log('resp blog', response.data);
            setUpdates(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    return (
        <div className="twitter-style-updates">
            <div className="updates-header">
                <h2>
                    {selectedUpdate ? <ArrowLeft size={24} onClick={handleBackToList} /> : null}
                    Mises à jour du site
                </h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>
            <div className="filter-bar">
                <button onClick={() => setFilter('all')} className={filter === 'all' ? 'active' : ''}>Toutes</button>
                <button onClick={() => setFilter('feature')} className={filter === 'feature' ? 'active' : ''}>Fonctionnalités</button>
                <button onClick={() => setFilter('performance')} className={filter === 'performance' ? 'active' : ''}>Performances</button>
                <button onClick={() => setFilter('security')} className={filter === 'security' ? 'active' : ''}>Sécurité</button>
                <button onClick={() => setFilter('design')} className={filter === 'design' ? 'active' : ''}>Design</button>
                <button onClick={() => setFilter('other')} className={filter === 'other' ? 'active' : ''}>Autres</button>
            </div>
            <div className="updates-content">
                {selectedUpdate ? (
                    <div className="update-details">
                        <div className="update-header">
                            {/* <img src={selectedUpdate.avatar} alt={selectedUpdate.author} className="avatar" /> */}
                            <div>
                                <h3>{selectedUpdate.authorName}</h3>
                                <span className="timestamp">{formatDate(selectedUpdate.timestamp)}</span>
                            </div>
                        </div>
                        <p>{selectedUpdate.content}</p>
                        {selectedUpdate.image && <img src={selectedUpdate.image} alt="Update visual" className="update-image" />}
                        {selectedUpdate.link && (
                            <a href={selectedUpdate.link} target="_blank" rel="noopener noreferrer" className="update-link">
                                <LinkIcon size={16} /> En savoir plus
                            </a>
                        )}
                        <div className="tags">
                            {selectedUpdate.tags.map(tag => (
                                <span key={tag} className="tag"><Hash size={14} /> {tag}</span>
                            ))}
                        </div>
                        <div className="update-stats">
                            <span><Heart size={16} /> {selectedUpdate.likes} likes</span>
                            <span><MessageCircle size={16} /> {selectedUpdate.comments.length} commentaires</span>
                            <span><Share2 size={16} /> {selectedUpdate.shares} partages</span>
                            <span><Bookmark size={16} /> {selectedUpdate.bookmarks} favoris</span>
                        </div>
                        <div className="comments-section">
                            <h4>Commentaires:</h4>
                            {selectedUpdate.comments.map((comment, index) => (
                                <div key={index} className="comment">
                                    <strong>{comment.author}:</strong> {comment.content}
                                </div>
                            ))}
                            <textarea
                                placeholder="Ajouter un commentaire..."
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleComment(selectedUpdate.id, { author: "Vous", content: e.target.value });
                                        e.target.value = '';
                                    }
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    filteredUpdates.map(update => (
                        <div key={update.id} className="update-item">
                            <div className="update-header" onClick={() => handleUpdateClick(update)}>
                                {/* <img src={update.avatar} alt={update.author} className="avatar" /> */}
                                <div>
                                    <h3>{update.authorName}</h3>
                                    <span className="timestamp">{formatDate(update.createdAt)}</span>
                                </div>

                                {isStaff && (
                                    <button onClick={() => handleDelete(update._id)} className="delete-button">Supprimer</button>
                                )}
                            </div>
                            <p onClick={() => handleUpdateClick(update)}>{update.content}</p>
                            {update.image && (
                                <img src={update.image} alt="Update visual" className="update-image" onClick={() => handleUpdateClick(update)} />
                            )}
                            {update.link && (
                                <a href={update.link} target="_blank" rel="noopener noreferrer" className="update-link">
                                    <LinkIcon size={16} /> En savoir plus
                                </a>
                            )}
                            <div className="tags">
                                {update.tags.map(tag => (
                                    <span key={tag} className="tag"><Hash size={14} /> {tag}</span>
                                ))}
                            </div>
                            <div className="update-actions">
                                <button onClick={() => handleLike(update.id)}>
                                    <Heart size={20} /> {update.likes}
                                </button>
                                <button onClick={() => handleUpdateClick(update)}>
                                    <MessageCircle size={20} /> {update.comments.length}
                                </button>
                                <button onClick={() => handleShare(update.id)}>
                                    <Share2 size={20} /> {update.shares}
                                </button>
                                <button onClick={() => handleBookmark(update.id)}>
                                    <Bookmark size={20} /> {update.bookmarks}
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* if user.isStaff add button to add a new blogpost */}
            {isStaff & !showAddBlogpost && (
                <button className="add-blogpost" onClick={openAddBlogpost}>Ajouter une publication</button>
            )}

            {showAddBlogpost && (
                <AddBlogPost closeCreation={closeAddBlogpost} />
            )}
        </div>
    );
};

export default Blog;