import React, { useState } from 'react';
import { BellRingIcon, File, HashIcon, IdCard, Music, SatelliteIcon, Settings, ShieldCheck, User, VideoIcon } from 'lucide-react'
import './AdminDashboard.scss';

// Composants pour chaque section
import UserManagement from './UserManagement';
import RoleManagement from './PermissionManagement';
import PermissionManagement from './PermissionManagement';
import CDNManagement from './CDNManagement';
import ScardManagement from './ScardManagement';
import ProjectManagement from './ProjectManagement';
// import MusicManagement from './MusicManagement';
// import MovieManagement from './MovieManagement';
// import ParamManagement from './ParamManagement';
import BlogPostManager from './BlogPostManager';
import Statistics from './Statistics';

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState('users');

  const renderSection = () => {
    switch (activeSection) {
      case 'users':
      return <UserManagement />;
      case 'roles':
        return <RoleManagement />;
      case 'permissions':
        return <PermissionManagement />;
      case 'cdns':
        return <CDNManagement />;
      case 'scards':
        return <ScardManagement />;
      case 'projects':
        return <ProjectManagement />;
      case 'music':
      // return <MusicManagement />;
      case 'movies':
      // return <MovieManagement />;
      case 'params':
      // return <ParamManagement />;
      case 'blogposts':
      return <BlogPostManager />;
      case 'statistics':
      return <Statistics />;
      default:
        return <div>Sélectionnez une section</div>;
    }
  };

  return (
    <div className="admin-dashboard">
      <aside className="sidebar">
        <nav>
          <ul>
            <li onClick={() => setActiveSection('projects')}><span><User size={18} color='#ffffff8c' /><hr />Projets</span></li>
            <li onClick={() => setActiveSection('users')}><span><User size={18} color='#ffffff8c' /><hr />Utilisateurs</span></li>
            <li onClick={() => setActiveSection('roles')}><span><HashIcon size={18} color='#ffffff8c' /><hr />Rôles & Permissions</span></li>
            <li onClick={() => setActiveSection('cdns')}><span><File size={18} color='#ffffff8c' /><hr />CDNs</span></li>
            {/* <li onClick={() => setActiveSection('scards')}><span><ShieldCheck size={18} color='#ffffff8c' /><hr />Scards</span></li> */}
            {/* <li onClick={() => setActiveSection('music')}><span><Music size={18} color='#ffffff8c' /><hr />Musiques</span></li> */}
            {/* <li onClick={() => setActiveSection('movies')}><span><VideoIcon size={18} color='#ffffff8c' /><hr />Films</span></li> */}
            {/* <li onClick={() => setActiveSection('params')}><span><Settings size={18} color='#ffffff8c' /><hr />Paramètres</span></li> */}
            <li onClick={() => setActiveSection('blogposts')}><span><BellRingIcon size={18} color='#ffffff8c' /><hr />Blog Posts</span></li>
            <li onClick={() => setActiveSection('statistics')}><span><SatelliteIcon size={18} color='#ffffff8c' /><hr />Statistiques</span></li>
          </ul>
        </nav>

      </aside>
      <main className="content">
        <section className="active-section">
          {renderSection()}
        </section>
      </main>
    </div>
  );
};

export default AdminDashboard;