import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';
import { X } from 'lucide-react';
import '../styles/FPSGame.scss';

const FPSGame = ({ onClose }) => {
    const mountRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const controlsRef = useRef(null);
    const moveForward = useRef(false);
    const moveBackward = useRef(false);
    const moveLeft = useRef(false);
    const moveRight = useRef(false);
    const canJump = useRef(false);
    const prevTime = useRef(performance.now());
    const velocity = useRef(new THREE.Vector3());
    const direction = useRef(new THREE.Vector3());
    const bullets = useRef([]);
    const targets = useRef([]);
    const gunRef = useRef(null);
    const [ammo, setAmmo] = useState(30);
    const [score, setScore] = useState(0);
    const [finalScore, setFinalScore] = useState(0);
    const [isGameOver, setIsGameOver] = useState(false);
    const [timeleft, setTimeleft] = useState(10);
    const clock = new THREE.Clock();



    const createProceduralTexture = (color1, color2, size = 512) => {
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = size;
        const context = canvas.getContext('2d');
        context.fillStyle = color1;
        context.fillRect(0, 0, size, size);
        context.fillStyle = color2;
        context.fillRect(0, 0, size / 2, size / 2);
        context.fillRect(size / 2, size / 2, size / 2, size / 2);



        return new THREE.CanvasTexture(canvas);
    };

    const createTerrain = () => {
        const groundTexture = createProceduralTexture('#4CAF50', '#45a049');
        groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
        groundTexture.repeat.set(25, 25);
        groundTexture.anisotropy = 16;

        const groundMaterial = new THREE.MeshLambertMaterial({ map: groundTexture });
        const groundMesh = new THREE.Mesh(new THREE.PlaneGeometry(500, 500), groundMaterial);
        groundMesh.rotation.x = -Math.PI / 2;
        groundMesh.receiveShadow = true;
        sceneRef.current.add(groundMesh);
    };

    const createSkybox = () => {
        const skyTexture = createProceduralTexture('#87CEEB', '#B0E0E6', 1024);
        const skyboxGeometry = new THREE.SphereGeometry(1000, 32, 32);
        const skyboxMaterial = new THREE.MeshBasicMaterial({ map: skyTexture, side: THREE.BackSide });
        const skybox = new THREE.Mesh(skyboxGeometry, skyboxMaterial);
        sceneRef.current.add(skybox);
    };

    const createTarget = () => {
        const geometry = new THREE.BoxGeometry(2, 3, 0.5);
        const texture = createProceduralTexture('#FF0000', '#CC0000');
        const material = new THREE.MeshPhongMaterial({ map: texture });
        const target = new THREE.Mesh(geometry, material);
        target.position.set(
            Math.random() * 100 - 50,
            1.5,
            Math.random() * 100 - 50
        );
        target.userData.velocity = new THREE.Vector3(
            Math.random() * 0.1 - 0.05,
            0,
            Math.random() * 0.1 - 0.05
        );
        sceneRef.current.add(target);
        targets.current.push(target);
    };

    const updateTargets = () => {
        targets.current.forEach((target) => {
            target.position.add(target.userData.velocity);
            if (Math.abs(target.position.x) > 50 || Math.abs(target.position.z) > 50) {
                target.userData.velocity.multiplyScalar(-1);
            }
        });
    };




    const shoot = () => {
        if (ammo > 0) {
            if (isGameOver) return; // Ne tirez pas si le jeu est termin
            if (controlsRef.current.isLocked === false) return; // Ne tirez pas si les contrôles ne sont pas verrouillés

            let amo;
            // etre sur que le joueur a encore des balles
            setAmmo((prevAmmo) => {
                if (prevAmmo > 0) {
                    amo = prevAmmo - 1;
                    return prevAmmo - 1;
                } else {
                    amo = 0;
                    return 0;
                }
            });

            console.info('Balle = ', amo);
            if (amo === 0) return; // Ne tirez pas si le joueur n'a plus de balles

            // Reste de votre code pour créer et tirer une balle
            const bulletGeometry = new THREE.SphereGeometry(0.3, 8, 8);
            const bulletMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            const bullet = new THREE.Mesh(bulletGeometry, bulletMaterial);
            bullet.position.set(0, 0, 0);
            bullet.position.applyMatrix4(cameraRef.current.matrixWorld);
            bullet.userData.velocity = new THREE.Vector3(0, 0, -1);
            bullet.userData.velocity.applyQuaternion(cameraRef.current.quaternion);
            bullet.userData.velocity.multiplyScalar(50);

            sceneRef.current.add(bullet);
            bullets.current.push(bullet);

            // Recul simulé pour la caméra et l'arme
            cameraRef.current.position.z -= 0.1;
            setTimeout(() => {
                cameraRef.current.position.z += 0.1;
            }, 100);

            gunRef.current.position.z -= 0.1;
            setTimeout(() => {
                gunRef.current.position.z += 0.1;
            }, 100);
        }
    };


    const handleReload = () => {
        if (!isGameOver) {
            setAmmo(30);
        }
    };


    const updateBullets = () => {

        if (isGameOver) return; // Ne tirez pas si le jeu est terminé

        for (let i = bullets.current.length - 1; i >= 0; i--) {
            const bullet = bullets.current[i];
            bullet.position.add(bullet.userData.velocity.clone().multiplyScalar(clock.getDelta()));

            // Check for collision with targets
            for (let j = targets.current.length - 1; j >= 0; j--) {
                const target = targets.current[j];
                if (bullet.position.distanceTo(target.position) < 1.5) {
                    sceneRef.current.remove(target);
                    targets.current.splice(j, 1);
                    setScore(prevScore => prevScore + 100);
                    createTarget(); // Create a new target
                    break;
                }
            }

            // Remove bullet if it's too far away
            if (bullet.position.length() > 500) {
                sceneRef.current.remove(bullet);
                bullets.current.splice(i, 1);
            }

            // clear bullets that hit the ground
            if (bullet.position.y < 0) {
                sceneRef.current.remove(bullet);
                bullets.current.splice(i, 1);
            }

            // clear bullets that hit the sky
            if (bullet.position.y > 100) {
                sceneRef.current.remove(bullet);
                bullets.current.splice(i, 1);
            }

        }
    };

    useEffect(() => {
        // Décrémente le timer chaque seconde
        const timerInterval = setInterval(() => {
            setTimeleft((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    setIsGameOver(true);
                    clearInterval(timerInterval);  // Arrêter l'intervalle une fois le temps écoulé
                    setFinalScore(score);
                    return 0;
                }
            });
        }, 1000);

        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(timerInterval);
    }, []);


    const init = () => {
        sceneRef.current = new THREE.Scene();
        sceneRef.current.fog = new THREE.Fog(0x87ceeb, 0, 500);

        cameraRef.current = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        cameraRef.current.position.y = 2;

        controlsRef.current = new PointerLockControls(cameraRef.current, document.body);

        const blocker = document.getElementById('blocker');
        const instructions = document.getElementById('instructions');

        instructions.addEventListener('click', () => {
            if (isGameOver) return; // Ne commencez pas à jouer si le jeu est terminé
            controlsRef.current.lock();

            // prevent the cursor from leaving the game
            document.body.requestPointerLock = document.body.requestPointerLock || document.body.mozRequestPointerLock;

            if (document.body.requestPointerLock) {
                document.body.requestPointerLock();
            }


        });

        controlsRef.current.addEventListener('lock', () => {
            instructions.style.display = 'none';
            blocker.style.display = 'none';
        });
        controlsRef.current.addEventListener('unlock', () => {
            blocker.style.display = 'block';
            instructions.style.display = '';
        });

        sceneRef.current.add(controlsRef.current.getObject());

        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        document.addEventListener('click', shoot);

        createTerrain();
        createSkybox();

        // Add ambient light
        const ambientLight = new THREE.AmbientLight(0x404040);
        sceneRef.current.add(ambientLight);

        // Add directional light
        const dirLight = new THREE.DirectionalLight(0xffffff, 1);
        dirLight.position.set(10, 10, 10);
        sceneRef.current.add(dirLight);

        // Create a simple gun model
        const gunGeometry = new THREE.BoxGeometry(0.1, 0.1, 0.3);
        const gunMaterial = new THREE.MeshPhongMaterial({ color: 0x333333 });

        // add a sight to the gun
        const sightGeometry = new THREE.BoxGeometry(0.01, 0.01, 0.01);
        const sightMaterial = new THREE.MeshPhongMaterial({ color: 0x000000 });
        const sight = new THREE.Mesh(sightGeometry, sightMaterial);
        sight.position.set(0, 0, 0);
        cameraRef.current.add(sight);

        // add a gun to the camera
        gunRef.current = new THREE.Mesh(gunGeometry, gunMaterial);
        gunRef.current.position.set(0.3, -0.3, -0.5);
        cameraRef.current.add(gunRef.current);

        // Create targets
        for (let i = 0; i < 10; i++) {
            createTarget();
        }


        // add crosshair to the camera view
        const crosshairGeometry = new THREE.RingGeometry(0.01, 0.02, 10);
        const crosshairMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const crosshair = new THREE.Mesh(crosshairGeometry, crosshairMaterial);
        crosshair.position.z = -2;
        cameraRef.current.add(crosshair);




        rendererRef.current = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true, powerPreference: 'high-performance' });
        rendererRef.current.setPixelRatio(window.devicePixelRatio);
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);
        rendererRef.current.shadowMap.enabled = true;
        mountRef.current.appendChild(rendererRef.current.domElement);


        // Add event listeners
        // window.addEventListener('resize', onWindowResize);

    };

    const onWindowResize = () => {
        cameraRef.current.aspect = window.innerWidth / window.innerHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    };

    const onKeyDown = (event) => {
        switch (event.code) {
            case 'ArrowUp':
            case 'KeyW':
                moveForward.current = true;
                break;
            case 'ArrowLeft':
            case 'KeyA':
                moveLeft.current = true;
                break;
            case 'ArrowDown':
            case 'KeyS':
                moveBackward.current = true;
                break;
            case 'ArrowRight':
            case 'KeyD':
                moveRight.current = true;
                break;
            case 'Space':
                if (canJump.current) {
                    velocity.current.y += 50;
                    canJump.current = false;
                }
                break;
            case 'KeyR':
               
                handleReload();
                break;
        }
    };

    const onKeyUp = (event) => {
        switch (event.code) {
            case 'ArrowUp':
            case 'KeyW':
                moveForward.current = false;
                break;
            case 'ArrowLeft':
            case 'KeyA':
                moveLeft.current = false;
                break;
            case 'ArrowDown':
            case 'KeyS':
                moveBackward.current = false;
                break;
            case 'ArrowRight':
            case 'KeyD':
                moveRight.current = false;
                break;
        }
    };

    const animate = () => {

        requestAnimationFrame(animate);

        const time = performance.now();

        if (controlsRef.current.isLocked === true) {
            const delta = (time - prevTime.current) / 1000;

            velocity.current.x -= velocity.current.x * 10.0 * delta;
            velocity.current.z -= velocity.current.z * 10.0 * delta;
            velocity.current.y -= 9.8 * 100.0 * delta; // 100.0 = mass

            direction.current.z = Number(moveForward.current) - Number(moveBackward.current);
            direction.current.x = Number(moveRight.current) - Number(moveLeft.current);
            direction.current.normalize();

            if (moveForward.current || moveBackward.current) velocity.current.z -= direction.current.z * 150.0 * delta;
            if (moveLeft.current || moveRight.current) velocity.current.x -= direction.current.x * 150.0 * delta;

            controlsRef.current.moveRight(-velocity.current.x * delta);
            controlsRef.current.moveForward(-velocity.current.z * delta);

            controlsRef.current.getObject().position.y += velocity.current.y * delta;

            if (controlsRef.current.getObject().position.y < 2) {
                velocity.current.y = 0;
                controlsRef.current.getObject().position.y = 2;
                canJump.current = true;
            }
        }


        updateTargets();
        updateBullets();

        prevTime.current = time;
        rendererRef.current.render(sceneRef.current, cameraRef.current);



        // create a bullet clearer automatically
        setInterval(() => {
            for (let i = bullets.current.length - 1; i >= 0; i--) {
                const bullet = bullets.current[i];
                if (bullet.position.y < 0) {
                    sceneRef.current.remove(bullet);
                    bullets.current.splice(i, 1);
                }
            }
        }, 1000);
    };

    useEffect(() => {
        init();
        animate();

        return () => {
            cancelAnimationFrame(animate);
            window.removeEventListener('resize', onWindowResize);
            if (mountRef.current && rendererRef.current) {
                mountRef.current.removeChild(rendererRef.current.domElement);
            }
        };
    }, []);

    const CloseGame = () => {
        if (controlsRef.current) controlsRef.current.dispose();
        if (rendererRef.current) rendererRef.current.dispose();
        if (sceneRef.current) sceneRef.current.clear();

        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
        document.removeEventListener('click', shoot);
        window.removeEventListener('resize', onWindowResize);

        mountRef.current = null;
        sceneRef.current = null;
        cameraRef.current = null;
        rendererRef.current = null;
        controlsRef.current = null;
        bullets.current = [];
        targets.current = [];

        onClose();
    };

    return (
        <div className="fps-game-popup">
            <div className="fps-game-header">
                <h2>Jeux de tir à la première personne</h2>
                <button className="close-button" onClick={CloseGame}>
                    <X size={24} />
                </button>
            </div>
            <div ref={mountRef} className="fps-game-content">
                <div id="blocker">
                    <div id="instructions">
                        <p style={{ fontSize: '36px' }}>Cliquez pour jouer</p>
                        <p>
                            Déplacement: WASD<br />
                            Saut: Espace<br />
                            Tirer: Clic-gauche (souris)<br />
                            Recharger: R<br />
                            Vue: Souris
                        </p>
                    </div>
                </div>
                <div id="hud">
                    <div>Temps restant: {timeleft}</div>
                    <div>Balle: {ammo}</div>
                    <div>Score: {score}</div>
                </div>


                {isGameOver && (
                    <div className="game-over">
                        <h2>Game Over</h2>
                        <p>Votre score: {finalScore}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FPSGame;