import React, { useState, useEffect } from 'react';
import { FileWarning } from 'lucide-react';
import { useUser } from '../contexts/UserContext';
import logo from '../img/Banner_transparent.png';
import '../styles/Setup.scss';

const Setup = () => {
  const { user, register, login } = useUser();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentView, setCurrentView] = useState('register');

  const handleRegister = async () => {
    if (name.trim() === '') {
      setError('Please enter your name');
      return;
    }

    // verify that name is only letters and Numbers
    const nameRegex = /^[a-zA-Z0-9]+$/;
    if (!nameRegex.test(name)) {
      setError('Name can only contain letters and numbers');
      return;
    }

    if (!termsAccepted) {
      setError('Please accept the terms of service and privacy policy');
      return;
    }

    const isRegister = await register(name);


    if (isRegister) {
      setCurrentView('mfa');
    } else {
      setError('This name already exists');
    }
  };


  const handleLogin = async () => {
    if (name.trim() === '') {
      setError('Please enter your name');
      return;
    }

    // verify that name is only letters and Numbers
    const nameRegex = /^[a-zA-Z0-9]+$/;
    if (!nameRegex.test(name)) {
      setError('Name can only contain letters and numbers');
      return;
    }

    // Verify that there is a password and that not empty
    if (password.trim() === '') {
      setError('Please enter your password');
      return;
    }

    const isLogin = await login(name, password);

    if (isLogin) {
      setCurrentView('mfa');
    } else {
      setError('Invalid name or password. Account may be locked.');
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    setError('');
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setError('');
  };




  useEffect(() => {
    if (error !== '') {
      console.error('Error:', error);
    }
  }, [error]);


  const handleAcceptTerms = () => {
    setTermsAccepted(!termsAccepted);
    setError('');
  };

  const renderRegisterView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
        <h3>Welcome to my Portfolio,</h3>
        <p className='welcome'>
          <strong>Check out my projects</strong> and learn more about me.
        </p>
      </div>
      {error !== '' && (
        <div className="error">
          <FileWarning size={24} />
          <span>{error}</span>
        </div>
      )}
      <div className="setup-step">
        <h2>Pick your Name</h2>
        <input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="checkbox-wrapper" onClick={handleAcceptTerms}>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={handleAcceptTerms}
          />
          <span className="checkbox__text">I accept the <strong>Terms of Service</strong> and <strong>Privacy Policy</strong></span>
        </label>
      </div>
      <div className='footer-login'>
        <button onClick={handleRegister} className="next-button">
          Discover
        </button>
        <div className="has-account">
          <hr />
          <button className="account" onClick={() => setCurrentView('login')}>I have an account</button>
        </div>
      </div>
    </>
  );

  const renderLoginView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
        <h3>Login to your account</h3>
        <p className='welcome'>
          <strong>Access your account</strong> and continue where you left off.
        </p>
      </div>
      {error !== '' && (
        <div className="error">
          <FileWarning size={24} />
          <span>{error}</span>
        </div>
      )}
      <div className="setup-step">
        <p className='login-step'>Name</p>
        <input type="text" placeholder="Name" onChange={handleChangeName} />
        <p className='login-step'>Password</p>
        <input type="password" placeholder="Password" onChange={handleChangePassword} />
      </div>
      <div className='footer-login'>
        <button className="next-button" onClick={() => handleLogin()}>
          Login
        </button>
        <div className="has-account">
          <hr />
          <button className="account" onClick={() => setCurrentView('register')}>Create an account</button>
        </div>
      </div>
    </>
  );

  const renderMfaView = () => (
    <>
      <div className='header-setup'>
        <img src={logo} alt="Logo" className="logo" />
        <h3>Verify your account</h3>
        <p className='welcome'>
          <strong>Enter the code</strong> sent to your email or from App.
        </p>
      </div>
      <div className="setup-step">
        <input type="text" placeholder="Code" />
      </div>
      <div className='footer-login'>
        <button className="next-button">
          Verify
        </button>
      </div>
    </>
  );

  return (
    <div className="setup-page">
      <div className="backgroundblur">
        <div className="setup-container">
          {currentView === 'register' && renderRegisterView()}
          {currentView === 'login' && renderLoginView()}
          {currentView === 'mfa' && renderMfaView()}
        </div>
      </div>
    </div>
  );
}

export default Setup;