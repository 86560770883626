// OTPVerification.js
import React, { useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { getLink } from '../../utils/pointer';
import './OTPVerification.scss';

const OTPVerification = ({ onVerify }) => {
    const [otp, setOtp] = useState('');
    const [tryLeft, setTryLeft] = useState(3);
    const [isLocked, setIsLocked] = useState(false);
    const { user } = useUser();

    const handleSubmit = (e) => {
        e.preventDefault();

        const fetcher = async () => {
            try {
                const response = await fetch(getLink() + '/auth/private', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('db_token'),
                    },
                    body: JSON.stringify({ password: otp }),
                });
                const data = await response.json();
                // if response isn't ok, throw an error
                if (!response.ok) {
                    setTryLeft(tryLeft - 1);
                    if (tryLeft === 0) {
                        setIsLocked(true);
                    }
                }

                // Check if response contains "sessionToken"
                if (data.sessionToken) {
                    onVerify(data.sessionToken);
                }
            } catch (error) {
                alert(error.message);
            }
        };

        fetcher();
    };

    const renderTryCount = () => {
        if (tryLeft < 3) {
            return <p className="try-left">Essais restants: {tryLeft}</p>;
        }
    };

    const renderLocked = () => {
        if (isLocked) {
            return <p className="locked">Compte bloqué. Veuillez contacter l'administrateur</p>;
        }
    };


    const handlePasswordChange = (e) => {
        setOtp(e.target.value);
    };

    return (
        <div className="otp-verification">
            <div className="otp-box">
                <h2>Vérification d'identité</h2>
                <p>Veuillez entrer votre mot de passe afin de continuer</p>
                {renderTryCount()}
                {renderLocked()}
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        value={otp}
                        onChange={handlePasswordChange}
                        placeholder="Votre mot de passe"
                        required
                    />
                    <button type="submit" className="verify-btn">Vérifier</button>
                </form>
            </div>
        </div>
    );
};

export default OTPVerification;
