import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useUser } from '../contexts/UserContext';
import { X, Hash, User, Send, Plus, Settings, Mic, Headphones, Video } from 'lucide-react';
import axios from 'axios';
import { getLink, getWsLink } from '../utils/pointer';
import serverLogo from '../img/serverName.png';
import '../styles/Social.scss';

const Social = ({ onClose }) => {
    const [channels, setChannels] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentChannel, setCurrentChannel] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showNewChannelForm, setShowNewChannelForm] = useState(false);
    const chatEndRef = useRef(null);
    const { user, isStaff } = useUser();
    const [websocket, setWebsocket] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const reconnectTimeoutRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [timestampLastTyping, setTimestampLastTyping] = useState(null);


    const connectToWebSocket = useCallback((channelId) => {
        if (websocket) {
            websocket.close();
        }

        const ws = new WebSocket(getWsLink() + `/api/ws?token=${localStorage.getItem('db_token')}`);

        ws.onopen = () => {
            console.log('Connected to WebSocket');
            setWebsocket(ws);

            if (channelId) {
                ws.send(JSON.stringify({ type: 'join_channel', channelId }));
            }
        };

        ws.onmessage = (message) => {
            const data = JSON.parse(message.data);

            switch (data.type) {
                case 'channel_data':
                    setUsers(data.onlineUsers);
                    setMessages(data.messages);
                    break;
                case 'new_message':
                    setMessages(prevMessages => {
                        // Vérifier si le message existe déjà pour éviter les doublons
                        const messageExists = prevMessages.some(msg =>
                            msg.authorId === data.message.authorId &&
                            msg.timestamp === data.message.timestamp
                        );
                        if (!messageExists) {
                            return [...prevMessages, data.message];
                        }
                        return prevMessages;
                    });
                    break;
                case 'message_sent':
                    console.log('Message sent successfully:', data.message);
                    break;
                case 'start_typing':
                    console.log('User started typing:', data.authorName);
                    break;
                case 'stop_typing':
                    console.log('User stopped typing:', data.authorName);
                    break;
                case 'typing_sent':
                    console.log('Typing sent successfully:', data);
                    break;
                case 'new_typing':
                    console.log('New typing:', data.authorName);
                    break;
                case 'user_left':
                    console.log('User left:', data.authorName);
                    break;
                default:
                    console.error('Unknown message type:', data.type);
            }
        };

        ws.onclose = () => {
            console.log('Disconnected from WebSocket');
            setWebsocket(null);
            // Tenter de se reconnecter après 5 secondes
            reconnectTimeoutRef.current = setTimeout(() => connectToWebSocket(channelId), 5000);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            ws.close();
        };

        return ws;
    }, []);


    useEffect(() => {
        if (!user) return;

        const fetchChannels = async () => {
            try {
                const response = await axios.get(getLink() + '/channels', {
                    headers: { 'x-access-token': localStorage.getItem('db_token') }
                });
                setChannels(response.data);
                if (response.data.length > 0) {
                    setCurrentChannel(response.data[0]);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching channels:', error);
                setIsLoading(false);
            }
        };

        fetchChannels();

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [user]);

    useEffect(() => {
        if (currentChannel && !websocket) {
            const ws = connectToWebSocket(currentChannel._id);
            return () => {
                if (ws) ws.close();
            };
        }
    }, [currentChannel, connectToWebSocket]);



    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);


    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim() === '' || !websocket || !currentChannel) return;

        const message = {
            type: 'message',
            channelId: currentChannel._id,
            content: newMessage,
            authorId: user.id,
            authorName: user.name,
            timestamp: new Date().toISOString()
        };

        websocket.send(JSON.stringify(message));

        // Ne pas ajouter le message localement, attendre la confirmation du serveur
        setNewMessage('');
        setIsTyping(false);
        handleStopTyping();
    };


    const handleSendTyping = () => {
        if (isTyping) return;
        if (!websocket || !currentChannel) return;

        const message = {
            type: 'start_typing',
            channelId: currentChannel._id,
            authorId: user.id,
            authorName: user.name
        };

        websocket.send(JSON.stringify(message));
        setTimestampLastTyping(new Date().getTime());
    };


    useEffect(() => {
        if (isTyping && timestampLastTyping) {
            const now = new Date().getTime();
            const diff = now - timestampLastTyping;

            if (diff > 2000) {

                handleStopTyping();
            }

            console.log('Diff:', diff, 'ms');
        }
    }, [isTyping, timestampLastTyping]);



    const handleStopTyping = () => {
        if (!websocket || !currentChannel) return;

        const message = {
            type: 'stop_typing',
            channelId: currentChannel._id,
            authorId: user.id,
            authorName: user.name
        };

        websocket.send(JSON.stringify(message));
    };


    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
        setIsTyping(e.target.value.length > 0);
        handleSendTyping();
    };


    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };


    const handleChannelCreate = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(getLink() + '/channels', {
                title: e.target[0].value,
                topic: e.target[1].value,
                authorName: user.name
            }, {
                headers: { 'x-access-token': localStorage.getItem('db_token') }
            });
            setChannels([...channels, response.data]);
            setShowNewChannelForm(false);
        } catch (error) {
            console.error('Error creating channel:', error);
        }
    };


    const renderChannelForm = () => {
        if (!showNewChannelForm) return null;
        return (
            <div className="new-channel-form">
                <form onSubmit={handleChannelCreate}>
                    <input type="text" placeholder="Nom du salon" />
                    <input type="text" placeholder="Topic" />
                    <button type="submit">Créer</button>
                </form>
            </div>
        );
    };

    const handleChannelChange = (channel) => {
        if (websocket) {
            websocket.send(JSON.stringify({ type: 'leave_channel', channelId: currentChannel._id }));
            websocket.send(JSON.stringify({ type: 'join_channel', channelId: channel._id }));
        }
        setCurrentChannel(channel);
        setMessages([]);
    };

    return (
        <div className="discord-style-chat">
            <div className="updates-header">
                <h2>
                    Réseau social
                </h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>


            {isLoading ? (
                <div className="loading">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="updates-list">
                    <div className="server-list">
                        <div className="server active">
                            <img src={serverLogo} alt="Server icon" />
                        </div>
                        <div className="server add-server">
                            <Plus size={24} />
                        </div>
                    </div>
                    <div className="channels-container">
                        <div className="channels-header">
                            <h2>Ds-Holding » Room</h2>
                            <X size={24} onClick={onClose} className="close-button" />
                        </div>
                        <div className="channels-list">
                            {channels.map(channel => (
                                <div
                                    key={channel.id}
                                    className={`channel ${currentChannel.id === channel.id ? 'active' : ''}`}
                                    onClick={() => setCurrentChannel(channel)}
                                >
                                    <Hash size={20} />
                                    <span>{channel.title}</span>
                                </div>
                            ))}

                            {isStaff && (
                                <div className="channel add-channel" onClick={() => setShowNewChannelForm(true)}>
                                    <Plus size={20} />
                                    <span>Ajouter un salon</span>
                                </div>
                            )}
                        </div>
                        <div className="user-controls">
                            <img src="https://api.dicebear.com/6.x/avataaars/svg?seed=Alice" alt="User avatar" className="avatar" />
                            <div className="user-info">
                                <span className="username">{user ? user.name : 'Inconnu'}</span>
                                <span className="status">En ligne</span>
                            </div>
                            <div className="controls">
                                <Mic size={20} />
                                <Headphones size={20} />
                                <Settings size={20} />
                            </div>
                        </div>
                    </div>
                    <div className="chat-container">
                        <div className="chat-header">
                            <Hash size={24} />
                            <h3>{currentChannel ? currentChannel.title : 'Select a channel'}</h3>
                            <p>— {currentChannel ? currentChannel.topic : ''}</p>
                        </div>
                        <div className="chat-messages">
                            {messages.map((message, index) => (
                                <div key={index} className="message">
                                    <img
                                        src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${message.authorName}`}
                                        alt="User avatar"
                                        className="avatar"
                                    />
                                    <div className="message-content">
                                        <div className="message-header">
                                            <span className="username">{message.authorName}</span>
                                            <span className="timestamp">{formatTimestamp(message.timestamp)}</span>
                                        </div>
                                        <p>{message.content}</p>
                                    </div>
                                </div>
                            ))}
                            <div ref={chatEndRef} />
                        </div>
                        <form onSubmit={handleSendMessage} className="message-input">
                            <input
                                type="text"
                                placeholder={`Envoyer un message dans #${currentChannel ? currentChannel.title : ''}`}
                                value={newMessage}
                                onChange={(e) => handleMessageChange(e)}
                            />
                            <button type="submit">
                                <Send size={20} />
                            </button>
                        </form>
                    </div>
                    <div className="users-list">
                        <h3>En ligne - {users.filter(u => u.status !== 'offline').length}</h3>
                        {users.map(user => (
                            <div key={user.id} className={`user ${user.status}`}>
                                <img src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${user.name}`} alt="User avatar" style={{ borderRadius: '50%', width: '32px', height: '32px' }} />
                                <span>{user.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}



            {renderChannelForm()}
        </div>
    );
};

export default Social;