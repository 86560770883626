import React, { useState, useRef, useEffect } from 'react';
import { Upload, Link, Lock, FileText, Send, X, ChevronRight, List, Heart, Folder, LucideTable, Text, Weight, Eye, Download, Pointer, ChevronLeft } from 'lucide-react';
import { getLink } from '../utils/pointer';
import { useNotification } from '../contexts/NotificationContext';

import '../styles/FileUploader.scss';

const FileList = ({ unShow, refreshFiles, files, setFiles }) => {
    // const [files, setFiles] = useState([]);
    const [clickedFile, setClickedFile] = useState("");
    const { addNotification } = useNotification();

    useEffect(() => {
        fetch(getLink() + '/cdn/my-files', {
            headers: {
                'x-access-token': localStorage.getItem('db_token'),
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log('My files:', data);
                setFiles(data);
            })
            .catch(error => {
                console.error('Failed to fetch files:', error);
            });
    }, []);

    const handleDelete = (id) => {
        // setFiles(files.filter(file => file.id !== id));

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('db_token'),
            },
        };

        const fetcher = async () => {
            try {
                const response = await fetch(getLink() + `/cdn/${id}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`Failed to delete file: ${response.statusText}`);
                }

                setFiles(files.filter(file => file._id !== id));

                // Notification de succès
                addNotification({
                    title: "— Fichier supprimé !",
                    description: `Le fichier a été supprimé avec succès.`,
                    image: "https://static-00.iconduck.com/assets.00/success-icon-512x512-y0ltx23a.png",
                    duration: 5000,
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetcher()
    };

    const handleAccessChange = (id, newAccess) => {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('db_token'),
            },
            body: JSON.stringify({ isPublic: newAccess === 'public' }),
        };

        setClickedFile(files.find(file => file._id === id));

        const fetcher = async () => {
            try {
                const response = await fetch(getLink() + `/cdn/${id}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`Failed to update file access: ${response.statusText}`);
                }

                const updatedFile = await response.json();

                if (!updatedFile) {
                    throw new Error('Failed to update file access');
                }

                refreshFiles();

                // Notification de succès
                addNotification({
                    title: "— Accès modifié !",
                    description: `L'accès du fichier a été modifié avec succès.`,
                    image: "https://static-00.iconduck.com/assets.00/success-icon-512x512-y0ltx23a.png",
                    duration: 5000,
                });
            } catch (error) {
                console.error(error);
            }
        }

        fetcher()
    };

    return (
        <div className="file-list">

            <h2>
                <Folder size={48} />
                Vos Fichiers</h2>
            <button className="back-button" onClick={unShow}>
                <ChevronLeft size={24} />
            </button>
            <table>
                <thead>
                    <tr>
                        <th>
                            <Text size={16} />
                            Nom
                        </th>
                        <th>
                            <Weight size={16} />
                            Taille (MB)
                        </th>
                        <th>
                            <Eye size={16} />
                            Vues
                        </th>
                        <th>
                            <Download size={16} />
                            Téléchargements
                        </th>
                        <th>
                            <Lock size={16} />
                            Accès
                        </th>
                        <th>
                            <Pointer size={16} />
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file._id}>
                            <td><p>{file.title}</p></td>
                            <td>{file.size}</td>
                            <td>{file.views}</td>
                            <td>{file.downloadCount}</td>
                            <td>
                                <select
                                    value={file.isPublic ? 'public' : 'private'}
                                    onChange={(e) => handleAccessChange(file._id, e.target.value)}
                                >
                                    {['public', 'private'].map(option => (
                                        <option key={option} value={option}>
                                            {option === 'public' ? 'Public' : 'Privé'}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <button onClick={() => handleDelete(file._id)}>
                                    <X size={16} />
                                </button>
                                {/*  download button that open a new tab with the file url */}
                                <button onClick={() => window.open(`https://ds-holding.fr/download/${file._id}`)}>
                                    <List size={16} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const FileUploader = ({ onClose }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');
    const [access, setAccess] = useState('public');
    const [downloadLink, setDownloadLink] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const fileInputRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showFileList, setShowFileList] = useState(false);

    const [refreshFiles, setRefreshFiles] = useState(false);
    const [files, setFiles] = useState([]);


    const updateFiles = () => {
        fetch(getLink() + '/cdn/my-files', {
            headers: {
                'x-access-token': localStorage.getItem('db_token'),
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log('My files:', data);
                setFiles(data);
            })
            .catch(error => {
                console.error('Failed to fetch files:', error);
            });
    };




    const { addNotification } = useNotification();

    const slides = [
        {
            title: "Partage Instantané",
            description: "Transférez vos fichiers en un clin d'œil",
            icon: <Send size={48} />
        },
        {
            title: "Sécurité Maximale",
            description: "Vos données sont cryptées de bout en bout",
            icon: <Lock size={48} />
        },
        {
            title: "Collaboration Facile",
            description: "Partagez avec votre équipe en un instant",
            icon: <Link size={48} />
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile.name);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        setFileName(droppedFile.name);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };


    const handleUpload = async () => {
        if (!file) return;
        setIsUploading(true);
        setUploadProgress(0);

        // Création de la FormData pour l'envoi du fichier
        const formData = new FormData();
        formData.append('file', file);
        formData.append('isPublic', access === 'public');

        try {
            const response = await fetch(getLink() + '/cdn/upload', {
                method: 'POST',
                headers: {
                    'x-access-token': localStorage.getItem('db_token'),
                },
                body: formData, // Envoi des données du fichier
            });

            if (!response.ok) {
                throw new Error(`Failed to upload file: ${response.statusText}`);
            }

            const data = await response.json();
            console.log('File uploaded successfully:', data);

            // Simulation de la progression du téléversement
            for (let i = 0; i <= 100; i += 10) {
                setUploadProgress(i);
                await new Promise(resolve => setTimeout(resolve, 200));
            }

            // Générer un lien de téléchargement basé sur la réponse serveur
            setDownloadLink(`https://ds-holding.fr/download/${data._id}`); // Par exemple, data.url si le serveur renvoie l'URL du fichier

            // Notification de succès
            addNotification({
                title: "— Upload réussi !",
                description: `Votre fichier "${fileName}" a été téléversé avec succès.`,
                image: "https://static-00.iconduck.com/assets.00/success-icon-512x512-y0ltx23a.png",
                duration: 5000,
                actions: [
                    {
                        label: "Copier le lien",
                        onClick: () => navigator.clipboard.writeText(data.url),
                    },
                    {
                        label: "Partager",
                        onClick: () => console.log("Partage lancé"),
                    }
                ]
            });
        } catch (error) {
            console.error(error);
            addNotification({
                title: "Erreur",
                description: "Une erreur est survenue lors du téléversement du fichier.",
                duration: 5000,
            });
        } finally {
            setIsUploading(false);
        }
    };



    return (
        <div className="modern-file-uploader">
            <button className="close-button" onClick={onClose}>
                <X size={24} />
            </button>
            {showFileList ? (
                <FileList unShow={() => setShowFileList(!showFileList)} refreshFiles={updateFiles} files={files} setFiles={setFiles} />
            ) : (
                <div className="uploader-content">
                    <div className="uploader-left">
                        <h2>Partagez vos fichiers</h2>
                        <div
                            className={`drop-zone ${file ? 'has-file' : ''}`}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onClick={() => fileInputRef.current.click()}
                        >
                            {file ? (
                                <div className="file-info">
                                    <FileText size={48} />
                                    <p>{file.name}</p>
                                    <p>{(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                                </div>
                            ) : (
                                <>
                                    <Upload size={48} />
                                    <p>Glissez votre fichier ici ou cliquez pour sélectionner</p>
                                </>
                            )}
                            <input
                                type="file"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                        </div>
                        {!file && (
                            <div className="or-divider">
                                <button className="view-files-button" onClick={() => setShowFileList(true)}>
                                    <Folder size={24} />
                                    Vos fichiers
                                </button>
                                <button className="view-files-button" onClick={() => setShowFileList(true)}>
                                    <Heart size={24} />
                                    Devenir Premium
                                </button>
                            </div>
                        )}
                        {file && (
                            <div className="file-details">
                                <input
                                    type="text"
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                    placeholder="Nom du fichier"
                                />
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description (optionnelle)"
                                />
                                <div className="access-options">
                                    <label className={access === 'public' ? 'active' : ''}>
                                        <input
                                            type="radio"
                                            value="public"
                                            checked={access === 'public'}
                                            onChange={() => setAccess('public')}
                                        />
                                        Public
                                    </label>
                                    <label className={access === 'private' ? 'active' : ''}>
                                        <input
                                            type="radio"
                                            value="private"
                                            checked={access === 'private'}
                                            onChange={() => setAccess('private')}
                                        />
                                        Privé
                                    </label>
                                </div>
                            </div>
                        )}
                        {downloadLink ? (
                            <div className="download-link">
                                <input type="text" value={downloadLink} readOnly />
                                <button onClick={() => navigator.clipboard.writeText(downloadLink)}>
                                    Copier
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={handleUpload}
                                className="upload-button"
                                disabled={!file || isUploading}
                                style={{ display: !file || isUploading ? 'none' : 'block' }}
                            >
                                {isUploading ? 'Téléversement...' : 'Téléverser'}
                                <ChevronRight size={16} />
                            </button>
                        )}
                        {isUploading && (
                            <div className="upload-progress">
                                <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                            </div>
                        )}
                    </div>
                    <div className="uploader-right">
                        <div className="slider">
                            {slides.map((slide, index) => (
                                <div
                                    key={index}
                                    className={`slide ${index === currentSlide ? 'active' : ''}`}
                                >
                                    {slide.icon}
                                    <h3>{slide.title}</h3>
                                    <p>{slide.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileUploader;