import React, { useState, useEffect } from 'react';
import { FiClock, FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import logo from '../img/Banner_transparent.png';
import '../styles/Maintenance.scss';

const Maintenance = () => {
  const [status, setStatus] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {

    // change tab title
    document.title = "Ds-Holding ⇢ Maintenance";

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        updateStatus(newProgress);
        // scroll to the bottom of the status container
        const statusContainer = document.querySelector('.status-container');
        // scroll de 400px
        statusContainer.scrollTop = statusContainer.scrollHeight - statusContainer.clientHeight;


        // clear interval when progress reaches 100%
        if (newProgress >= 100) clearInterval(interval);
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const updateStatus = (progress) => {
    const newStatus = {
      date: new Date().toISOString(),
      title: getStatusTitle(progress),
      description: getStatusDescription(progress),
      icon: getStatusIcon(progress),
    };
    setStatus((prevStatus) => [...prevStatus, newStatus]);
  };

  const getStatusTitle = (progress) => {
    switch (progress) {
      case 10: return "Démarrage de la maintenance";
      case 30: return "Mise à jour du serveur";
      case 50: return "Mise à jour des bases de données";
      case 70: return "Mise à jour des fichiers";
      case 90: return "Finalisation";
      case 100: return "Maintenance terminée";
      default: return "En cours...";
    }
  };

  const getStatusDescription = (progress) => {
    switch (progress) {
      case 10: return "Initialisation des processus de maintenance.";
      case 30: return "Mise à niveau des composants serveur.";
      case 50: return "Optimisation et mise à jour des bases de données.";
      case 70: return "Synchronisation et mise à jour des fichiers système.";
      case 90: return "Vérifications finales et nettoyage.";
      case 100: return "Toutes les opérations de maintenance sont terminées.";
      default: return "Opérations de maintenance en cours.";
    }
  };

  const getStatusIcon = (progress) => {
    if (progress === 100) return <FiCheckCircle className="status-icon complete" />;
    if (progress < 50) return <FiClock className="status-icon in-progress" />;
    return <FiAlertCircle className="status-icon alert" />;
  };

  return (
    <div className="maintenance-page">
      <div className="maintenance-content">
        <img src={logo} alt="Logo" className="logo" />
        <div className="status-container">
          {status.length === 0 && <p>Aucun statut disponible.</p>}
          {status.map((item, index) => (
            <div key={index} className="status-item">
              {item.icon}
              <div className="status-info">
                <div className="status-header">
                  <span className="status-title">{item.title}</span>
                  <span className="status-date">{new Date(item.date).toLocaleTimeString()}</span>
                </div>
                <div className="status-description">{item.description}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="progress-text">{progress}% complété</p>

        <p className="maintenance-message">
          Nous travaillons à l'amélioration de notre site. Merci de votre patience.
        </p>

        <p className="maintenance-footer">
          www.ds-holding.fr
        </p>
      </div>
    </div>
  );
};

export default Maintenance;