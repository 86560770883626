import React, { useState } from 'react';
import logo from '../img/Shield.png';
import { X } from 'lucide-react';
import '../styles/CyberSecurity.scss';

const CyberSecurity = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState('search');

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'notes':
                return (
                    <div className="tab-content">
                        <h2 className="section-title">Pentest Notes</h2>
                        <textarea className="notes-area" placeholder="Add your pentest notes here..." />
                    </div>
                );
            case 'mindmap':
                return (
                    <div className="tab-content">
                        <h2 className="section-title">Pentest Mindmap</h2>
                        <div className="mindmap-area">
                            {/* Placeholder for Mindmap */}
                            <p>Mindmap area (integrate a mindmap library here)</p>
                        </div>
                    </div>
                );
            case 'search':
                return (
                    <div className="tab-content">
                        <h2 className="section-title">CyberSecurity Scanning</h2>
                        {/* Existing scanning form logic */}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="cybersecurity-scanner">
            <div className="cybersecurity-header">
                <h2>
                    <img src={logo} alt="Contact" className='mini-logo' />
                    Contactez-moi !</h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>
            <div className="tabs">
                <button className={activeTab === 'notes' ? 'active' : ''} onClick={() => setActiveTab('notes')}>
                    Notes
                </button>
                <button className={activeTab === 'mindmap' ? 'active' : ''} onClick={() => setActiveTab('mindmap')}>
                    Mindmap
                </button>
                <button className={activeTab === 'search' ? 'active' : ''} onClick={() => setActiveTab('search')}>
                    Scanning
                </button>
            </div>

            <div className="tab-container">{renderActiveTab()}</div>
        </div>
    );
};

export default CyberSecurity;
