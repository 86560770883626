import React, { useState, useEffect, useCallback } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, AtomicBlockUtils, Modifier, SelectionState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import logo from '../img/Keyboard.png';
import 'draft-js/dist/Draft.css';
import { X, Bold, Italic, Underline, List, Link, Image, AlignLeft, AlignCenter, AlignRight, Heading1, Heading2, Undo, Redo, Code } from 'lucide-react';
import '../styles/TextEditor.scss';

const TextEditor = ({ onClose, initialContent, onSave }) => {
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            return EditorState.createWithContent(convertFromRaw(JSON.parse(initialContent)));
        }
        return EditorState.createEmpty();
    });

    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    useEffect(() => {
        const saveContent = () => {
            const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            localStorage.setItem('draftContent', content);
        };

        window.addEventListener('beforeunload', saveContent);
        return () => {
            window.removeEventListener('beforeunload', saveContent);
        };
    }, [editorState]);

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const addLink = () => {
        const selection = editorState.getSelection();
        const link = window.prompt('Enter a URL:');
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
            return;
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        setEditorState(RichUtils.toggleLink(newEditorState, selection, contentWithEntity.getLastCreatedEntityKey()));
    };

    const addImage = () => {
        const url = window.prompt('Enter the image URL:');
        if (!url) return;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    };

    const handleSave = () => {
        const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        const html = stateToHTML(editorState.getCurrentContent());
        onSave({ content, html });
        onClose();
    };

    const alignText = (alignment) => {
        const newContentState = Modifier.setBlockData(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            { 'text-align': alignment }
        );
        setEditorState(EditorState.push(editorState, newContentState, 'change-block-data'));
    };

    const handleUndo = () => {
        if (undoStack.length > 0) {
            const previousState = undoStack[undoStack.length - 1];
            setRedoStack([...redoStack, editorState]);
            setUndoStack(undoStack.slice(0, -1));
            setEditorState(previousState);
        }
    };

    const handleRedo = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack[redoStack.length - 1];
            setUndoStack([...undoStack, editorState]);
            setRedoStack(redoStack.slice(0, -1));
            setEditorState(nextState);
        }
    };

    const onEditorChange = (newEditorState) => {
        setUndoStack([...undoStack, editorState]);
        setRedoStack([]);
        setEditorState(newEditorState);
    };

    const insertCodeBlock = () => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const blockType = contentState.getBlockForKey(selectionState.getStartKey()).getType();

        if (blockType === 'code-block') {
            setEditorState(RichUtils.toggleBlockType(editorState, 'unstyled'));
        } else {
            setEditorState(RichUtils.toggleBlockType(editorState, 'code-block'));
        }
    };

    return (
        <div className="advanced-text-editor">
            <div className="editor-header">
                <h2>
                    <img src={logo} alt="Text Editor" className='mini-logo' />
                    Editeur de texte</h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>
            <div className="toolbar">
                <button onClick={() => toggleInlineStyle('BOLD')}><Bold size={20} color='white' /></button>
                <button onClick={() => toggleInlineStyle('ITALIC')}><Italic size={20} color='white' /></button>
                <button onClick={() => toggleInlineStyle('UNDERLINE')}><Underline size={20} color='white' /></button>
                <button onClick={() => toggleBlockType('unordered-list-item')}><List size={20} color='white' /></button>
                <button onClick={() => toggleBlockType('header-one')}><Heading1 size={20} color='white' /></button>
                <button onClick={() => toggleBlockType('header-two')}><Heading2 size={20} color='white' /></button>
            </div>
            <div className="editor-container">
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand}
                />
            </div>
            <div className="editor-footer">
                <button className="save-button" onClick={handleSave}>Enregistrer</button>
            </div>
        </div>
    );
};

export default TextEditor;