// PasswordForm.js
import React, { useState } from 'react';
import { Folder, Key, KeyboardMusic, Lock, MailIcon, PlusCircle, Scale, Scale3d, TicketsPlane, X } from 'lucide-react';
import './PasswordForm.scss';

const PasswordForm = ({ password, onSave, onClose }) => {
    const [formData, setFormData] = useState(password || {
        name: '',
        username: '',
        password: '',
        website: '',
        folder: '',
    });

    const [configurations, setConfigurations] = useState({
        length: 12,
        uppercase: true,
        lowercase: true,
        numbers: true,
        symbols: true,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    const handleGeneratePassword = (configurations) => {
        const characters = {
            uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            lowercase: 'abcdefghijklmnopqrstuvwxyz',
            numbers: '0123456789',
            symbols: '!@#$%^&*()_+~`|}{[]\:;?><,./-='
        };

        let password = '';
        let allCharacters = '';
        for (let key in configurations) {
            if (configurations[key]) {
                allCharacters += characters[key];
            }
        }

        for (let i = 0; i < configurations.length; i++) {
            const randomIndex = Math.floor(Math.random() * allCharacters.length);
            password += allCharacters[randomIndex];
        }

        setFormData(prev => ({ ...prev, password }));
    };

    const handleConfigChange = (e) => {
        const { name, checked } = e.target;
        setConfigurations(prev => ({ ...prev, [name]: checked }));
    };

    const handleLengthChange = (e) => {
        const value = e.target.value;
        setConfigurations(prev => ({ ...prev, length: value }));
    }

    const handleCopyPassword = () => {
        navigator.clipboard.writeText(formData.password);
    }

    const handleClearPassword = () => {
        setFormData(prev => ({ ...prev, password: '' }));
    }


    return (
        <div className="password-form-modal">
            <div className="modal-content">
                <button className="close-btn" onClick={onClose}><X size={24} /></button>
                <h2>{password ? 'Modifier un mot de passe' : 'Créer un mot de passe'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name"><TicketsPlane size={24} /> Titre</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username"><MailIcon size={18} />Identifiant</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password"><Lock size={18} />Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <div className="password-actions">
                            <button type="button" onClick={() => handleGeneratePassword(configurations)}>Générer</button>
                            <button type="button" onClick={handleCopyPassword}>Copier</button>
                            <button type="button" onClick={handleClearPassword}>Effacer</button>
                        </div>
                        <div className="password-config">
                            <div className="config-group">
                                <label htmlFor="length"><Scale3d size={18} />Longueur</label>
                                <input
                                    type="number"
                                    id="length"
                                    name="length"
                                    value={configurations.length}
                                    onChange={handleLengthChange}
                                />
                            </div>
                            <div className="config-group">
                                <label><MailIcon size={18} /> Caractères</label>
                                <div className="config-options">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="uppercase"
                                            checked={configurations.uppercase}
                                            onChange={handleConfigChange}
                                        />
                                        Majuscules
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="lowercase"
                                            checked={configurations.lowercase}
                                            onChange={handleConfigChange}
                                        />
                                        Minuscules
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="numbers"
                                            checked={configurations.numbers}
                                            onChange={handleConfigChange}
                                        />
                                        Chiffres
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="symbols"
                                            checked={configurations.symbols}
                                            onChange={handleConfigChange}
                                        />
                                        Symboles
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="website"><KeyboardMusic size={18} /> Site lié (optionel)</label>
                        <input
                            type="url"
                            id="website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="folder"><Folder size={18} />Dossier (optionel)</label>
                        <input
                            type="text"
                            id="folder"
                            name="folder"
                            value={formData.folder}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="save-btn"><PlusCircle size={18} />Ajouter</button>
                </form>
            </div>
        </div>
    );
};

export default PasswordForm;