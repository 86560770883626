import React from 'react';
import { useNotification } from '../../contexts/NotificationContext';

const Notification = ({ notification }) => {
    const { removeNotification } = useNotification();

    return (
        <div className="notification">
            {notification.image && (
                <div className="notification-image">
                    <img src={notification.image} alt="Notification" />
                </div>
            )}
            <div className="notification-content">
                <h3>{notification.title}</h3>
                <p>{notification.description}</p>
                {notification.actions && (
                    <div className="notification-actions">
                        {notification.actions.map((action, index) => (
                            <button key={index} onClick={() => {
                                action.onClick();
                                removeNotification(notification.id);
                            }}>
                                {action.label}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <button className="notification-close" onClick={() => removeNotification(notification.id)}>
                &times;
            </button>
        </div>
    );
};


export default Notification;