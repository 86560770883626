import React, { useState } from 'react';
import '../styles/Window.scss';
import home from '../img/house.png';
import lib from '../img/Library.png';
import desk from '../img/Desktop.png';
import folder_download from '../img/Folder_Downloads.png';
import folder_docs from '../img/Folder_Documents.png';

const Window = ({ id, title, content, onClose }) => {
    const [selectedContent, setSelectedContent] = useState(content);
    const [titleContent, setTitleContent] = useState(title);
    const [detailedContent, setDetailedContent] = useState(null);

    const handleLeftBarClick = (contentKey, newTitle) => {
        setSelectedContent(contentKey);
        setTitleContent(newTitle);
        setDetailedContent(null);
    };

    const handleIconClick = (contentKey, newTitle) => {
        setDetailedContent({ contentKey, newTitle });
        setTitleContent(newTitle);
    };

    const renderMainContentIcons = () => {
        switch (selectedContent) {
            case '1':
                return (
                    <>
                        <div className='window-project' onClick={() => handleIconClick('1a', 'Listen me (Spotify replica)')}>
                            <img src={home} alt="home" className="icon" />
                            <span>Listen me<br />(Spotify replica)</span>
                        </div>
                        <div className='window-project' onClick={() => handleIconClick('1b', 'Fluidify')}>
                            <img src={lib} alt="lib" className="icon" />
                            <span>Fluidify</span>
                        </div>
                        <div className='window-project' onClick={() => handleIconClick('2a', 'Sass')}>
                            <img src={desk} alt="desk" className="icon" />
                            <span>Sass</span>
                        </div>
                        <div className='window-project' onClick={() => handleIconClick('2b', 'AutoVPN')}>
                            <img src={folder_download} alt="downloads" className="icon" />
                            <span>AutoVPN</span>
                        </div>
                        <div className='window-project' onClick={() => handleIconClick('3a', 'Protocol de sécurité')}>
                            <img src={folder_docs} alt="documents" className="icon" />
                            <span>Protocol de sécurité</span>
                        </div>
                    </>
                );
            case '2':
                return (
                    <>
                        <div className='window-project' onClick={() => handleIconClick('2a', 'Sass')}>
                            <img src={desk} alt="desk" className="icon" />
                            <span>Sass</span>
                        </div>
                        <div className='window-project' onClick={() => handleIconClick('2b', 'AutoVPN')}>
                            <img src={folder_download} alt="downloads" className="icon" />
                            <span>AutoVPN</span>
                        </div>
                    </>
                );
            case '3':
                return (
                    <>
                        <div className='window-project' onClick={() => handleIconClick('3a', 'Protocol de sécurité')}>
                            <img src={folder_docs} alt="documents" className="icon" />
                            <span>Protocol de sécurité</span>
                        </div>
                    </>
                );
            default:
                return <div>Select an item from the left bar.</div>;
        }
    };

    const renderDetailedContent = () => {
        if (!detailedContent) return null;

        switch (detailedContent.contentKey) {
            case '1a':
                return (
                    <div className="project-details">
                        <h1>ListenMe (Réplica de Spotify)</h1>
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/59868131-b540-404b-b4ac-b9643902b774/df53yy2-16f7268c-a5f0-4aee-a33a-d45f8eab7fbc.png/v1/fill/w_1280,h_720,q_80,strp/windows_12_concept_light_wallpaper_by_sarthakchaubey_df53yy2-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvNTk4NjgxMzEtYjU0MC00MDRiLWI0YWMtYjk2NDM5MDJiNzc0XC9kZjUzeXkyLTE2ZjcyNjhjLWE1ZjAtNGFlZS1hMzNhLWQ0NWY4ZWFiN2ZiYy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PL1ap2WTb3D9C46XscOP8Lre2AEclZawq_FNbjlPvAs" alt="ListenMe" className="project-image" />
                        <p>
                            <strong>ListenMe</strong> est une plateforme de streaming musical complète conçue pour reproduire les fonctionnalités populaires de Spotify. Elle permet aux utilisateurs de :
                        </p>
                        <ul>
                            <li><strong>Télécharger</strong> de la musique pour une écoute hors ligne, garantissant l'accès aux morceaux préférés à tout moment et n'importe où.</li>
                            <li><strong>Écouter en streaming</strong> une vaste bibliothèque de chansons de différents genres et artistes.</li>
                            <li><strong>Partager</strong> de la musique avec des amis et la famille, favorisant ainsi l'interaction sociale par la musique.</li>
                            <li>Créer et gérer des <strong>playlists personnalisées</strong> pour différentes humeurs et occasions.</li>
                            <li>Profiter de sessions d'écoute <strong>collaboratives</strong> avec des amis, où plusieurs utilisateurs peuvent écouter la même playlist ou chanson en temps réel.</li>
                            <li>Et bien plus encore...</li>
                        </ul>
                        <p>
                            ListenMe vise à offrir une expérience musicale immersive et interactive, avec un streaming audio de haute qualité, une intégration transparente aux réseaux sociaux, et des recommandations personnalisées basées sur les préférences des utilisateurs.
                        </p>
                        <div className="stack-used">
                            <h4>Front-End :</h4>
                            <ul>
                                <li>JavaScript</li>
                                <li>React</li>
                                <li>Node.js</li>
                            </ul>
                            <h4>Back-End :</h4>
                            <ul>
                                <li>Express</li>
                                <li>MySQL</li>
                                <li>API RESTful</li>
                            </ul>


                            <h2>Compétences requises :</h2>
                            <ul>
                                <li>Développement Front-End</li>
                                <li>Gestion des API</li>
                                <li>Conception UI/UX</li>
                            </ul>
                        </div>
                    </div>
                );
            case '1b':
                return (
                    <div className="project-details">
                        <h1>Fluidify</h1>
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/59868131-b540-404b-b4ac-b9643902b774/df53yy2-16f7268c-a5f0-4aee-a33a-d45f8eab7fbc.png/v1/fill/w_1280,h_720,q_80,strp/windows_12_concept_light_wallpaper_by_sarthakchaubey_df53yy2-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvNTk4NjgxMzEtYjU0MC00MDRiLWI0YWMtYjk2NDM5MDJiNzc0XC9kZjUzeXkyLTE2ZjcyNjhjLWE1ZjAtNGFlZS1hMzNhLWQ0NWY4ZWFiN2ZiYy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PL1ap2WTb3D9C46XscOP8Lre2AEclZawq_FNbjlPvAs" alt="Fluidify" className="project-image" />
                        <p>
                            <strong>Fluidify</strong> est un service de marketing par e-mail puissant conçu pour aider les utilisateurs à gérer efficacement et envoyer des campagnes d'e-mails à leurs prospects. La plateforme offre une gamme de fonctionnalités, notamment :
                        </p>
                        <ul>
                            <li>Création de <strong>modèles d'e-mails personnalisés</strong> pour adapter les messages à différents publics et objectifs de campagne.</li>
                            <li>Gestion des <strong>groupes d'e-mails</strong> pour des campagnes ciblées, garantissant que le bon message atteigne le bon public.</li>
                            <li>Intégration avec des <strong>serveurs SMTP personnalisés</strong>, permettant des domaines d'expéditeur personnalisés et des taux de livraison améliorés.</li>
                            <li>Analyses et rapports détaillés sur les performances des campagnes, y compris les taux d'ouverture, les taux de clics et le suivi des conversions.</li>
                            <li>Et bien plus encore, tout conçu pour maximiser l'efficacité des efforts de marketing par e-mail.</li>
                        </ul>
                        <p>
                            Fluidify est idéal pour les entreprises et les particuliers cherchant à simplifier leurs processus de marketing par e-mail, à améliorer l'engagement avec leur audience, et à obtenir de meilleurs résultats marketing.
                        </p>
                        <div className="stack-used">
                            <h4>Front-End :</h4>
                            <ul>
                                <li>JavaScript</li>
                                <li>React</li>
                                <li>Node.js</li>
                            </ul>
                            <h4>Back-End :</h4>
                            <ul>
                                <li>Express</li>
                                <li>MySQL</li>
                                <li>API RESTful</li>
                            </ul>


                            <h2>Compétences requises :</h2>
                            <ul>
                                <li>Développement Front-End</li>
                                <li>Gestion des API</li>
                                <li>Conception UI/UX</li>
                            </ul>
                        </div>
                    </div>
                );
            case '2a':
                return (
                    <div className="project-details">
                        <h1>Sass (Outil de Gestion Automobile)</h1>
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/59868131-b540-404b-b4ac-b9643902b774/df53yy2-16f7268c-a5f0-4aee-a33a-d45f8eab7fbc.png/v1/fill/w_1280,h_720,q_80,strp/windows_12_concept_light_wallpaper_by_sarthakchaubey_df53yy2-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvNTk4NjgxMzEtYjU0MC00MDRiLWI0YWMtYjk2NDM5MDJiNzc0XC9kZjUzeXkyLTE2ZjcyNjhjLWE1ZjAtNGFlZS1hMzNhLWQ0NWY4ZWFiN2ZiYy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PL1ap2WTb3D9C46XscOP8Lre2AEclZawq_FNbjlPvAs" alt="Sass" className="project-image" />
                        <p>
                            <strong>Sass</strong> est un outil complet conçu pour la gestion des entreprises automobiles et des stocks. Il offre des fonctionnalités qui permettent aux utilisateurs de :
                        </p>
                        <ul>
                            <li><strong>Gérer les ventes de véhicules</strong> de manière efficace, en suivant chaque étape du processus de vente, de la génération de leads à la conclusion de la vente.</li>
                            <li>Maintenir un <strong>inventaire organisé des véhicules</strong>, incluant des détails tels que la marque, le modèle, l'année et le prix.</li>
                            <li>Générer des <strong>rapports de vente détaillés</strong> et des analyses pour surveiller les performances et identifier les tendances.</li>
                            <li>S'intégrer à d'autres systèmes pour la <strong>gestion financière</strong>, y compris la facturation et le traitement des paiements.</li>
                            <li>Et bien plus encore, aidant les entreprises automobiles à optimiser leurs opérations et à améliorer leur rentabilité.</li>
                        </ul>
                        <p>
                            Sass est un outil essentiel pour les concessionnaires et les entreprises automobiles cherchant à optimiser la gestion de leur inventaire et de leurs processus de vente.
                        </p>
                        <div className="stack-used">
                            <h4>Front-End :</h4>
                            <ul>
                                <li>JavaScript</li>
                                <li>React</li>
                                <li>Node.js</li>
                            </ul>
                            <h4>Back-End :</h4>
                            <ul>
                                <li>Express</li>
                                <li>MySQL</li>
                                <li>API RESTful</li>
                            </ul>


                            <h2>Compétences requises :</h2>
                            <ul>
                                <li>Développement Front-End</li>
                                <li>Gestion des API</li>
                                <li>Conception UI/UX</li>
                            </ul>
                        </div>
                    </div>
                );
            case '2b':
                return (
                    <div className="project-details">
                        <h1>AutoVPN</h1>
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/59868131-b540-404b-b4ac-b9643902b774/df53yy2-16f7268c-a5f0-4aee-a33a-d45f8eab7fbc.png/v1/fill/w_1280,h_720,q_80,strp/windows_12_concept_light_wallpaper_by_sarthakchaubey_df53yy2-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvNTk4NjgxMzEtYjU0MC00MDRiLWI0YWMtYjk2NDM5MDJiNzc0XC9kZjUzeXkyLTE2ZjcyNjhjLWE1ZjAtNGFlZS1hMzNhLWQ0NWY4ZWFiN2ZiYy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PL1ap2WTb3D9C46XscOP8Lre2AEclZawq_FNbjlPvAs" alt="AutoVPN" className="project-image" />
                        <p>
                            <strong>AutoVPN</strong> est un service VPN spécialement conçu pour protéger les utilisateurs contre les fuites de données et le vol d'informations. Il offre :
                        </p>
                        <ul>
                            <li>Des <strong>protocoles de chiffrement robustes</strong> pour sécuriser le trafic internet et garantir la confidentialité des utilisateurs.</li>
                            <li>Une protection contre les <strong>fuites de données</strong> en masquant l'adresse IP de l'utilisateur et en chiffrant toutes les données transmises sur internet.</li>
                            <li>Des options de <strong>connexion automatique</strong> au VPN au démarrage, assurant une protection continue.</li>
                            <li>La compatibilité avec plusieurs appareils et plateformes, permettant aux utilisateurs de sécuriser leurs connexions sur n'importe quel appareil.</li>
                            <li>Et des fonctionnalités de sécurité supplémentaires comme un <strong>kill switch</strong> pour prévenir les fuites de données si la connexion VPN tombe.</li>
                        </ul>
                        <p>
                            AutoVPN est parfait pour tous ceux qui cherchent à maintenir leur vie privée et leur sécurité en ligne, que ce soit pour un usage personnel ou dans un environnement professionnel.
                        </p>
                        <div className="stack-used">
                            <h4>Front-End :</h4>
                            <ul>
                                <li>JavaScript</li>
                                <li>React</li>
                                <li>Node.js</li>
                            </ul>
                            <h4>Back-End :</h4>
                            <ul>
                                <li>Express</li>
                                <li>MySQL</li>
                                <li>API RESTful</li>
                            </ul>


                            <h2>Compétences requises :</h2>
                            <ul>
                                <li>Développement Front-End</li>
                                <li>Gestion des API</li>
                                <li>Conception UI/UX</li>
                            </ul>
                        </div>
                    </div>
                );
            case '3a':
                return (
                    <div className="project-details">
                        <h1>Protocole de Sécurité</h1>
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/59868131-b540-404b-b4ac-b9643902b774/df53yy2-16f7268c-a5f0-4aee-a33a-d45f8eab7fbc.png/v1/fill/w_1280,h_720,q_80,strp/windows_12_concept_light_wallpaper_by_sarthakchaubey_df53yy2-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvNTk4NjgxMzEtYjU0MC00MDRiLWI0YWMtYjk2NDM5MDJiNzc0XC9kZjUzeXkyLTE2ZjcyNjhjLWE1ZjAtNGFlZS1hMzNhLWQ0NWY4ZWFiN2ZiYy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PL1ap2WTb3D9C46XscOP8Lre2AEclZawq_FNbjlPvAs" alt="Protocole de Sécurité" className="project-image" />
                        <p>
                            Le <strong>Protocole de Sécurité</strong> a été développé pour le projet AutoVPN afin d'ajouter une couche supplémentaire de sécurité, garantissant l'envoi et la réception de données de manière sécurisée. Ce protocole :
                        </p>
                        <ul>
                            <li>Mise en œuvre de <strong>normes de chiffrement avancées</strong> pour sécuriser les données pendant leur transmission, rendant presque impossible l'interception et la lecture des données par des tiers non autorisés.</li>
                            <li>Fournit un <strong>tunnel sécurisé</strong> pour l'échange de données, garantissant que toutes les données envoyées et reçues sont protégées contre les menaces externes.</li>
                            <li>Offre des méthodes <strong>d'authentification de haut niveau</strong> pour vérifier l'identité des utilisateurs et prévenir les accès non autorisés.</li>
                            <li>Prend en charge l'<strong>authentification multi-facteurs (MFA)</strong> pour une sécurité supplémentaire, nécessitant plusieurs formes de vérification avant d'accorder l'accès.</li>
                            <li>Et bien plus encore, visant à offrir une sécurité de premier ordre pour les communications et les données sensibles.</li>
                        </ul>
                        <p>
                            Ce protocole est particulièrement bénéfique pour les organisations et les individus qui privilégient la sécurité et la confidentialité des données, garantissant que toutes les communications restent confidentielles et sécurisées.
                        </p>
                        <div className="stack-used">
                            <h4>Front-End :</h4>
                            <ul>
                                <li>JavaScript</li>
                                <li>React</li>
                                <li>Node.js</li>
                            </ul>
                            <h4>Back-End :</h4>
                            <ul>
                                <li>Express</li>
                                <li>MySQL</li>
                                <li>API RESTful</li>
                            </ul>


                            <h2>Compétences requises :</h2>
                            <ul>
                                <li>Développement Front-End</li>
                                <li>Gestion des API</li>
                                <li>Conception UI/UX</li>
                            </ul>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };




    return (
        <div className="window">
            <div className="window-header">
                <div className="window-header-buttons">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleLeftBarClick('1', 'Accueil')} className='directory-button'>
                        <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleLeftBarClick('2', 'Forward')} className='directory-button'>
                        <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <span>{titleContent}</span>
                <button className="close-button" onClick={() => onClose(id)}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18M18 6L6 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="window-content">
                <div className="left-bar">
                    <div className="left-bar-item" onClick={() => handleLeftBarClick('1', 'Accueil')}>
                        <img src={home} alt="home" className="icon" />
                        Accueil
                    </div>
                    <div className="left-bar-item" onClick={() => handleLeftBarClick('2', 'Galerie')}>
                        <img src={lib} alt="library" className="icon" />
                        Galerie
                    </div>
                    <hr />
                    <div className="left-bar-item" onClick={() => handleLeftBarClick('3', 'Bureau')}>
                        <img src={desk} alt="desktop" className="icon" />
                        Bureau
                    </div>
                    <div className="left-bar-item" onClick={() => handleLeftBarClick('4', 'Téléchargements')}>
                        <img src={folder_download} alt="downloads" className="icon" />
                        Téléchargements
                    </div>
                    <div className="left-bar-item" onClick={() => handleLeftBarClick('5', 'Documents')}>
                        <img src={folder_docs} alt="documents" className="icon" />
                        Documents
                    </div>
                </div>
                <div className="main-content">
                    {detailedContent ? renderDetailedContent() : renderMainContentIcons()}
                </div>
            </div>
        </div>
    );
};

export default Window;
