// PasswordManager.js
import React, { useState, useEffect } from 'react';
import logo from '../../img/Shield.png';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { Lock, FileText, Users, X } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import { getLink } from '../../utils/pointer';
import PasswordSection from './PasswordSection';
import NoteSection from './NoteSection';
import ContactSection from './ContactSection';
import OTPVerification from './OTPVerification';
import AccountVerificationRequired from './AccountVerificationRequired';
import './PasswordManager.scss';

const PasswordManager = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showAccountVerification, setShowAccountVerification] = useState(true);
    const [showContent, setShowContent] = useState(false);
    const { user, isStaff, isVerified } = useUser();

    const handleVerify = (token) => {
        setShowOtpModal(false);
        setShowContent(true);
        localStorage.setItem('sessionToken', token);
    }

    const handleAccountVerification = () => {
        setShowAccountVerification(false);
        setShowOtpModal(true);
    }

    useEffect(() => {
        if (isVerified) {
            setShowAccountVerification(false);
            setShowContent(false);
            setShowOtpModal(true);
        }
    }, [isVerified]);

    // useEffect to check every 10 seconds if the user is still connected
    useEffect(() => {
        if (!isVerified) return;
        const interval = setInterval(() => {
            const fetcher = async () => {
                try {
                    const response = await fetch(getLink() + '/private/ultra', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': localStorage.getItem('db_token'),
                            'x-auth-token': localStorage.getItem('sessionToken'),
                        },
                    });
                    const data = await response.json();
                    if (!response.ok) {
                        setShowContent(false);
                        setShowOtpModal(true);
                    }
                } catch (error) {
                    console.error(error);
                    setShowContent(false);
                    setShowOtpModal(true);
                    localStorage.removeItem('sessionToken');
                }
            };

            fetcher();
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className="password-manager">
            <div className="password-manager-header">
                <h2>
                    <img src={logo} alt="Gestionnaire de Projets" className='mini-logo' />
                    Gestionnaire de Projets</h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>

            {showContent && (
                <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
                    <TabList>
                        <Tab><Lock size={20} /> Mots de passes</Tab>
                        <Tab><FileText size={20} /> Notes</Tab>
                        <Tab><Users size={20} /> Contacts</Tab>
                    </TabList>

                    <TabPanel>
                        <PasswordSection />
                    </TabPanel>
                    <TabPanel>
                        <NoteSection />
                    </TabPanel>
                    <TabPanel>
                        <ContactSection />
                    </TabPanel>
                </Tabs>
            )}

            {showOtpModal && <OTPVerification onVerify={handleVerify} />}
            {!isVerified && <AccountVerificationRequired onVerifyAccount={handleAccountVerification} />}
        </div>
    );
};

export default PasswordManager;