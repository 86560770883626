import React, { useState } from 'react';
import logo from '../img/User.png';
import { X, ArrowRight, Send } from 'lucide-react';
import '../styles/Contact.scss';

const questions = [
    { id: 'email', question: "Où puis-je vous recontacter ?", placeholder: "Votre email" },
    { id: 'subject', question: "Quel est le titre de votre message ?", placeholder: "Le titre du message" },
    { id: 'message', question: "Que souhaitez-vous me dire ?", placeholder: "Votre message" }
];

const Contact = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [questions[currentStep].id]: e.target.value });
    };

    const goToNextStep = () => {
        if (currentStep < questions.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('https://discord.com/api/webhooks/1280280273056956500/wweJ771tpQMmMp6GYSIKgIDI5vN1nyOyQFYksrPT0EtSlReflW23H8388ZuZc0Nz-Byr', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: `Nouveau message de contact:\nNom: ${formData.name}\nEmail: ${formData.email}\nSujet: ${formData.subject}\nMessage: ${formData.message}`
                }),
            });

            if (response.ok) {
                alert('Message envoyé avec succès !');
                onClose();
            } else {
                throw new Error('Erreur lors de l\'envoi du message');
            }
        } catch (error) {
            console.error('Erreur:', error);
            alert('Une erreur est survenue lors de l\'envoi du message.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="contact-form-popup">
            <div className="contact-form-header">
                <h2>
                    <img src={logo} alt="Contact" className='mini-logo' />
                    Contactez-moi !</h2>
                <button className="close-button" onClick={onClose}>
                    <X size={24} />
                </button>
            </div>
            <div className="contact-form-content">
                <div className="question-container">
                    <h3>{questions[currentStep].question}</h3>
                    {questions[currentStep].id === 'message' ? (
                        <textarea
                            value={formData[questions[currentStep].id] || ''}
                            onChange={handleInputChange}
                            placeholder={questions[currentStep].placeholder}
                        />
                    ) : (
                        <input
                            type={questions[currentStep].id === 'email' ? 'email' : 'text'}
                            value={formData[questions[currentStep].id] || ''}
                            onChange={handleInputChange}
                            placeholder={questions[currentStep].placeholder}
                        />
                    )}
                </div>
                <div className="navigation-buttons">
                    {currentStep > 0 && (
                        <button onClick={goToPreviousStep} className="nav-button prev">
                            Précédent
                        </button>
                    )}
                    {currentStep < questions.length - 1 ? (
                        <button
                            onClick={goToNextStep}
                            className="nav-button next"
                            disabled={!formData[questions[currentStep].id]}
                        >
                            Suivant <ArrowRight size={16} />
                        </button>
                    ) : (
                        <button
                            onClick={handleSubmit}
                            className="nav-button submit"
                            disabled={isSubmitting || !formData[questions[currentStep].id]}
                        >
                            {isSubmitting ? 'Envoi...' : 'Envoyer'} <Send size={16} />
                        </button>
                    )}
                </div>
            </div>
            <div className="progress-bar">
                <div className="progress" style={{ width: `${((currentStep + 1) / questions.length) * 100}%` }}></div>
            </div>
        </div>
    );
};

export default Contact;