import React, { useState, useEffect } from 'react';
import './ScardManagement.scss';

const ScardManagement = () => {
    const [scards, setScards] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newScard, setNewScard] = useState({ title: '', content: '', status: 'draft' });
    const [isEditing, setIsEditing] = useState(false);
    const [editingScard, setEditingScard] = useState(null);

    useEffect(() => {
        // Simuler le chargement des Scards depuis une API
        const fetchScards = async () => {
            // Remplacer ceci par un vrai appel API
            const mockScards = [
                { id: 1, title: 'Scard 1', content: 'Contenu de la Scard 1', status: 'published' },
                { id: 2, title: 'Scard 2', content: 'Contenu de la Scard 2', status: 'draft' },
            ];
            setScards(mockScards);
        };
        fetchScards();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDeleteScard = (scardId) => {
        setScards(prev => prev.filter(scard => scard.id !== scardId));
    };

    const handleAddScard = () => {
        if (newScard.title && newScard.content) {
            setScards(prev => [...prev, { ...newScard, id: Date.now() }]);
            setNewScard({ title: '', content: '', status: 'draft' });
        }
    };

    const handleEditScard = (scard) => {
        setIsEditing(true);
        setEditingScard(scard);
    };

    const handleUpdateScard = () => {
        setScards(prev => prev.map(scard =>
            scard.id === editingScard.id ? editingScard : scard
        ));
        setIsEditing(false);
        setEditingScard(null);
    };

    const handleToggleScardStatus = (scardId) => {
        setScards(prev => prev.map(scard =>
            scard.id === scardId ? { ...scard, status: scard.status === 'published' ? 'draft' : 'published' } : scard
        ));
    };

    const filteredScards = scards.filter(scard =>
        scard.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        scard.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="scard-management">
            <h2>Gestion des Scards</h2>
            <input
                type="text"
                placeholder="Rechercher des Scards"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="scard-list">
                {filteredScards.map(scard => (
                    <li key={scard.id}>
                        <strong>{scard.title}</strong>
                        <p>{scard.content.substring(0, 50)}...</p>
                        <span className={`status ${scard.status}`}>{scard.status}</span>
                        <button onClick={() => handleToggleScardStatus(scard.id)}>
                            {scard.status === 'published' ? 'Repasser en brouillon' : 'Publier'}
                        </button>
                        <button onClick={() => handleEditScard(scard)}>Modifier</button>
                        <button onClick={() => handleDeleteScard(scard.id)}>Supprimer</button>
                    </li>
                ))}
            </ul>
            <div className="scard-form">
                <h3>{isEditing ? 'Modifier la Scard' : 'Ajouter une nouvelle Scard'}</h3>
                <input
                    type="text"
                    placeholder="Titre de la Scard"
                    value={isEditing ? editingScard.title : newScard.title}
                    onChange={(e) => isEditing
                        ? setEditingScard({ ...editingScard, title: e.target.value })
                        : setNewScard({ ...newScard, title: e.target.value })
                    }
                />
                <textarea
                    placeholder="Contenu de la Scard"
                    value={isEditing ? editingScard.content : newScard.content}
                    onChange={(e) => isEditing
                        ? setEditingScard({ ...editingScard, content: e.target.value })
                        : setNewScard({ ...newScard, content: e.target.value })
                    }
                />
                <select
                    value={isEditing ? editingScard.status : newScard.status}
                    onChange={(e) => isEditing
                        ? setEditingScard({ ...editingScard, status: e.target.value })
                        : setNewScard({ ...newScard, status: e.target.value })
                    }
                >
                    <option value="draft">Brouillon</option>
                    <option value="published">Publié</option>
                </select>
                {isEditing ? (
                    <button onClick={handleUpdateScard}>Mettre à jour la Scard</button>
                ) : (
                    <button onClick={handleAddScard}>Ajouter la Scard</button>
                )}
            </div>
        </div>
    );
};

export default ScardManagement;