import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Download, Heart, Send, Lock, Link, Magnet } from 'lucide-react';
import { getLink } from '../utils/pointer';
import { useParams } from 'react-router-dom';
import '../styles/DownloadPage.scss';
import logo from '../img/Banner_transparent.png'
import Loading from './Loading';
import Error from './Error';

const DownloadPage = () => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [fileInfo, setFileInfo] = useState(null);
    const { fileId } = useParams();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [hasDownloaded, setHasDownloaded] = useState(false);

    const slides = [
        {
            title: "Partage Instantané",
            description: "Transférez vos fichiers en un clin d'œil",
            icon: <Send size={48} color='white' />
        },
        {
            title: "Sécurité Maximale",
            description: "Vos données sont cryptées de bout en bout",
            icon: <Lock size={48} color='white' />
        },
        {
            title: "Collaboration Facile",
            description: "Partagez avec votre équipe en un instant",
            icon: <Link size={48} color='white' />
        }
    ];

    // useEffect pour les slides
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [slides.length]);

    const fetchFileInfo = async () => {
        setIsLoading(true);
        setError('');

        // wait 15 seconds before fetching the file info
        await new Promise((resolve) => setTimeout(resolve, 5000));

        try {
            const response = await axios.get(getLink() + `/cdn/check/${fileId}`);
            setFileInfo(response.data);
        } catch (err) {
            setError(err.response?.data?.message || 'Une erreur est survenue');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFileInfo();
    }, [fileId]);

    const handleDownload = async () => {
        try {
            const response = await axios.get(getLink() + `/cdn/download/${fileId}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileInfo.title);
            document.body.appendChild(link);
            link.click();
            link.remove();

            setHasDownloaded(true);
        } catch (err) {
            setError('Erreur lors du téléchargement du fichier');
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error message={error} onRetry={fetchFileInfo} />;
    }

    return (
        <div className="download-page">
            <img src="https://4kwallpapers.com/images/wallpapers/windows-11-stock-black-abstract-black-background-amoled-2560x1440-8971.jpg" alt="Background" className='background' />
            <div className="download-container">
                <div className="file-downloader">
                    <div className="downloader-content">
                        <div className="downloader-left">
                            {hasDownloaded ? (
                                <div className="header">
                                    <img src={logo} alt="Logo" className="downloader-logo" />
                                    <hr />
                                    <h2>Merci d'avoir utilisé notre service !</h2>

                                    <div className="or-divider">

                                        <button className="view-files-button">
                                            <Magnet size={24} />
                                            Découvrir DsHolding
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="header">
                                        <img src={logo} alt="Logo" className="downloader-logo" />
                                        <hr />
                                        <h2>Votre fichier est <strong>prêt</strong>, téléchargez le !</h2>
                                    </div>
                                    <div className="drop-zone" onClick={handleDownload}>
                                        <Download size={48} color='white' />
                                        <p>Télécharger {fileInfo.title}</p>
                                    </div>
                                    <div className="file-info">
                                        <p>Taille: {(fileInfo.size / 1024 / 1024).toFixed(2)} MB</p>
                                        <p>Type: {fileInfo.type}</p>
                                    </div>
                                    <div className="or-divider">
                                        <button className="view-files-button">
                                            <Magnet size={24} />
                                            Découvrir DsHolding
                                        </button>

                                        <button className="view-files-button">
                                            <Heart size={24} />
                                            Devenir Premium
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="downloader-right">
                            <div className="slider">
                                {slides.map((slide, index) => (
                                    <div
                                        key={index}
                                        className={`slide ${index === currentSlide ? 'active' : ''}`}
                                    >
                                        {slide.icon}
                                        <h3>{slide.title}</h3>
                                        <p>{slide.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;