import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route
import Desktop from './components/Desktop';
import StartMenu from './components/StartMenu';
import Taskbar from './components/Taskbar';
import Setup from './components/Setup';
import Download from './components/DownloadPage'; // Import the Download component
import { useUser } from './contexts/UserContext';
import './styles/App.scss';
import NotificationCenter from './components/NotificationCenter';
import Maintenance from './components/Maintenance';
// import QuestGame from './components/QuestGame';
// import Game from './game/Game';

const App = () => {
  const { isAuth } = useUser();
  const [isStartMenuVisible, setStartMenuVisible] = useState(false);
  const [isCreditsVisible, setCreditsVisible] = useState(true);
  const [isNotificationCenterVisible, setNotificationCenterVisible] = useState(false);

  const toggleStartMenu = () => {
    setStartMenuVisible(!isStartMenuVisible);
  };

  const toggleCredits = () => {
    setCreditsVisible(!isCreditsVisible);
  }

  const toggleNotificationCenter = () => {
    setNotificationCenterVisible(!isNotificationCenterVisible);
  }

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={isAuth ? (
          <>
            <Desktop isCreditsVisible={isCreditsVisible} />
            <StartMenu isVisible={isStartMenuVisible} />
            <NotificationCenter isVisible={isNotificationCenterVisible} handleClose={toggleNotificationCenter} />
            <Taskbar onStartButtonClick={toggleStartMenu} onToggleCredits={toggleCredits} onNotificationCenterClick={toggleNotificationCenter} />
          </>
        ) : (
          <Setup />
        )} />
        <Route path="/download/:fileId" element={<Download />} />
        <Route path="/maintenance" element={<Maintenance />} />
        {/* <Route path="/game" element={<QuestGame />} /> */}
        {/* <Route path="/cod" element={<Game />} /> */}
      </Routes>
    </div>
  );
};

export default App;
