import React, { useState, useEffect } from 'react';
import {
    LineChart, Line, BarChart, Bar, PieChart, Pie, AreaChart, Area,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { ArrowUp, ArrowDown, TrendingUp, BarChart2, PieChart as PieChartIcon, Activity } from 'lucide-react';
import './Statistics.scss';

const Statistics = () => {
    const [timeRange, setTimeRange] = useState('month');
    const [data, setData] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [kpis, setKPIs] = useState({});

    useEffect(() => {
        fetchData();
        fetchPredictions();
        calculateKPIs();
    }, [timeRange]);

    const fetchData = () => {
        // Simuler la récupération de données
        const generatedData = generateData(timeRange);
        setData(generatedData);
    };

    const fetchPredictions = () => {
        // Simuler des prédictions
        // const generatedPredictions = generatePredictions(timeRange);
        // setPredictions(generatedPredictions);
    };

    const calculateKPIs = () => {
        // Calculer les KPIs basés sur les données
        const calculatedKPIs = {
            totalRevenue: data.reduce((sum, item) => sum + item.revenue, 0),
            averageEngagement: data.reduce((sum, item) => sum + item.engagement, 0) / data.length,
            conversionRate: (data.reduce((sum, item) => sum + item.conversions, 0) / data.reduce((sum, item) => sum + item.visitors, 0)) * 100
        };
        setKPIs(calculatedKPIs);
    };

    const generateData = (range) => {
        // Générer des données simulées basées sur la plage de temps
        const dataPoints = range === 'year' ? 12 : 30;
        return Array.from({ length: dataPoints }, (_, i) => ({
            date: `2023-${(i + 1).toString().padStart(2, '0')}-01`,
            revenue: Math.floor(Math.random() * 10000) + 5000,
            visitors: Math.floor(Math.random() * 1000) + 500,
            engagement: Math.random() * 100,
            conversions: Math.floor(Math.random() * 50) + 10
        }));
    };

    // const generatePredictions = (range) => {
    //     // Générer des prédictions simulées
    //     const dataPoints = range === 'year' ? 3 : 7;
    //     const lastDataPoint = data[data.length - 1];
    //     return Array.from({ length: dataPoints }, (_, i) => ({
    //         date: `2024-${(i + 1).toString().padStart(2, '0')}-01`,
    //         revenue: lastDataPoint.revenue * (1 + (Math.random() * 0.2 - 0.1)),
    //         visitors: lastDataPoint.visitors * (1 + (Math.random() * 0.2 - 0.1))
    //     }));
    // };

    return (
        <div className="advanced-statistics-dashboard">
            <h1>Tableau de Bord de Statistiques Avancées</h1>

            <div className="time-range-selector">
                <button onClick={() => setTimeRange('month')} className={timeRange === 'month' ? 'active' : ''}>Mois</button>
                <button onClick={() => setTimeRange('year')} className={timeRange === 'year' ? 'active' : ''}>Année</button>
            </div>

            <div className="kpi-cards">
                <div className="kpi-card">
                    <h3>Revenu Total</h3>
                    <p>{kpis.totalRevenue?.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}</p>
                    <ArrowUp className="icon up" />
                </div>
                <div className="kpi-card">
                    <h3>Engagement Moyen</h3>
                    <p>{kpis.averageEngagement?.toFixed(2)}%</p>
                    <ArrowDown className="icon down" />
                </div>
                <div className="kpi-card">
                    <h3>Taux de Conversion</h3>
                    <p>{kpis.conversionRate?.toFixed(2)}%</p>
                    <ArrowUp className="icon up" />
                </div>
            </div>

            <div className="chart-grid">
                <div className="chart-container">
                    <h2><TrendingUp size={24} /> Tendance des Revenus</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={[...data, ...predictions]}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="revenue" stroke="#8884d8" name="Revenus" />
                            <Line type="monotone" dataKey="revenue" stroke="#82ca9d" strokeDasharray="5 5" data={predictions} name="Prédictions" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className="chart-container">
                    <h2><BarChart2 size={24} /> Visiteurs par Période</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="visitors" fill="#8884d8" name="Visiteurs" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div className="chart-container">
                    <h2><PieChartIcon size={24} /> Répartition des Conversions</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie data={data} dataKey="conversions" nameKey="date" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label />
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>

                <div className="chart-container">
                    <h2><Activity size={24} /> Engagement au fil du temps</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="engagement" stroke="#8884d8" fill="#8884d8" name="Engagement" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default Statistics;