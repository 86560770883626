import React, { useState, useEffect } from 'react';
import {
  List,
  Edit,
  Trash2,
  Eye,
  Plus,
  Search,
  Filter,
  ChevronLeft,
  ChevronRight,
  Save,
  X
} from 'lucide-react';
import { getLink } from '../../utils/pointer';
import './BlogPostManager.scss';

const BlogPostManager = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchPosts();
    fetchCategories();
  }, []);

  const fetchPosts = async () => {
    // Simuler la récupération des posts depuis une API
    const response = await fetch(getLink() + '/blog', { method: 'GET', headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('db_token') } });
    const data = await response.json();
    setPosts(data);
  };

  const fetchCategories = async () => {
    // Simuler la récupération des catégories depuis une API
    const data = [
      { id: 1, name: 'Technologie' },
      { id: 2, name: 'Science' },
      { id: 3, name: 'Art' },
      { id: 4, name: 'Musique' },
      { id: 5, name: 'Cinéma' },
      { id: 6, name: 'Littérature' }
    ];
    setCategories(data);
  };

  const handleCreatePost = () => {
    setCurrentPost({
      title: '',
      content: '',
      category: '',
      author: '',
      publishDate: new Date().toISOString().split('T')[0],
      status: 'draft'
    });
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleEditPost = (post) => {
    setCurrentPost(post);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDeletePost = async (postId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
      // Simuler la suppression d'un post
      await fetch(`https://api.example.com/posts/${postId}`, { method: 'DELETE' });
      setPosts(posts.filter(post => post.id !== postId));
    }
  };

  const handleSavePost = async () => {
    if (currentPost.id) {
      // Simuler la mise à jour d'un post existant
      await fetch(`https://api.example.com/posts/${currentPost.id}`, {
        method: 'PUT',
        body: JSON.stringify(currentPost)
      });
      setPosts(posts.map(post => post.id === currentPost.id ? currentPost : post));
    } else {
      // Simuler la création d'un nouveau post
      const response = await fetch('https://api.example.com/posts', {
        method: 'POST',
        body: JSON.stringify(currentPost)
      });
      const newPost = await response.json();
      setPosts([...posts, newPost]);
    }
    setIsEditing(false);
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setCurrentPost({ ...currentPost, [e.target.name]: e.target.value });
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCategory === '' || post.category === selectedCategory)
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-post-manager">
      <h1><List size={24} /> Gestionnaire de Blogposts</h1>

      <div className="actions">
        <button onClick={handleCreatePost} className="create-btn">
          <Plus size={18} /> Créer un article
        </button>
        <div className="search-bar">
          <Search size={18} />
          <input
            type="text"
            placeholder="Rechercher un article..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="category-filter">
          <Filter size={18} />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Toutes les catégories</option>
            {categories.map(category => (
              <option key={category.id} value={category.name}>{category.name}</option>
            ))}
          </select>
        </div>
      </div>

      <table className="posts-table">
        <thead>
          <tr>
            <th>Titre</th>
            <th>Auteur</th>
            <th>Catégorie</th>
            <th>Date de publication</th>
            <th>Vues</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map(post => (
            <tr key={post.id}>
              <td>{post.title}</td>
              <td>{post.author.name}</td>
              <td>{post.category}</td>
              <td>{new Date(post.createdAt).toLocaleDateString()}</td>
              <td>{post.views.length}</td>
              <td>
                <button onClick={() => handleEditPost(post)} title="Modifier">
                  <Edit size={18} />
                </button>
                <button onClick={() => handleDeletePost(post.id)} title="Supprimer">
                  <Trash2 size={18} />
                </button>
                <button onClick={() => window.open(`/blog/${post.id}`, '_blank')} title="Voir">
                  <Eye size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          <ChevronLeft size={18} />
        </button>
        <span>{currentPage} / {Math.ceil(filteredPosts.length / postsPerPage)}</span>
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredPosts.length / postsPerPage)}>
          <ChevronRight size={18} />
        </button>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{isEditing ? 'Modifier l\'article' : 'Créer un article'}</h2>
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>
              <X size={18} />
            </button>
            <form onSubmit={(e) => { e.preventDefault(); handleSavePost(); }}>
              <div className="form-group">
                <label htmlFor="title">Titre</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={currentPost?.title || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Contenu</label>
                <textarea
                  id="content"
                  name="content"
                  value={currentPost?.content || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="category">Catégorie</label>
                <select
                  id="category"
                  name="category"
                  value={currentPost?.category || ''}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Sélectionner une catégorie</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="author">Auteur</label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  value={currentPost?.author || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="publishDate">Date de publication</label>
                <input
                  type="date"
                  id="publishDate"
                  name="publishDate"
                  value={currentPost?.publishDate || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Statut</label>
                <select
                  id="status"
                  name="status"
                  value={currentPost?.status || ''}
                  onChange={handleInputChange}
                  required
                >
                  <option value="draft">Brouillon</option>
                  <option value="published">Publié</option>
                  <option value="archived">Archivé</option>
                </select>
              </div>
              <button type="submit" className="save-btn">
                <Save size={18} /> Enregistrer
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPostManager;