import React, { useEffect, useState } from 'react';
import '../styles/Taskbar.scss';
import wlogo from '../img/wlogo.png';

const Taskbar = ({ onStartButtonClick, onToggleCredits, onNotificationCenterClick }) => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="taskbar">
            <button className="start-button" onClick={onStartButtonClick}>
                <img src={wlogo} alt="Start" />
            </button>
            <div className="taskbar-right">

                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onToggleCredits}>
                    <path d="M13.8 10.2H13.806M10.2004 10.1999H10.2064M9.59961 13.1997C9.59961 13.1997 10.4996 14.3997 11.9996 14.3997C13.4996 14.3997 14.3996 13.1997 14.3996 13.1997M17 3H19C20.1046 3 21 3.89543 21 5V7M21 17V19C21 20.1046 20.1046 21 19 21H17M7 21H5C3.89543 21 3 20.1046 3 19V17M3 7V5C3 3.89543 3.89543 3 5 3H7M14.1 10.2C14.1 10.3657 13.9657 10.5 13.8 10.5C13.6343 10.5 13.5 10.3657 13.5 10.2C13.5 10.0343 13.6343 9.9 13.8 9.9C13.9657 9.9 14.1 10.0343 14.1 10.2ZM10.5004 10.1999C10.5004 10.3656 10.3661 10.4999 10.2004 10.4999C10.0347 10.4999 9.90039 10.3656 9.90039 10.1999C9.90039 10.0342 10.0347 9.8999 10.2004 9.8999C10.3661 9.8999 10.5004 10.0342 10.5004 10.1999ZM18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <div className="taskbar-clock">
                    <span>{time.toLocaleTimeString()}</span>
                    <span>{time.toLocaleDateString()}</span>
                </div>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onNotificationCenterClick}>
                    <path d="M9.00195 17H5.60636C4.34793 17 3.71872 17 3.58633 16.9023C3.4376 16.7925 3.40126 16.7277 3.38515 16.5436C3.37082 16.3797 3.75646 15.7486 4.52776 14.4866C5.32411 13.1835 6.00031 11.2862 6.00031 8.6C6.00031 7.11479 6.63245 5.69041 7.75766 4.6402C8.88288 3.59 10.409 3 12.0003 3C13.5916 3 15.1177 3.59 16.2429 4.6402C17.3682 5.69041 18.0003 7.11479 18.0003 8.6C18.0003 11.2862 18.6765 13.1835 19.4729 14.4866C20.2441 15.7486 20.6298 16.3797 20.6155 16.5436C20.5994 16.7277 20.563 16.7925 20.4143 16.9023C20.2819 17 19.6527 17 18.3943 17H15.0003M9.00195 17L9.00031 18C9.00031 19.6569 10.3435 21 12.0003 21C13.6572 21 15.0003 19.6569 15.0003 18V17M9.00195 17H15.0003" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>


        </div>
    );
};

export default Taskbar;
