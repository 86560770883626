import React, { useState, useEffect } from 'react';
import { getLink } from '../../utils/pointer';
import './PermissionManagement.scss';
import { Plus, X, Edit, Trash2, Eye, Shield, Users, CheckSquare, XSquare } from 'lucide-react';

const PermissionManagement = () => {
    const [permissions, setPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isCreatingPermission, setIsCreatingPermission] = useState(false);
    const [isCreatingRole, setIsCreatingRole] = useState(false);
    const [isEditingPermission, setIsEditingPermission] = useState(false);
    const [isEditingRole, setIsEditingRole] = useState(false);

    useEffect(() => {
        fetchPermissions();
        fetchRoles();
    }, []);

    const fetchPermissions = async () => {
        try {
            const response = await fetch(getLink() + '/permissions', {
                headers: { 'x-access-token': localStorage.getItem('db_token') }
            });
            const data = await response.json();
            setPermissions(data);
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await fetch(getLink() + '/roles', {
                headers: { 'x-access-token': localStorage.getItem('db_token') }
            });
            const data = await response.json();
            setRoles(data);
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    const handleCreatePermission = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const permissionData = Object.fromEntries(formData.entries());

        try {
            const response = await fetch(getLink() + '/permissions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(permissionData)
            });
            const newPermission = await response.json();
            setPermissions([...permissions, newPermission]);
            setIsCreatingPermission(false);
        } catch (error) {
            console.error("Error creating permission:", error);
        }
    };

    const handleCreateRole = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const roleData = Object.fromEntries(formData.entries());
        roleData.permissions = Array.from(formData.getAll('permissions'));

        try {
            const response = await fetch(getLink() + '/roles', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(roleData)
            });
            const newRole = await response.json();
            setRoles([...roles, newRole]);
            setIsCreatingRole(false);
        } catch (error) {
            console.error("Error creating role:", error);
        }
    };

    const handleEditPermission = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const permissionData = Object.fromEntries(formData.entries());

        try {
            const response = await fetch(getLink() + `permissions/${selectedPermission._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(permissionData)
            });
            const updatedPermission = await response.json();
            setPermissions(permissions.map(p => p._id === updatedPermission._id ? updatedPermission : p));
            setIsEditingPermission(false);
            setSelectedPermission(null);
        } catch (error) {
            console.error("Error updating permission:", error);
        }
    };

    const handleEditRole = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const roleData = Object.fromEntries(formData.entries());
        roleData.permissions = Array.from(formData.getAll('permissions'));

        try {
            const response = await fetch(getLink() + `/roles/${selectedRole._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('db_token')
                },
                body: JSON.stringify(roleData)
            });
            const updatedRole = await response.json();
            setRoles(roles.map(r => r._id === updatedRole._id ? updatedRole : r));
            setIsEditingRole(false);
            setSelectedRole(null);
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };

    const handleDeletePermission = async (permissionId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette permission ?")) {
            try {
                await fetch(getLink() + `/permissions/${permissionId}`, {
                    method: 'DELETE',
                    headers: { 'x-access-token': localStorage.getItem('db_token') }
                });
                setPermissions(permissions.filter(p => p._id !== permissionId));
            } catch (error) {
                console.error("Error deleting permission:", error);
            }
        }
    };

    const handleDeleteRole = async (roleId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce rôle ?")) {
            try {
                await fetch(getLink() + `/roles/${roleId}`, {
                    method: 'DELETE',
                    headers: { 'x-access-token': localStorage.getItem('db_token') }
                });
                setRoles(roles.filter(r => r._id !== roleId));
            } catch (error) {
                console.error("Error deleting role:", error);
            }
        }
    };

    const renderPermissionForm = (permission = {}) => (
        <form onSubmit={permission._id ? handleEditPermission : handleCreatePermission}>
            <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input type="text" id="name" name="name" defaultValue={permission.name || ''} required />
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea id="description" name="description" defaultValue={permission.description || ''} required />
            </div>
            <div className="form-group">
                <label htmlFor="category">Catégorie</label>
                <input type="text" id="category" name="category" defaultValue={permission.category || ''} />
            </div>
            <button type="submit">{permission._id ? 'Modifier' : 'Créer'} la permission</button>
        </form>
    );

    const renderRoleForm = (role = {}) => (
        <form onSubmit={role._id ? handleEditRole : handleCreateRole}>
            <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input type="text" id="name" name="name" defaultValue={role.name || ''} required />
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea id="description" name="description" defaultValue={role.description || ''} required />
            </div>
            <div className="form-group">
                <label>Permissions</label>
                {permissions.map(permission => (
                    <div key={permission._id} className="checkbox-group">
                        <input
                            type="checkbox"
                            id={`permission-${permission._id}`}
                            name="permissions"
                            value={permission._id}
                            defaultChecked={role.permissions && role.permissions.includes(permission._id)}
                        />
                        <label htmlFor={`permission-${permission._id}`}>{permission.name}</label>
                    </div>
                ))}
            </div>
            <button type="submit">{role._id ? 'Modifier' : 'Créer'} le rôle</button>
        </form>
    );

    const renderPermissionsTable = () => (
        <table className="permissions-table">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Catégorie</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {permissions.map(permission => (
                    <tr key={permission._id}>
                        <td>{permission.name}</td>
                        <td>{permission.description}</td>
                        <td>{permission.category}</td>
                        <td>
                            <button onClick={() => { setSelectedPermission(permission); setIsEditingPermission(true); }} title="Modifier">
                                <Edit size={18} />
                            </button>
                            <button onClick={() => handleDeletePermission(permission._id)} title="Supprimer">
                                <Trash2 size={18} />
                            </button>
                            <button onClick={() => setSelectedPermission(permission)} title="Voir">
                                <Eye size={18} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderRolesTable = () => (
        <table className="roles-table">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Permissions</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {roles.map(role => (
                    <tr key={role._id}>
                        <td>{role.name}</td>
                        <td>{role.description}</td>
                        <td>{role.permissions.length}</td>
                        <td>
                            <button onClick={() => { setSelectedRole(role); setIsEditingRole(true); }} title="Modifier">
                                <Edit size={18} />
                            </button>
                            <button onClick={() => handleDeleteRole(role._id)} title="Supprimer">
                                <Trash2 size={18} />
                            </button>
                            <button onClick={() => setSelectedRole(role)} title="Voir">
                                <Eye size={18} />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <div className="permissions-roles-management">
            <h1>Gestion des Permissions et des Rôles</h1>

            <section className="permissions-section">
                <h2><Shield size={24} color='#0078d4' /> Permissions</h2>
                <button onClick={() => setIsCreatingPermission(true)} className="create-btn">
                    <Plus size={18} /> Ajouter une permission
                </button>
                {renderPermissionsTable()}
            </section>

            <section className="roles-section">
                <h2><Users size={24} color='#0078d4' /> Rôles</h2>
                <button onClick={() => setIsCreatingRole(true)} className="create-btn">
                    <Plus size={18} /> Ajouter un rôle
                </button>
                {renderRolesTable()}
            </section>

            {/* Modals for creation and editing */}
            {isCreatingPermission && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Créer une nouvelle permission</h2>
                        <button className="close-btn" onClick={() => setIsCreatingPermission(false)}><X size={18} /></button>
                        {renderPermissionForm()}
                    </div>
                </div>
            )}

            {isEditingPermission && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Modifier la permission</h2>
                        <button className="close-btn" onClick={() => setIsEditingPermission(false)}><X size={18} /></button>
                        {renderPermissionForm(selectedPermission)}
                    </div>
                </div>
            )}

            {isCreatingRole && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Créer un nouveau rôle</h2>
                        <button className="close-btn" onClick={() => setIsCreatingRole(false)}><X size={18} /></button>
                        {renderRoleForm()}
                    </div>
                </div>
            )}

            {isEditingRole && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Modifier le rôle</h2>
                        <button className="close-btn" onClick={() => setIsEditingRole(false)}><X size={18} /></button>
                        {renderRoleForm(selectedRole)}
                    </div>
                </div>
            )}

            {/* Modals for displaying details */}
            {selectedPermission && !isEditingPermission && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Détails de la permission</h2>
                        <button className="close-btn" onClick={() => setSelectedPermission(null)}><X size={18} /></button>
                        <p><strong>Nom :</strong> {selectedPermission.name}</p>
                        <p><strong>Description :</strong> {selectedPermission.description}</p>
                        <p><strong>Catégorie :</strong> {selectedPermission.category}</p>
                    </div>
                </div>
            )}

            {selectedRole && !isEditingRole && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Détails du rôle</h2>
                        <button className="close-btn" onClick={() => setSelectedRole(null)}><X size={18} /></button>
                        <p><strong>Nom :</strong> {selectedRole.name}</p>
                        <p><strong>Description :</strong> {selectedRole.description}</p>
                        <p><strong>Permissions :</strong></p>
                        <ul>
                            {selectedRole.permissions.map(permissionId => {
                                const permission = permissions.find(p => p._id === permissionId);
                                return permission ? <li key={permission._id}>{permission.name}</li> : null;
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PermissionManagement;