import React, { useState, useEffect } from 'react';
import './CDNManagement.scss';

const CDNManagement = () => {
    const [cdns, setCDNs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newCDN, setNewCDN] = useState({ name: '', url: '', status: 'active' });
    const [isEditing, setIsEditing] = useState(false);
    const [editingCDN, setEditingCDN] = useState(null);

    useEffect(() => {
        // Simuler le chargement des CDNs depuis une API
        const fetchCDNs = async () => {
            // Remplacer ceci par un vrai appel API
            const mockCDNs = [
                { id: 1, name: 'Main CDN', url: 'https://cdn1.example.com', status: 'active' },
                { id: 2, name: 'Backup CDN', url: 'https://cdn2.example.com', status: 'inactive' },
            ];
            setCDNs(mockCDNs);
        };
        fetchCDNs();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDeleteCDN = (cdnId) => {
        setCDNs(prev => prev.filter(cdn => cdn.id !== cdnId));
    };

    const handleAddCDN = () => {
        if (newCDN.name && newCDN.url) {
            setCDNs(prev => [...prev, { ...newCDN, id: Date.now() }]);
            setNewCDN({ name: '', url: '', status: 'active' });
        }
    };

    const handleEditCDN = (cdn) => {
        setIsEditing(true);
        setEditingCDN(cdn);
    };

    const handleUpdateCDN = () => {
        setCDNs(prev => prev.map(cdn =>
            cdn.id === editingCDN.id ? editingCDN : cdn
        ));
        setIsEditing(false);
        setEditingCDN(null);
    };

    const handleToggleCDNStatus = (cdnId) => {
        setCDNs(prev => prev.map(cdn =>
            cdn.id === cdnId ? { ...cdn, status: cdn.status === 'active' ? 'inactive' : 'active' } : cdn
        ));
    };

    const filteredCDNs = cdns.filter(cdn =>
        cdn.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cdn.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="cdn-management">
            <h2>Gestion des CDN</h2>
            <input
                type="text"
                placeholder="Rechercher des CDN"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="cdn-list">
                {filteredCDNs.map(cdn => (
                    <li key={cdn.id}>
                        <strong>{cdn.name}</strong>: {cdn.url}
                        <span className={`status ${cdn.status}`}>{cdn.status}</span>
                        <button onClick={() => handleToggleCDNStatus(cdn.id)}>
                            {cdn.status === 'active' ? 'Désactiver' : 'Activer'}
                        </button>
                        <button onClick={() => handleEditCDN(cdn)}>Modifier</button>
                        <button onClick={() => handleDeleteCDN(cdn.id)}>Supprimer</button>
                    </li>
                ))}
            </ul>
            <div className="cdn-form">
                <h3>{isEditing ? 'Modifier le CDN' : 'Ajouter un nouveau CDN'}</h3>
                <input
                    type="text"
                    placeholder="Nom du CDN"
                    value={isEditing ? editingCDN.name : newCDN.name}
                    onChange={(e) => isEditing
                        ? setEditingCDN({ ...editingCDN, name: e.target.value })
                        : setNewCDN({ ...newCDN, name: e.target.value })
                    }
                />
                <input
                    type="text"
                    placeholder="URL du CDN"
                    value={isEditing ? editingCDN.url : newCDN.url}
                    onChange={(e) => isEditing
                        ? setEditingCDN({ ...editingCDN, url: e.target.value })
                        : setNewCDN({ ...newCDN, url: e.target.value })
                    }
                />
                <select
                    value={isEditing ? editingCDN.status : newCDN.status}
                    onChange={(e) => isEditing
                        ? setEditingCDN({ ...editingCDN, status: e.target.value })
                        : setNewCDN({ ...newCDN, status: e.target.value })
                    }
                >
                    <option value="active">Actif</option>
                    <option value="inactive">Inactif</option>
                </select>
                {isEditing ? (
                    <button onClick={handleUpdateCDN}>Mettre à jour le CDN</button>
                ) : (
                    <button onClick={handleAddCDN}>Ajouter le CDN</button>
                )}
            </div>
        </div>
    );
};

export default CDNManagement;