import React, { useState, useEffect } from 'react';
import { getLink } from '../../utils/pointer';
import './ProjectManagement.scss';
import { Calendar, Edit, Eye, Goal, ListCheck, Plus, Trash2, X } from 'lucide-react';

const ProjectManagement = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isCreatingGoal, setIsCreatingGoal] = useState(false);
    const [isCreatingTask, setIsCreatingTask] = useState(false);
    const [isCreatingDeadline, setIsCreatingDeadline] = useState(false);


    // useEffect to fetch projects from an API
    useEffect(() => {

        const fetchProjects = async () => {
            await fetch(getLink() + '/projects',
                {
                    method: 'GET',
                    headers: {
                        'x-access-token': localStorage.getItem('db_token')
                    }
                })
                .then(async (response) => {
                    const resp = await response.json();
                    console.log("Projects fetched from API", resp);
                    setProjects(resp);
                })
        }

        fetchProjects();

    }, []);





    const selectProject = (project) => {
        setSelectedProject(project);
    };

    const addGoal = (goal) => {
        setSelectedProject({
            ...selectedProject,
            goals: [...selectedProject.objectives, goal],
        });
    };

    const addTask = (task) => {
        setSelectedProject({
            ...selectedProject,
            tasks: [...selectedProject.tasks, task],
        });
    };

    const openNewProjectForm = () => {
        setIsCreating(true);
    };

    const openCreateGoalForm = () => {
        setIsCreatingGoal(true);
    };

    const openCreateTaskForm = () => {
        setIsCreatingTask(true);
    };

    const handleCreateNewProject = () => {
        const projectName = document.getElementById('project-name').value;
        const projectDescription = document.getElementById('project-description').value;

        const postRequest = async () => {
            await fetch(getLink() + '/project',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('db_token')
                    },
                    body: JSON.stringify({ name: projectName, description: projectDescription })
                })
                .then(async (response) => {
                    const resp = await response.json();
                    console.log("Project created", resp);

                    setProjects([...projects, { name: projectName, description: projectDescription, goals: [], tasks: [], deadlines: [], version: 1 }]);
                    setIsCreating(false);
                })
        }


        if (projectName && projectDescription) {
            postRequest();
        }
    };


    const handleCreateNewTask = () => {
        const taskName = document.getElementById('task-name').value;
        const taskDescription = document.getElementById('task-description').value;

        const taskPriority = document.getElementById('task-priority').value;
        const taskStatus = document.getElementById('task-status').value;
        const taskAssignee = document.getElementById('task-assignee').value;

        if (!taskName || !taskDescription || !taskPriority || !taskStatus || !taskAssignee) {
            return;
        }

        const postRequest = async () => {
            await fetch(getLink() + '/project/' + selectedProject._id + '/task',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('db_token')
                    },
                    body: JSON.stringify({ name: taskName, description: taskDescription, priority: taskPriority, status: taskStatus, assignee: taskAssignee })
                })
                .then(async (response) => {
                    const resp = await response.json();
                    console.log("Task created", resp);

                    setSelectedProject({
                        ...selectedProject,
                        tasks: [...selectedProject.tasks, { name: taskName, description: taskDescription, priority: taskPriority, status: taskStatus, assignee: taskAssignee }],
                    });

                    setIsCreatingTask(false);
                })
        }

        postRequest();
    }

    const handleEditItem = (itemType, item) => {
        // Implement edit functionality
        console.log(`Editing ${itemType}:`, item);
    };

    const handleDeleteItem = (itemType, item) => {
        // Implement delete functionality
        console.log(`Deleting ${itemType}:`, item);
    };

    const handleViewItem = (itemType, item) => {
        // Implement view functionality
        console.log(`Viewing ${itemType}:`, item);
    };


    const renderActionButtons = (itemType, item) => (
        <div className="action-buttons">
            <button onClick={() => handleEditItem(itemType, item)} title="Edit" className='manage-button'>
                <Edit size={16} />
            </button>
            <button onClick={() => handleDeleteItem(itemType, item)} title="Delete" className='manage-button'>
                <Trash2 size={16} />
            </button>
            <button onClick={() => handleViewItem(itemType, item)} title="View" className='manage-button'>
                <Eye size={16} />
            </button>
        </div>
    );


    const handleCreateNewObjective = () => {
        const objectiveName = document.getElementById('objective-name').value;
        const objectiveDescription = document.getElementById('objective-description').value;
        const objectivePriority = document.getElementById('objective-priority').value;
        const objectiveStatus = document.getElementById('objective-status').value;
        const objectiveAssignee = document.getElementById('objective-assignee').value;

        if (!objectiveName || !objectiveDescription || !objectivePriority || !objectiveStatus || !objectiveAssignee) {
            return;
        }

        const postRequest = async () => {
            await fetch(getLink() + '/project/' + selectedProject._id + '/objective',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('db_token')
                    },
                    body: JSON.stringify({ name: objectiveName, description: objectiveDescription, priority: objectivePriority, status: objectiveStatus, assignee: objectiveAssignee })
                })
                .then(async (response) => {
                    const resp = await response.json();
                    console.log("Objective created", resp);

                    setSelectedProject({
                        ...selectedProject,
                        objectives: [...selectedProject.objectives, { name: objectiveName, description: objectiveDescription, priority: objectivePriority, status: objectiveStatus, assignee: objectiveAssignee }],
                    });

                    setIsCreatingGoal(false);
                })
        }

        postRequest();

    };

    const renderNewProjectForm = () => {
        return (
            <div className='project-popup'>
                <div className='popup-content'>
                    {/* ajouter un header avec titre et description */}
                    <div className='popup-header'>
                        <h3>Ajouter un projet</h3>
                        <p>Créer un nouveau projet</p>
                        <button className='close-button' onClick={() => setIsCreating(false)}><X size={18} /></button>
                    </div>

                    <div className='popup-form'>

                        <div className='project-input'>
                            <label htmlFor="project-name">Nom du projet</label>
                            <input type="text" id="project-name" placeholder="Nom du projet" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="project-description">Description</label>
                            <textarea id="project-description" placeholder="Description du projet"></textarea>
                        </div>

                        <button onClick={handleCreateNewProject}>Créer le projet</button>
                    </div>
                </div>
            </div>
        );
    };

    const renderEditProjectForm = () => {
        return (
            <div className='project-popup'>
                <div className='popup-content'>
                    <div className='popup-header'>
                        <h3>Modifier le projet</h3>
                        <p>Modifier les détails du projet</p>
                        <button className='close-button' onClick={() => setIsEditing(false)}><X size={18} /></button>
                    </div>

                    <div className='popup-form'>

                        <div className='project-input'>
                            <label htmlFor="project-name">Nom du projet</label>
                            <input type="text" id="project-name" placeholder="Nom du projet" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="project-description">Description</label>
                            <textarea id="project-description" placeholder="Description du projet"></textarea>
                        </div>

                        <button type="submit">Sauvegarder les modifications</button>
                    </div>
                </div>
            </div>
        );
    };


    const handleCreateGoal = () => {
        const goalName = document.getElementById('task-name').value;
        const goalDescription = document.getElementById('task-description').value;
        const goalDeadline = document.getElementById('task-deadline').value;
        const goalPriority = document.getElementById('task-priority').value;
        const goalStatus = document.getElementById('task-status').value;
        const goalAssignee = document.getElementById('task-assignee').value;

        if (goalName && goalDescription && goalDeadline && goalPriority && goalStatus && goalAssignee) {
            addGoal({ name: goalName, description: goalDescription, deadline: goalDeadline, priority: goalPriority, status: goalStatus, assignee: goalAssignee });
            setIsCreatingGoal(false);
        }
    };

    const renderCreateGoalForm = () => {
        return (
            <div className='project-popup'>
                <div className='popup-content'>
                    <div className='popup-header'>
                        <h3>Créer un objectif</h3>
                        <p>Créer un nouvel objectif pour le projet</p>
                        <button className='close-button' onClick={() => setIsCreatingGoal(false)}><X size={18} /></button>
                    </div>

                    <div className='popup-form'>
                        <div className='project-input'>
                            <label htmlFor="objective-name">Nom de l'objectif</label>
                            <input type="text" id="objective-name" placeholder="Nom de l'objectif" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="objective-description">Description</label>
                            <textarea id="objective-description" placeholder="Description de l'objectif"></textarea>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="objective-deadline">Date limite</label>
                            <input type="date" id="objective-deadline" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="objective-priority">Priorité</label>
                            <select id="objective-priority">
                                <option value="low">Basse</option>
                                <option value="medium">Moyenne</option>
                                <option value="high">Haute</option>
                            </select>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="objective-status">Statut</label>
                            <select id="objective-status">
                                <option value="todo">A faire</option>
                                <option value="in-progress">En cours</option>
                                <option value="done">Terminé</option>
                            </select>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="objective-assignee">Assigné à</label>
                            <input type="text" id="objective-assignee" placeholder="Assigné à" />
                        </div>


                        <button onClick={handleCreateNewObjective}>Créer l'objectif</button>
                    </div>
                </div>
            </div>
        );
    };


    const renderCreateTaskForm = () => {
        return (
            <div className='project-popup'>
                <div className='popup-content'>
                    <div className='popup-header'>
                        <h3>Créer une tâche</h3>
                        <p>Créer une nouvelle tâche pour le projet</p>
                        <button className='close-button' onClick={() => setIsCreatingTask(false)}><X size={18} /></button>
                    </div>

                    <div className='popup-form'>

                        <div className='project-input'>
                            <label htmlFor="task-name">Nom de la tâche</label>
                            <input type="text" id="task-name" placeholder="Nom de la tâche" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-description">Description</label>
                            <textarea id="task-description" placeholder="Description de la tâche"></textarea>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-deadline">Date limite</label>
                            <input type="date" id="task-deadline" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-priority">Priorité</label>
                            <select id="task-priority">
                                <option value="low">Basse</option>
                                <option value="medium">Moyenne</option>
                                <option value="high">Haute</option>
                            </select>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-status">Statut</label>
                            <select id="task-status">
                                <option value="todo">A faire</option>
                                <option value="in-progress">En cours</option>
                                <option value="done">Terminé</option>
                            </select>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-assignee">Assigné à</label>
                            <input type="text" id="task-assignee" placeholder="Assigné à" />
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-goal">Objectif</label>
                            <select id="task-goal">
                                {selectedProject.objectives.map((goal, index) => (
                                    <option key={index} value={goal}>{goal}</option>

                                ))}
                            </select>
                        </div>

                        <div className='project-input'>
                            <label htmlFor="task-deadline">Deadline</label>
                            <select id="task-deadline">


                            </select>
                        </div>

                        <button onClick={handleCreateNewTask}>Créer la tâche</button>
                    </div>
                </div>
            </div>
        );
    }


    const renderObjectivesTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Objectif</th>
                        <th>Date limite</th>
                        <th>Priorité</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProject.objectives.map((goal, index) => (
                        <tr key={index}>
                            <td>{goal.name}</td>
                            <td>{goal.deadline}</td>
                            <td>{goal.priority}</td>
                            <td>{goal.status}</td>
                            <td>{renderActionButtons('objective', goal)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };


    const renderTasksTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Tâche</th>
                        <th>Date limite</th>
                        <th>Priorité</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProject.tasks.map((task, index) => (
                        <tr key={index}>
                            <td>{task.name}</td>
                            <td>{task.deadline}</td>
                            <td>{task.priority}</td>
                            <td>{task.status}</td>
                            <td>{renderActionButtons('task', task)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    return (
        <div className="project-manager">
            <aside className="sidebar">
                <button onClick={openNewProjectForm} className="action-btn">
                    <Plus size={18} /> Ajouter un projet
                </button>
                <ul className="project-list">
                    {projects.map((project, index) => (
                        <li key={index} onClick={() => selectProject(project)}>
                            –  {project.name}
                        </li>
                    ))}
                </ul>
            </aside>

            <main className="project-details">
                {selectedProject ? (
                    <>
                        <div className="project-header">
                            <h2>{selectedProject.name} <span className="version">v{selectedProject.version_major}.{selectedProject.version_minor}.{selectedProject.version_patch}.{selectedProject.version_build} {selectedProject.branch}</span></h2>
                            <p>{selectedProject.description}</p>
                        </div>

                        <div className="project-section">
                            <div className="project-actions">
                                <h3><Goal size={18} /> Objectifs</h3>
                                <button onClick={openCreateGoalForm}>Ajouter un objectif</button>
                            </div>
                            {selectedProject.objectives.length > 0 ? renderObjectivesTable() : <p className="empty-state">Aucun objectif pour ce projet</p>}

                        </div>

                        <div className="project-section">
                            <div className="project-actions">
                                <h3><ListCheck size={18} /> Tasks</h3>
                                <button onClick={openCreateTaskForm}>Ajouter une tâche</button>
                            </div>
                            {selectedProject.tasks.length > 0 ? renderTasksTable() : <p className="empty-state">Aucune tâche pour ce projet</p>}
                        </div>
                    </>
                ) : (
                    <p className="empty-state">Select a project to view details</p>
                )}
            </main>

            {isCreating && renderNewProjectForm()}
            {isEditing && renderEditProjectForm()}
            {isCreatingGoal && renderCreateGoalForm()}
            {isCreatingTask && renderCreateTaskForm()}
        </div>
    );
};

export default ProjectManagement;
