import React, { useState, useEffect } from 'react';
import { X, ShoppingCart, ChevronRight, Check, Star, Lock, ArrowLeft, Search, User, Heart, ChevronLeft } from 'lucide-react';
import banner from '../img/Banner.png';
import frontend from '../img/frontend.png';
import backend from '../img/backend.png';
import mobile from '../img/mobile.png';
import automation from '../img/ia.png';
import security from '../img/security_banner.png';
import addon from '../img/addons.png';
import { useUser } from '../contexts/UserContext';
import '../styles/ServiceOrder.scss';

const services = [
    {
        id: 1,
        name: "Site Web",
        image: frontend,
        description: "Design élégant et fonctionnalités avancées pour votre présence en ligne, avec des solutions adaptées à tous vos besoins.",
        rating: 4.8,
        price: 499.99,
        subCategories: [
            {
                name: "Site Vitrine",
                image: addon,
                description: "Idéal pour présenter votre entreprise ou vos services, avec un design attrayant et une navigation intuitive.",
                options: [
                    { name: "Design sur mesure", description: "Création d'un design unique adapté à votre identité visuelle.", price: 299.99 },
                    { name: "Responsive", description: "Optimisation du site pour une utilisation fluide sur tous les appareils.", price: 59.99 },
                    { name: "Optimisé SEO", description: "Amélioration du référencement pour une meilleure visibilité sur les moteurs de recherche.", price: 59.99 },
                    { name: "Intégration de contenu", description: "Ajout et mise en forme du contenu fourni (texte, images, vidéos).", price: 299.99 }
                ],
                pricing: { base: 499.99 },
                additionalInfo: "Le site vitrine est parfait pour les petites entreprises souhaitant se démarquer en ligne."
            },
            {
                name: "Application Web",
                image: addon,
                description: "Des applications web interactives et réactives, conçues pour offrir une expérience utilisateur optimale.",
                options: [
                    { name: "Interface utilisateur intuitive", description: "Développement d'une interface facile à utiliser, centrée sur l'expérience utilisateur.", price: 399.99 },
                    { name: "Fonctionnalités interactives", description: "Ajout de fonctionnalités interactives comme les formulaires dynamiques, les animations, etc.", price: 299.99 },
                    { name: "Intégration API", description: "Connexion avec des services externes via des APIs pour enrichir l'application.", price: 499.99 },
                    { name: "Tests utilisateurs", description: "Réalisation de tests utilisateurs pour garantir la qualité et l'utilisabilité.", price: 69.99 }
                ],
                pricing: { base: 799.99 },
                additionalInfo: "Idéal pour les entreprises ayant besoin de solutions sur mesure pour leurs utilisateurs."
            },
            {
                name: "E-commerce",
                image: addon,
                description: "Développez votre boutique en ligne avec un site e-commerce puissant et sécurisé.",
                options: [
                    { name: "Catalogue produits", description: "Mise en place d'un catalogue produits dynamique et facile à gérer.", price: 199.99 },
                    { name: "Panier d'achat", description: "Développement d'un système de panier d'achat fluide et intuitif.", price: 199.99 },
                    { name: "Passerelle de paiement", description: "Intégration sécurisée de passerelles de paiement comme PayPal, Stripe, etc.", price: 199.99 },
                    { name: "Gestion des stocks", description: "Outils de gestion des stocks en temps réel pour suivre les inventaires.", price: 199.99 }
                ],
                pricing: { base: 999.99 },
                additionalInfo: "Solution idéale pour les entreprises souhaitant vendre en ligne avec une plateforme robuste."
            }
        ],
    },
    {
        id: 2,
        name: "Applications Mobiles",
        image: mobile,
        description: "Développement d'applications mobiles performantes pour iOS et Android, offrant une expérience utilisateur exceptionnelle.",
        rating: 4.7,
        price: 999.99,
        subCategories: [
            {
                name: "Android",
                image: addon,
                description: "Création d'applications natives pour l'écosystème Android.",
                options: [
                    { name: "Maquette & design", description: "Conception d'une maquette personnalisée pour une expérience utilisateur optimale.", price: 299.99 },
                    { name: "Création de plusieurs screens", description: "Développement de plusieurs écrans pour une navigation fluide et intuitive.", price: 199.99 },
                    { name: "Intégration API", description: "Connexion avec des services externes via des APIs pour enrichir l'application.", price: 399.99 },
                    { name: "Tests utilisateurs", description: "Réalisation de tests utilisateurs pour garantir la qualité et l'utilisabilité.", price: 199.99 }
                    
                ],
                pricing: { base: 999.99 },
                additionalInfo: "Idéal pour les applications Android nécessitant des fonctionnalités natives avancées."
            },
            {
                name: "iOS",
                image: addon,
                description: "Développement d'applications natives pour l'écosystème Apple.",
                options: [
                    { name: "Maquette & design", description: "Conception d'une maquette personnalisée pour une expérience utilisateur optimale.", price: 299.99 },
                    { name: "Création de plusieurs screens", description: "Développement de plusieurs écrans pour une navigation fluide et intuitive.", price: 199.99 },
                    { name: "Intégration API", description: "Connexion avec des services externes via des APIs pour enrichir l'application.", price: 399.99 },
                    { name: "Tests utilisateurs", description: "Réalisation de tests utilisateurs pour garantir la qualité et l'utilisabilité.", price: 199.99 }
                ],
                pricing: { base: 1299.99 },
                additionalInfo: "Conçu pour offrir une expérience utilisateur optimale sur les appareils Apple."
            },
            {
                name: "Cross-Platform",
                image: addon,
                description: "Développement d'applications cross-platform pour atteindre à la fois les utilisateurs iOS et Android.",
                options: [
                    { name: "Maquette & design", description: "Conception d'une maquette personnalisée pour une expérience utilisateur optimale.", price: 299.99 },
                    { name: "Création de plusieurs screens", description: "Développement de plusieurs écrans pour une navigation fluide et intuitive.", price: 199.99 },
                    { name: "Intégration API", description: "Connexion avec des services externes via des APIs pour enrichir l'application.", price: 399.99 },
                    { name: "Tests utilisateurs", description: "Réalisation de tests utilisateurs pour garantir la qualité et l'utilisabilité.", price: 199.99 }
                ],
                pricing: { base: 2299.99 },
                additionalInfo: "Idéal pour les entreprises souhaitant toucher un public large avec un seul développement."
            }
        ],
    },
    {
        id: 3,
        name: "Backend Robuste",
        image: backend,
        description: "Infrastructure puissante et évolutive pour soutenir vos applications et services en ligne.",
        rating: 4.9,
        price: 599.99,
        subCategories: [
            {
                name: "API REST",
                image: addon,
                description: "Créez des APIs robustes et bien documentées pour interagir avec vos applications.",
                options: [
                    { name: "Conception d'architecture", description: "Création d'une architecture API efficace et scalable.", price: 299.99 },
                    { name: "Développement endpoints", description: "Développement de points d'accès API sécurisés et performants.", price: 199.99 },
                    { name: "Documentation", description: "Documentation complète et claire des endpoints.", price: 99.99 },
                    { name: "Tests unitaires", description: "Réalisation de tests unitaires pour garantir la stabilité et la fiabilité.", price: 199.99 }
                ],
                pricing: { base: 99.99 },
                additionalInfo: "Parfait pour des intégrations tierces et des services web à grande échelle."
            },
            {
                name: "Base de Données",
                image: addon,
                description: "Solutions de gestion de données efficaces pour des performances optimales.",
                options: [
                    { name: "Modélisation de données", description: "Conception de schémas de base de données optimisés pour les performances.", price: 299.99 },
                    { name: "Optimisation des requêtes", description: "Amélioration des requêtes pour une exécution rapide et efficace.", price: 199.99 },
                    { name: "Mise en cache", description: "Implémentation de systèmes de cache pour réduire la charge sur la base de données.", price: 499.99 },
                    { name: "Sauvegardes automatisées", description: "Configuration de sauvegardes régulières pour garantir la sécurité des données.", price: 99.99 }
                ],
                pricing: { base: 99.99 },
                additionalInfo: "Idéal pour les applications nécessitant une gestion de données complexe et sécurisée."
            },
            {
                name: "Authentification",
                image: addon,
                description: "Systèmes d'authentification sécurisés pour protéger vos utilisateurs et vos données.",
                options: [
                    { name: "JWT", description: "Mise en place de JSON Web Tokens pour sécuriser les échanges.", price: 299.99 },
                    { name: "OAuth 2.0", description: "Intégration du protocole OAuth 2.0 pour des autorisations sécurisées.", price: 199.99 },
                    { name: "2FA", description: "Implémentation de l'authentification à deux facteurs pour plus de sécurité.", price: 199.99 },
                    { name: "Gestion des rôles", description: "Configuration de systèmes de rôles et permissions pour un accès contrôlé.", price: 299.99 }
                ],
                pricing: { base: 199.99 },
                additionalInfo: "Indispensable pour les applications nécessitant une sécurité renforcée."
            }
        ],
    },
    {
        id: 5,
        name: "Sécurité Web",
        image: security,
        description: "Protégez vos actifs numériques avec des solutions de sécurité avancées.",
        rating: 4.9,
        price: 799.99,
        subCategories: [
            {
                name: "Audit de Sécurité",
                image: addon,
                description: "Analysez la sécurité de votre site ou application pour identifier et corriger les vulnérabilités.",
                options: [
                    { name: "Analyse de vulnérabilités", description: "Détection des failles de sécurité et des vulnérabilités potentielles.", price: 399.99 },
                    { name: "Tests de pénétration", description: "Simulation d'attaques pour évaluer la résistance de votre infrastructure.", price: 599.99 },
                    { name: "Audit de conformité", description: "Vérification de la conformité aux normes de sécurité (GDPR, HIPAA, etc.).", price: 199.99 },
                    { name: "Rapport détaillé", description: "Fourniture d'un rapport détaillé avec recommandations pour améliorer la sécurité.", price: 199.99 }
                ],
                pricing: { base: 199.99 },
                additionalInfo: "Indispensable pour garantir la sécurité de vos systèmes contre les cybermenaces."
            },
            {
                name: "Sécurisation des Applications",
                image: addon,
                description: "Renforcez la sécurité de vos applications web et mobiles.",
                options: [
                    { name: "Chiffrement des données", description: "Implémentation de techniques de chiffrement pour protéger les données sensibles.", price: 499.99 },
                    { name: "Protection contre les attaques DDoS", description: "Mise en place de solutions pour prévenir et atténuer les attaques DDoS.", price: 799.99 },
                    { name: "Authentification renforcée", description: "Ajout de méthodes d'authentification avancées (2FA, biométrie).", price: 399.99 },
                    { name: "Monitoring de sécurité", description: "Surveillance continue des menaces pour une réponse rapide en cas d'incident.", price: 499.99 }
                ],
                pricing: { base: 399.99 },
                additionalInfo: "Essentiel pour les entreprises manipulant des données sensibles."
            },
        ],
    },
    {
        id: 6,
        name: "Développement Spécialisé",
        image: "https://fiches-pratiques.chefdentreprise.com/Assets/Img/FICHEPRATIQUE/2021/12/368384/Les-formations-devenir-developpeur-application-mobile-F.jpg",
        description: "Solutions de développement personnalisées pour des besoins spécifiques.",
        rating: 4.7,
        price: 99.99,
        subCategories: [
            {
                name: "Développement de Plugin Minecraft",
                image: addon,
                description: "Créez des plugins sur mesure pour améliorer l'expérience de jeu sur vos serveurs Minecraft.",
                options: [
                    { name: "Commandes personnalisées", description: "Développement de commandes spécifiques.", price: 4.99 },
                    { name: "Configuration sur mesure", description: "Création de configurations adaptées à vos besoins.", price: 39.99 },
                    { name: "Events custom", description: "Création d'événements personnalisés pour enrichir le gameplay.", price: 29.99 },
                ],
                pricing: { base: 199.99 },
                additionalInfo: "Idéal pour les serveurs Minecraft souhaitant offrir des fonctionnalités inédites à leurs joueurs."
            },
            {
                name: "Développement de Bot Discord",
                image: addon,
                description: "Développement de bots personnalisés pour automatiser et enrichir votre serveur Discord.",
                options: [
                    { name: "Commandes personnalisées", description: "Création de commandes spécifiques pour répondre à vos besoins.", price: 19.99 },
                    { name: "Modération automatique", description: "Fonctionnalités pour automatiser la modération de votre serveur.", price: 19.99 },
                    { name: "Intégration API", description: "Connexion avec des services externes pour enrichir les fonctionnalités du bot.", price: 59.99 },
                ],
                pricing: { base: 59.99 },
                additionalInfo: "Parfait pour automatiser les tâches courantes et améliorer l'interaction sur votre serveur Discord."
            }
        ]
    }
];



const ServiceOrder = ({ onClose }) => {
    const [step, setStep] = useState(0);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const { user } = useUser();

    useEffect(() => {
        // Extraire les catégories uniques des services
        const uniqueCategories = ['Accueil', ...new Set(services.map(service => service.name))];
        setCategories(uniqueCategories);
    }, []);

    const filteredServices = services.filter(service =>
        service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleServiceSelect = (service) => {
        setSelectedService(service);
        setStep(1);
    };

    const handleSubCategorySelect = (subCategory) => {
        setSelectedSubCategory(subCategory);
        setSelectedOptions([]);
        setStep(2);
    };

    const handleOptionToggle = (option) => {
        setSelectedOptions(prev =>
            prev.includes(option)
                ? prev.filter(o => o !== option)
                : [...prev, option]
        );
    };

    const calculateTotal = () => {
        if (!selectedSubCategory) return 0;
        return selectedSubCategory.pricing.base + selectedOptions.reduce((acc, option) => {
            const selectedOption = selectedSubCategory.options.find(o => o.name === option);
            return acc + selectedOption.price;
        }
            , 0);

    };

    const renderHeader = () => (
        <header className="site-header">
            <div className="header-top">
                <div className="logo">
                    <img src={banner} alt="MesServices" />
                </div>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Rechercher un service..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Search size={20} />
                </div>
                <div className="user-actions">
                    <button><User size={20} /> {user ? user.name : 'Connexion'}</button>
                    <button><Heart size={20} /> Favoris</button>
                    <button><ShoppingCart size={20} /> Panier</button>
                </div>
            </div>
            <nav className="main-nav">
                <ul>
                    {categories.map((category, index) => (
                        <li key={index} onClick={() => handleCategoryClick(category)}>
                            {category}
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );

    const handleCategoryClick = (category) => {
        if (category === 'Accueil') {
            setStep(0);
            setSelectedService(null);
            setSelectedSubCategory(null);
        } else {
            const service = services.find(s => s.name === category);
            if (service) {
                handleServiceSelect(service);
            }
        }
    };


    // ici nous avons une fonction qui sera barre de navigateur internet
    const renderBrowserBar = () => (
        <div className="browser-bar">
            <div className="browser-buttons">

                <button className="browser-button" onClick={() => setStep(0)}>
                    <ChevronLeft size={20} />
                </button>

                <button className="browser-button" onClick={() => setStep(0)}>
                    <ChevronRight size={20} />
                </button>

                <button className="browser-button">
                    <Lock size={20} />
                </button>
            </div>
            <div className="browser-address">
                <span className="address-bar">https://www.dsholding.fr</span>
            </div>
            <div className="browser-buttons">
                <button className="browser-button" onClick={onClose}>
                    <X size={20} />
                </button>
            </div>
        </div >
    );

    const renderFeaturedServices = () => (
        <section className="featured-services">
            <h2>Services Populaires</h2>
            <div className="services-grid">
                {services.slice(0, 4).map(service => (
                    <div key={service.id} className="service-card" onClick={() => handleServiceSelect(service)}>
                        <img src={service.image} alt={service.name} />
                        <div className="service-info">
                            <h3>{service.name}</h3>
                            <p>{service.description}</p>
                            <div className="service-meta">
                                <span className="rating"><Star size={16} /> {service.rating}</span>
                                <span className="price">À partir de  {service.price}€</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );

    const renderAllServices = () => (
        <section className="all-services">
            <h2>Tous nos Services</h2>
            <div className="services-grid">
                {filteredServices.map(service => (
                    <div key={service.id} className="service-card" onClick={() => handleServiceSelect(service)}>
                        <img src={service.image} alt={service.name} />
                        <div className="service-info">
                            <h3>{service.name}</h3>
                            <p>{service.description}</p>
                            <div className="service-meta">
                                <span className="rating"><Star size={16} /> {service.rating}</span>
                                <span className="price">À partir de {Math.min(...service.subCategories.map(sc => sc.pricing.base))}€</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );

    const renderFooter = () => (
        <footer className="site-footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>À propos de DsHolding.fr</h3>
                    <p>Nous proposons des services de qualité pour tous vos besoins numériques.</p>
                </div>
                <div className="footer-section">
                    <h3>Liens Rapides</h3>
                    <ul>
                        <li>Accueil</li>
                        <li>Services</li>
                        <li>À propos</li>
                        <li>Contact</li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Contactez-nous</h3>
                    <p>Email: contact@dsholding.fr</p>
                    <p>Téléphone: +33 1 23 45 67 89</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 DsHolding.fr. Tous droits réservés.</p>
            </div>
        </footer>
    );

    const renderServiceList = () => (
        <div className="service-list">
            <main className="site-main">
                {renderFeaturedServices()}
                {renderAllServices()}
            </main>
        </div>
    );

    const renderSubCategories = () => (
        <div className="sub-categories">
            <button className="back-button" onClick={() => setStep(0)}>
                <ArrowLeft size={20} />
                Retour
            </button>
            <h2>{selectedService.name}</h2>
            <div className="sub-categories-grid">
                {selectedService.subCategories.map(subCategory => (
                    <div key={subCategory.name} className="sub-category-card" onClick={() => handleSubCategorySelect(subCategory)}>
                        <img src={subCategory.image} alt={subCategory.name} />
                        <div className="sub-category-info">
                            <h3>{subCategory.name}</h3>
                            <div className="sub-category-meta">
                                <p>{subCategory.description}</p>
                                <span className="price">À partir de {subCategory.pricing.base}€</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderOptions = () => (
        <div className="options">
            <button className="back-button" onClick={() => setStep(1)}>
                <ArrowLeft size={20} />
                Retour
            </button>
            <h2>{selectedSubCategory.name}</h2>
            <div className="options-grid">
                {selectedSubCategory.options.map(option => (
                    <div key={option} className="option-card" onClick={() => handleOptionToggle(option.name)}>
                        <div className="option-info">
                            <h3>{option.name}</h3>
                            <p>{option.description}</p>
                            <div className="option-meta">
                                <span className="price">{option.price}€</span>
                            </div>
                        </div>
                        {selectedOptions.includes(option.name) && <Check size={48} />}
                    </div>
                ))}
            </div>
            <button className="next-button" onClick={() => setStep(3)}>Résumé</button>
        </div>
    );

    const renderSummary = () => (
        <div className="summary">
            <button className="back-button" onClick={() => setStep(2)}>
                <ArrowLeft size={20} />
                Retour
            </button>
            <h2>Résumé de la Commande</h2>
            <div className="summary-content">
                <div className="summary-section">
                    <h3>{selectedService.name}</h3>
                    <h4>{selectedSubCategory.name}</h4>
                    <ul>
                        {selectedOptions.map(option => <li key={option}>{option}</li>)}
                    </ul>
                </div>
                <div className="summary-section">
                    <h4>Total</h4>
                    <h3>{calculateTotal()}€</h3>
                </div>
            </div>
            <button className="next-button">Commander</button>
        </div>
    );

    return (
        <div className="service-order-popup">
            {renderBrowserBar()}
            <div className="service-order-content">
                {renderHeader()}
                {step === 0 && renderServiceList()}
                {step === 1 && renderSubCategories()}
                {step === 2 && renderOptions()}
                {step === 3 && renderSummary()}
                {renderFooter()}
            </div>
        </div>
    );
};

export default ServiceOrder;